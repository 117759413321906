import { Box  } from '@mui/material'
import React from 'react'
import ExecutiveCommentry from './ExecutiveCommentry'
import Loader from "../../../components/Loader";
function ExecutiveSummary({commentary,executiveSummarydetail , setExecutiveSummary ,generateReport}) {
  return (
    <Box>
      {!commentary?.smartData?.quadrantSummary?.isLoading &&
      !commentary?.premiumData?.quadrantSummary?.isLoading &&
      !commentary?.budgetData?.quadrantSummary?.isLoading &&
      !commentary?.lowData?.quadrantSummary?.isLoading  &&
      !commentary?.ComparativeSummary?.sectionSummary?.isLoading
      ? (
        <ExecutiveCommentry
          executiveSummarydetail={executiveSummarydetail}
          setExecutiveSummary={setExecutiveSummary}
          commentary={commentary}
          generateReport={generateReport}
        />
      ) : (
        // <Box mt={4} textAlign="center">
        //   <CircularProgress />
        // </Box>
        <Loader open={true} />
      )}
    </Box>
  );
}

export default ExecutiveSummary;
