import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, purchasedGuide, theme) {
  return {
    fontWeight:
      purchasedGuide?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DropdownSelect({
  purchasedPackageClaimed,
  onSet,
  quantities,
  countChange,
  rowId,
}) {
  const [selectedGuide, setSelectedGuide] = useState([]);
  const theme = useTheme();
  useEffect(() => {}, [quantities]);

  useEffect(() => {
    const updatedSelect =
      selectedGuide?.length > 0
        ? purchasedPackageClaimed.options?.find((option) => option.PackageType === selectedGuide[0])
        : "None";

    purchasedPackageClaimed.selected = updatedSelect;

    // for managing the count of packages of EUO and NO Match
    if (updatedSelect.Match === "NO" || updatedSelect.Match === "EUO")
      countChange(updatedSelect, rowId);

    if (purchasedPackageClaimed.selected !== "None" && purchasedPackageClaimed?.error) {
      delete purchasedPackageClaimed.error;
    }

    onSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuide, purchasedPackageClaimed]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGuide(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // console.log("selected Guide",selectedGuide)
  // console.log("purchased packaed ",purchasedPackageClaimed)

  return (
    <Box sx={{ width: "100%" }} data-test-id="dropdown">
      <FormControl
        sx={{
          m: 1,
          width: "80%",
        }}
      >
        {purchasedPackageClaimed?.options === null ? (
          <span>None</span>
        ) : (
          purchasedPackageClaimed.isClaimed === true && (
            <span>{purchasedPackageClaimed?.options[0]?.PackageType} </span>
          )
        )}

        {purchasedPackageClaimed?.options?.length > 0 &&
          purchasedPackageClaimed?.isClaimed === false && (
            <Select
              data-testid="dropdown"
              id={`${rowId}`}
              displayEmpty
              value={selectedGuide}
              onChange={handleChange}
              input={<OutlinedInput />}
              sx={{
                color: "gray",
                height: 40,
              }}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <span>None </span>;
                }
                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
                name: "",
              }}
            >
              <MenuItem data-test-id="dropdown"  disabled value="" sx={{ justifyContent: "center" }}>
                <span >None</span>
              </MenuItem>
              {purchasedPackageClaimed?.options?.map((val) => (
                <MenuItem
                  data-testid={val.PackageType}
                  sx={{ justifyContent: "center" }}
                  key={val.PackageType}
                  value={val.PackageType}
                  // onClick={() => {
                  //   countChange(val?.Match, val.PackageType);
                  // }}
                  // style={getStyles(theme)}
                  style={getStyles(val.PackageType, selectedGuide, theme)}
                >
                  {val?.Match === "EUO" || val?.Match === "NO"
                    ? quantities?.[val?.Match]?.[val.PackageType] !== 0
                      ? // Return the formatted string
                        `${val.PackageType} (${quantities?.[val?.Match]?.[val.PackageType]})`
                      : ""
                    : val.PackageType}
                </MenuItem>
              ))}
            </Select>
          )}
      </FormControl>
    </Box>
  );
}
