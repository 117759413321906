/**
 * #do not remove these documentation
 * */
import React, { forwardRef } from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = forwardRef((props, ref) => {
  const {
    text = null, // Default button text
    onClick, // Click handler
    sx = {}, // MUI-specific styles
    variant = "contained", // MUI Button variant
    fullWidth = false, // MUI fullWidth prop
    disableRipple = false, // MUI disableRipple prop
    ...rest // Additional props spread (line arial-label,startIcon,etc)
  } = props;

  return (
    <Button
      ref={ref} // Forwarded ref
      sx={{
        width: "250px", // Default width
        ...sx, // Merge custom MUI styles
      }}
      variant={variant} // MUI button variant
      onClick={onClick} // Click handler
      disableRipple={disableRipple} // Disable ripple effect
      fullWidth={fullWidth} // Optional full-width button
      {...rest} // Spread remaining props
    >
      {text}
    </Button>
  );
});

export default CustomButton;

CustomButton.propTypes = {
  text: PropTypes.string.isRequired, // `text` is required and must be a string
  onClick: PropTypes.func.isRequired, // `onClick` is required and must be a function
  sx: PropTypes.object, // `sx` is optional and must be an object
  variant: PropTypes.oneOf(["text", "outlined", "contained"]), // Must be one of MUI's variants
};

