import { Home, NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserProfileContext } from "../../context/UserContext";
import HoverDropdown from "./HoverDropdown";

export default function CustomBreadcrumbs() {
  const { pathname } = useLocation();
  const { finalcrumbs, setFinalCrumbs } = useContext(UserProfileContext);

  useEffect(() => {
    const path = pathname.split("/").filter(Boolean);

    setFinalCrumbs((prev) => {
      if (path.length === 0) return [];

      let updatedCrumbs = [...prev];

      if (updatedCrumbs[updatedCrumbs.length - 1] === "select-scenario" && path[0] === "results") {
        updatedCrumbs = [...updatedCrumbs, "edit-scenario"];
      }

      path.forEach((newString) => {
        const existingIndex = updatedCrumbs.indexOf(newString);

        if (existingIndex !== -1) {
          updatedCrumbs.splice(existingIndex + 1);
        } else {
          updatedCrumbs.push(newString);
        }
      });

      if (JSON.stringify(updatedCrumbs) !== JSON.stringify(prev)) {
        localStorage.setItem("breadcrumbs", JSON.stringify(updatedCrumbs));
        return updatedCrumbs;
      }

      return prev; // No state change if breadcrumbs are the same
    });
  }, [pathname, setFinalCrumbs]);

  return pathname === "/" ? null : (
    <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "10px", overflowY: "auto", whiteSpace: "nowrap" }}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext />} maxItems={2}>
        <Link to={"/"} display="flex" alignItems="center" style={{ display: "flex", color: "gray", ":hover": { color: "gray" }, ":visited": { color: "gray" }, ":active": { color: "gray" } }}>
          <Home fontSize="small" sx={{ ":hover": { color: "#000" } }} />
        </Link>

        {finalcrumbs?.map((page, index) => {
          return index !== finalcrumbs.length - 1 ? (
            typeof breadcrumbsMap[finalcrumbs[index]] === "object" ? (
              <HoverDropdown filterOptions={breadcrumbsMap[finalcrumbs[index]]} parentKey={findParentKey(breadcrumbsMap[finalcrumbs[index]])} />
            ) : breadcrumbsMap[page] ? (
              <Link className="link" to={`/${page}`} key={index}>
                {breadcrumbsMap[page]}
              </Link>
            ) : null
          ) : (
            <Typography key={index} sx={{ fontWeight: "bold" }}>
              {typeof breadcrumbsMap[finalcrumbs[index - 1]] === "object"
                ? breadcrumbsMap[finalcrumbs[index - 1]]?.[page]?.label
                  ? breadcrumbsMap[finalcrumbs[index - 1]]?.[page].label
                  : breadcrumbsMap[finalcrumbs[index - 1]]?.[page]
                : typeof breadcrumbsMap[page] === "object"
                ? breadcrumbsMap[page]?.label
                : typeof breadcrumbsMap[finalcrumbs[index - 2]]?.[finalcrumbs[index - 1]] === "object"
                ? breadcrumbsMap[finalcrumbs[index - 2]]?.[finalcrumbs[index - 1]]?.[page]
                : breadcrumbsMap[page]}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}

const findParentKey = (searchValue) => {
  for (const [key, value] of Object.entries(breadcrumbsMap)) {
    if (typeof value === "object" && value !== null) {
      // If the value is an object, check if the searchValue is part of it
      if (value === searchValue) {
        return key; // Return the parent key if the searchValue is found in a nested object
      }
    }
  }
  return null; // If no match is found, return null
};

const breadcrumbsMap = {
  "select-end-user-org": "Select End User",
  "select-buyer-guide": {
    label: "Select Buyer Guide",
    "buyer-guide-of-interest": "Buyer Guide of Interest",
    "available-buyer-guides": "Available Buyer Guide",
  },
  "select-scenario": "Select Scenario",
  results: {
    label: "Results",
    "comparative-analysis": "Comparative Analysis",
    "vendor-details": "Vendor Details",
    "offer-details": "Offer Details",
  },
  "edit-scenario": {
    label: "Edit Scenario",
    "vendor-options": "Vendor Options",
    "update-use-cases": "Update Use cases",
    "scenario-settings": {
      label: "Scenario Settings",
      "bundle-option": "Bundle Options",
      "threshold-option": "Threshold Options",
      "display-option": "Display Options",
      "report-settings": "Report Settings",
    },
  },
  "report-settings": "Report Settings",
  "view-reports": "View Reports",
  "saved-reports": "Saved Reports",
  "available-units-for-allocation": "Available Units for Allocation",
  packages: "Packages",
  "my-subscriptions": "My Subscriptions",
  users: "Users",
};

/**
 * border: 'none',
 * MuiOutlinedInput-notchedOutline': { border: 0 },
 */
