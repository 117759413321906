import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader({ open, onClose, zIndex = 1300 }) {
  return (
    <Backdrop sx={{ zIndex: zIndex, backgroundColor: "transparent" }} open={open} onClick={onClose}>
      <CircularProgress data-testid="loading" />
    </Backdrop>
  );
}
