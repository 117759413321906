import { Box } from "@mui/material";
import React, {memo}from "react";
import OfferCommentary from "./OfferCommentary";

const OfferSummery = memo(({ section, offer, commentary, setCommentary, data,generateReport,offerData })=>{
  
  return (
    <Box>
      {!commentary[section][offer]?.offerMetrics?.tables?.mandatory
        ?.isLoading &&
      !commentary[section][offer]?.offerMetrics?.tables?.overall?.isLoading &&
      !commentary[section][offer]?.supportedUseCases?.tables?.overall
        ?.isLoading &&
      !commentary[section][offer]?.unsupportedUseCases?.tables?.mandatory
        ?.isLoading &&
      !commentary[section][offer]?.unsupportedUseCases?.tables?.overall
        ?.isLoading &&
      !commentary[section][offer]?.unusedFeatures?.tables?.generic?.isLoading &&
      !commentary[section][offer]?.unusedFeatures?.tables?.notRequired
        ?.isLoading ? 
        (
        <OfferCommentary
          setCommentary={setCommentary}
          offer_name={offer}
          all_widget_summaries={
            commentary[section][offer]?.offerSummary.all_widget_summaries
          }
          section={section}
          widget_jsons_input={commentary[section][offer]?.offerSummary?.widget_jsons_input}
          commentary={commentary}
          data={data}
          generateReport={generateReport}
          offerData={offerData}
        />
      ):"loading"}
    </Box>
  );
})

export default OfferSummery;
