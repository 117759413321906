import { Box, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserProfileContext } from "../../../context/UserContext";
import DataGridTable from "../../../components/DataGridTable";
import theme from "../../../theme/theme";
import { apiService } from "../../../utils/utils";
import NoDataAvilable from "../../../components/NoDataAvilable";
import Loader from "../../../components/Loader";

const getColumnNames = (name) => {
  switch (name) {
    case "metrics":
      return "Offer Metrics";
    case "vendorMax":
      return "Best Offer Metric- Vendor (%)";
    case "scenarioMax":
      return "Best Offer Metric- Scenario (%)";
    case "vendorRank":
      return "Vendor Rank- Scenario";
    default:
      return name;
  }
};

const api = {
  mandatory_cases: "https://newtestfuncpython.azurewebsites.net/api/getVendorMetricsMandatory?",
  overall_cases: "https://newtestfuncpython.azurewebsites.net/api/getVendorMetricsOverall?",
};
export default function CommonVendorDetailWidget({ menu, setLayout, widgetKey, widgetLabels }) {
  const {
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    currentOrganizationId,
    currentScenarioId,
    currentBuyerGuideId,
    currentPackage,
    vendorId,
  } = useContext(UserProfileContext);

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          currentVendor: vendorId,
        };

        //API Call
        const response = await apiService(
          api[widgetKey],
          {
            method: "POST",
            data: payload,
          },
          setLoading,
          setError
        );

        // Extract column names from the keys of the first object in response
        const columnKeys = Object.keys(response[0]);

        // Map over columnKeys to create columns array dynamically
        const transformedColumns = columnKeys?.map((key, index) => ({
          field: key, // Use the key as field name
          headerName: getColumnNames(key), // Format header name with spaces (optional)
          flex: 1,
          minWidth: 200,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => (
            <Box
              sx={{
                overflowY: "hidden",
                display: "flex",
                justifyContent: index === 0 ? "flex-start" : "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {params.value}
            </Box>
          ),
        }));

        // Map over response to create rows array
        const transformedRows = response?.map((data, index) => {
          const { ...rowData } = data; // Destructure to exclude "metric"
          return {
            id: index, // Ensure unique ID for each row
            ...rowData, // Spread the remaining data into the row object
          };
        });

        // Set the columns and rows
        setColumns(transformedColumns);
        setRows(transformedRows);

        // Adjust layout height based on row count
        setLayout((prev) =>
          prev.map((item) =>
            item.i !== widgetKey
              ? item
              : {
                  ...item,
                  h: 1.5 + transformedRows.length,
                }
          )
        );

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    setLayout,
    widgetKey,
    widgetLabels,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage.id,
    currentScenarioId,
    vendorId,
  ]);

  return loading ? (
    // If loading is true, display a loading spinner inside a box.
    // <Box sx={{ height: "70px", display: "flex", justifyContent: "center", alignItems: "center" }}>
    //   <CircularProgress />
    // </Box>
    <Loader open={loading} />
  ) : rows.length > 0 ? (
    <Box
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      }}
    >
      <Box
        className="widget-header-container"
        sx={{
          width: "100%",
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Divider sx={{ width: "95%", marginY: "10px" }} />
      </Box>
      <Box className="thinner-scrollbar" sx={{ width: "100%", overflow: "auto" }}>
        <DataGridTable
          name={widgetLabels}
          rows={rows}
          columns={columns}
          loading={loading}
          error={error}
          menu={menu}
          sx={{
            "& .MuiDataGrid-cell": {
              wordWrap: "break-word",
              whiteSpace: "normal",
            },
          }}
        />
      </Box>
    </Box>
  ) : (
    // If no data (rows.length === 0), display a message.
    <NoDataAvilable widgetLabels={widgetLabels} widgetKey={widgetKey} />
  );
}
