import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { UserProfileContext } from "../../../context/UserContext";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

Chart.register(annotationPlugin);
Chart.register(ChartDataLabels);

const ChartComponent = ({
  chartType,
  initialData,
  gridLines,
  onUpdateVisibleData,
  flag = true,
}) => {
  let XLineValue = useRef(null); // X line to separate quadrants
  let YLineValue = useRef(null); // Y line to separate quadrants
  const theme = useTheme();
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const navigate = useNavigate();
  const { setOfferName, setOfferId, setVendorId, setNotification, setVendorName } =
    useContext(UserProfileContext);
  
  // Update XLineValue and YLineValue if gridLines are provided
  useEffect(() => {
    if (gridLines) {
      XLineValue.current = gridLines?.XDivider;
      YLineValue.current = gridLines?.YDivider;
    }
  }, [gridLines, XLineValue, YLineValue]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    // Create and render the chart
    const createChart = () => {
      const ctx = chartRef.current.getContext("2d");
      // Map initial data to chart data
     
      const chartData = initialData.slice(1).map((row) => {
        const originalR = parseFloat(row[flag ? 5 : 4]);
        return chartType === "Bar offer metric"
          ? {
              x: parseFloat(row[flag ? 7 : 6]),
              y: [parseFloat(row[flag ? 6 : 5]), originalR],
              r: originalR < 15 ? originalR / 2 : originalR / 5,
              label: row[flag ? 3 : 1],
              vendor: row[flag ? 1 : 0],
              status: row[2], // Status to distinguish "mandatory" vs "overall"
              originalR: originalR,
              CustomID: `bubble-${row[3]}`,
              offerId: row[flag ? 8 : 7],
              vendorId: row[flag ? 9 : 8],
            }
          : {
              x: parseFloat(row[flag ? 7 : 6]),
              y: parseFloat(row[flag ? 6 : 5]),
              r: originalR < 15 ? originalR / 2 : originalR / 5,
              label: row[flag ? 3 : 1],
              vendor: row[flag ? 1 : 0],
              status: row[2], // for mandatory or overall use case
              originalR: originalR,
              CustomID: `bubble-${row[3]}`,
              offerId: row[flag ? 8 : 7],
              vendorId: row[flag ? 9 : 8],
            };
      });
      
      // Destroy the previous chart instance if it exists
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
      
      //for color and labels of data
      const labels = isSmallScreen
        ? [...new Set(chartData.flatMap((d) => d.vendor))]
        : [...new Set(chartData.flatMap((d) => d.label))];
      const datasets =
        chartType === "Bar offer metric"
          ? [
              {
                label: "Overall Use Cases",
                data: chartData
                  .filter((d) => d.status === "overall")
                  .map((d) => ({
                    ...d,
                    y: d.y[0], // Set y to the first element in the y array
                  })),
                backgroundColor: theme.palette.primary.main,
                borderWidth: 0,
              },
              {
                label: "Offer Score (Overall)",
                data: chartData
                  .filter((d) => d.status === "overall")
                  .map((d) => ({
                    ...d,
                    y: d.y[1],
                  })),
                backgroundColor: "#8BD3D0",
                borderWidth: 0,
              },
              {
                label: "Mandatory Use Cases",
                data: chartData
                  .filter((d) => d.status === "mandatory")
                  .map((d) => ({
                    ...d,
                    y: d.y[0],
                  })),
                backgroundColor: theme.palette.secondary.main,
                borderWidth: 0,
              },
              {
                label: "Offer Score (Mandatory)",
                data: chartData
                  .filter((d) => d.status === "mandatory")
                  .map((d) => ({
                    ...d,
                    y: d.y[1],
                  })),
                backgroundColor: "#948BD3",
                borderWidth: 0,
              },
            ]
          :chartData.filter((d) => d.status === "overall").length>0&&chartData.filter((d) => d.status === "mandatory").length>0?
            [
              {
                label: "Overall Use Cases",
                data: chartData.filter((d) => d.status === "overall"),
                backgroundColor: theme.palette.primary.main,
                borderWidth: 0,
              },
              {
                label: "Mandatory Use Cases",
                data: chartData.filter((d) => d.status === "mandatory"),
                backgroundColor: theme.palette.secondary.main,
                borderWidth: 0,
              },
            ]:chartData.filter((d) => d.status === "overall").length>0?[
              {
                label: "Overall Use Cases",
                data: chartData.filter((d) => d.status === "overall"),
                backgroundColor: theme.palette.primary.main,
                borderWidth: 0,
              }
            ]:[
              {
                label: "Mandatory Use Cases",
                data: chartData.filter((d) => d.status === "mandatory"),
                backgroundColor: theme.palette.secondary.main,
                borderWidth: 0,
              }
            ];
      const config = {
        type: chartType.includes("Bar")
          ? "bar"
          : chartType.includes("scatter")
          ? "scatter"
          : "bubble",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              text: chartType.includes("Bar") ? "The Offer Metric" : "The Valyou Metrics",
              display: true,
              color: "#444",
              font: {
                family: "Oswald, sans-serif",
                weight: "bold",
                size: 20,
              },
            },
            legend: {
              labels: {
                font: {
                  family: "Oswald, sans-serif",
                  font: "bold",
                },
                usePointStyle: true,
              },
              display: true,
              position: "bottom",
              onClick: (e, legendItem) => {
                const chartInstance = legendItem.chart;
                const index = legendItem.datasetIndex;
                const meta = chartInstance.getDatasetMeta(index);

                // Toggle dataset visibility
                meta.hidden = meta.hidden === null ? true : !meta.hidden;
                chartInstance.update();

                // Update visible data in parent
                const visibleDatasets = chartInstance.data.datasets.filter(
                  (dataset) => !chartInstance.getDatasetMeta(dataset._datasetIndex).hidden
                );
                onUpdateVisibleData(visibleDatasets.map((dataset) => dataset.label));
              },
            },
            datalabels: {
              display: !chartType.includes("Bar") ? true : false,
              align: "right",
              anchor: "end",
              clip: true,
              formatter: (context) => {
                const vendor = context.vendor;
                return vendor && vendor !== "Unlock Vendor" ? vendor : ""; // Show only vendor name
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const { label, vendor, x, y, originalR } = context.raw;
                  return `${label} (${vendor}): (Score: ${originalR}%, Relevance: ${y}%, Utilization: ${x}%)`;
                },
              },
            },
            annotation: {
              annotations: {
                xLine: {
                  type: "line",
                  scaleID: "x",
                  value: chartType.includes("Bar") ? undefined : XLineValue.current,
                  borderColor: "gray",
                  borderWidth: 1,
                  borderDash: [6, 6],
                },
                yLine: {
                  type: "line",
                  scaleID: "y",
                  value: chartType.includes("Bar") ? undefined : YLineValue.current,
                  borderColor: "gray",
                  borderWidth: 1,
                  borderDash: [6, 6],
                },
                quadrant1: {
                  type: "label",
                  xValue: 100,
                  yValue: 100,
                  content: chartType.includes("Bar") ? undefined : "Smart",
                  font: { size: 15, weight: "bold" },
                  position: { x: "end", y: "start" },
                },
                quadrant2: {
                  type: "label",
                  xValue: 0,
                  yValue: 100,
                  content: chartType.includes("Bar") ? undefined : "Premium",
                  font: { size: 15, weight: "bold" },
                  position: { x: "start", y: "start" },
                },
                quadrant3: {
                  type: "label",
                  xValue: 0,
                  yValue: 0,
                  content: chartType.includes("Bar") ? undefined : "Low",
                  font: { size: 15, weight: "bold" },
                  position: { x: "start", y: "end" },
                },
                quadrant4: {
                  type: "label",
                  xValue: 100,
                  yValue: 0,
                  content: chartType.includes("Bar") ? undefined : "Budget",
                  font: { size: 15, weight: "bold" },
                  position: { x: "end", y: "end" },
                },
              },
            },
          },
          onClick: (e, elements) => {
            if (elements.length === 0) return;
            if (elements.length > 0 && elements[0].element.$context.raw.label !== "Unlock Vendor") {
              const datasetIndex = elements[0].datasetIndex;
              const index = elements[0].index;
              const selectedData = chartInstanceRef.current.data.datasets[datasetIndex].data[index];

              // Set the offer name and navigate to the offer details page
              setOfferName(selectedData?.label);
              localStorage.setItem("offerName", selectedData?.label);
              setVendorName(selectedData?.vendor);
              localStorage.setItem("vendorName", selectedData?.vendor);
              setOfferId(selectedData?.offerId);
              setVendorId(selectedData?.vendorId);
              localStorage.setItem("currentOffer", selectedData.label);
              localStorage.setItem("currentOfferId", selectedData.offerId);
              localStorage.setItem("currentVendorId", selectedData.vendorId);
              navigate("/results/offer-details", {
                state: {
                  vendor: selectedData.vendor,
                  offer: selectedData.label,
                },
              });
            } else {
              setNotification({
                open: true,
                message: "Please Unlock Vendor", //remainingVendor will be null when there no current package
                severity: "info",
              });
            }
          },
          scales: {
            x: {
              min: 0,
              max: 100,
              title: {
                display: true,
                text: chartType?.includes("Bar") ? "Vendors / Offers" : "Offer Utilization (%)",
                color: "#444",
                font: { size: 16, weight: "bold" },
              },
              ticks: { color: "#444", maxRotation: 0 },
            },
            y: {
              title: {
                display: true,
                text:
                  chartType === "Bar offer metric" ? "Offer Metric (%)" : "Offer Relevance (%)",
                color: "#444",
                font: { size: 16, weight: "bold" },
              },
              ticks: { color: "#444" },
            },
          },
        },
      };

      chartInstanceRef.current = new Chart(ctx, config);
    };

    if (chartRef.current) {
      createChart();
    }

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [
    isSmallScreen,
    chartType,
    initialData,
    navigate,
    onUpdateVisibleData,
    setOfferName,
    setVendorName,
    setOfferId,
    setVendorId,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.typography.h1,
    setNotification,
    flag,
  ]);

  return (
    <Box
      sx={{
        position: "relative",
        height: "400px",
        maxHeight: "400px",
        display: "flex",
      }}
    >
      <canvas data-test-id="chartcomponent" ref={chartRef} />
    </Box>
  );
};

export default ChartComponent;
