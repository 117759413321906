import React, {
  useContext,
  useState,
  useCallback,
  memo,
  Suspense,
  useEffect,
  lazy,
  useMemo,
  useRef,
} from "react";
import {
  Typography,
  Box,
  Collapse,
  IconButton,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
  ThemeProvider,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
import { useLocation, useNavigate } from "react-router-dom";
import { toPng } from "html-to-image";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UserProfileContext } from "../context/UserContext";
import { StaticReportContext } from "../context/StaticReportContext";
import OfferSummery from "./Widget/ViewReport/OfferSummery";
import QuadrantSummary from "./Widget/ViewReport/QuadrantSummary";
import theme from "../theme/theme";
import { BlobServiceClient } from "@azure/storage-blob";
import axios from "axios";
import ExecutiveSummary from "./Widget/ViewReport/ExecutiveSummary";
import { apiService } from "../utils/utils";
import { sectionQuadrantMap } from "./Utils/helper";
import BestOfBest from "./Widget/ComparativeAnalysisWidget/BestOfBest";
import UnmetUseCases from "./Widget/ComparativeAnalysisWidget/UnmetUseCases";
import Card from "./Widget/ComparativeAnalysisWidget/Card";
import DetailMetrics from "./Widget/ComparativeAnalysisWidget/DetailMetrics";
import CoverageHeatmap from "./Widget/ComparativeAnalysisWidget/CoverageHeatmap";
import ComparitiveSummary from "./Widget/ViewReport/ComparitiveSummary";
import { ComparativeTableMap } from "./Utils/helper";
const ChartComponent = lazy(() => import("./Widget/ComparativeAnalysisWidget/ChartComponent"));
const MandatoryUseCaseTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/MandatoryUseCaseTable")
);
const OverallUseCasesTable = lazy(() => import("./Widget/OfferDetailsWidget/OverallUseCasesTable"));
const SupportedUseCaseOverallTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/SupportedUseCaseOverallTable")
);
const UnusedGenericTable = lazy(() => import("./Widget/OfferDetailsWidget/UnusedGenericTable"));
const UnusedNotRequiredTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnusedNotRequiredTable")
);
const UnsupportedUseCaseMandatoryTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnsupportedUseCaseMandatoryTable")
);
const UnsupportedUseCaseOverallTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnsupportedUseCaseOverallTable")
);

const sections = [
  "ExecutiveSummary",
  "ComparativeSummary",
  "SmartValyou",
  "PremiumValyou",
  "BudgetValyou",
  "LowValyou",
];
const fields = [
  "offerMetrics",
  "supportedUseCases",
  "unsupportedUseCases",
  "unusedFeatures",
];
const fieldTables = {
  offerMetrics: ["mandatory", "overall"],
  supportedUseCases: ["overall"],
  unsupportedUseCases: ["mandatory", "overall"],
  unusedFeatures: ["generic", "notRequired"],
};
const fieldsHeading = {
  offerMetrics: "OfferMetrics",
  supportedUseCases: "SupportedUseCases",
  unsupportedUseCases: "UnsupportedUseCases",
  unusedFeatures: "UnusedFeatures",
};

const ViewReport = () => {
  const date = new Date().toLocaleString();
  const {
    userProfile,
    primaryMetric,
    currentOrganization,
    currentOrganizationId,
    scenarioName,
    currentPackage,
    currentBuyerGuideId,
    currentScenarioId,
    buyerGuideName,
    currentOrg,
  } = useContext(UserProfileContext);
  const {
        smartDataForStaticData,
        smartLoading,
        budgetDataForStaticData,
        budgetLoading,
        premiumDataForStaticData,
        lowLoading,
        lowDataForStaticData,
        premiumLoading,
        valyouMatrixData,
        initialDataForStatic
        } = useContext(StaticReportContext)
  const navigate = useNavigate();
  const companyName = userProfile?.companyName;
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [reportName, setReportName] = useState("");
  const [isReportLoading, setReportLoading] = useState(false);
  const location = useLocation();
  const reportdata = location?.state?.reportdata;
  const generateReport = location?.state?.generateReport;
  const [openCommentryBox, setCommentryBox] = useState(false);
  const sessionStorageUserGptKey = sessionStorage.getItem("userGptKey");
  const localStorageUserGptKey = localStorage.getItem("userGptKey");
  const [chatGptKey, setchatGptKey] = useState("");
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);
  const [saveKey, setsaveKey] = useState(false);
  //const [storageOption, setStorageOption] = useState("session");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const initialAccordionState = useMemo(
    () =>
      sections.reduce(
        (acc, section) => ({
          ...acc,
          [section]: section === "ExecutiveSummary",
        }),
        {}
      ),
    []
  );
  const onChangeReportName = (event) => {
    setReportName(event.target.value);
  };
  const onChangeChatGptkey = (event) => {
    setchatGptKey(event.target.value);
  };
  const commentaryTemplate = useMemo(
    () => ({
      commentary: "...loading",
      prompt: "",
      aiCommentary: "",
      showBox: false,
      isLoading: true,
      generateCount: 3,
    }),
    []
  );
  const [executiveSummarydetail, setExecutiveSummary] = useState(commentaryTemplate);
  const handleExport = async () => {
    setAccordionState((prevState) => ({
      ...prevState,
      ExecutiveSummary: true,
      SmartValyou: true,
      PremiumValyou: true,
      BudgetValyou: true,
      LowValyou: true,
    }));
    const styleSheets = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("\n");
        } catch (e) {
          if (e.name !== "SecurityError") {
            console.error("Failed to access stylesheet:", e.message);
          }
          return "";
        }
      })
      .join("\n");

    const dataUrl = await toPng(document.getElementById("blob"));
    var img = new Image();
    img.src = dataUrl;
    img.alt = "Exported content";
    const htmlFile = document.getElementById("htmlContent");
    const newHTMLFile = htmlFile.cloneNode(true);
    const targetElement = newHTMLFile.querySelector("#blob");
    if (targetElement) {
      setReportLoading(true);
      targetElement.innerHTML = "";
      img.style.maxWidth = "100%"; // Ensure the image fits within the container
      img.style.height = "auto"; // Maintain aspect ratio
      img.style.objectFit = "contain"; // Ensure it scales without distortion
      img.alt = "Exported content";
      targetElement.appendChild(img);

      const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <style>
          ${styleSheets}
          img {
            max-width: 100%;   /* Ensure the image does not overflow */
            height: auto;      /* Maintain aspect ratio */
            object-fit: contain; /* Ensure the image scales well within the container */
          }
        </style>
    </head>
    <body>
        <div>${newHTMLFile.innerHTML}</div>
    </body>
    </html>
  `;

      try {
        const writeResponse = await axios.get(
          "https://newtestfuncpython.azurewebsites.net/api/generateWriteSasToken"
        );

        const blobServiceClient = BlobServiceClient.fromConnectionString(
          `BlobEndpoint=https://newtestfuncpython.blob.core.windows.net/;QueueEndpoint=https://newtestfuncpython.queue.core.windows.net/;FileEndpoint=https://newtestfuncpython.file.core.windows.net/;TableEndpoint=https://newtestfuncpython.table.core.windows.net/;SharedAccessSignature=${writeResponse?.data.writeSasToken}`
        );
        const containerClient = blobServiceClient.getContainerClient("staticreport");
        const currentDate = Date.now();
        const blockBlobClient = containerClient.getBlockBlobClient(
          `${currentOrganization}-${scenarioName}-${reportName}-${currentDate}.html`
        );
        const options = {
          blobHTTPHeaders: {
            blobContentType: "text/html", // Set Content-Type as text/html
            blobContentDisposition: "inline", // Set Content-Disposition as inline
          },
        };
        await blockBlobClient.upload(htmlContent, htmlContent.length, options);

        /*const readResponse = await axios.get(
          "http://127.0.0.1:4000/generateReadSasToken"
        );*/

        const response = await apiService(
          "http://localhost:7071/api/finalizeReport?",
          {
            method: "POST",
            data: {
              creator: userProfile?.email,
              currentOrganisation: currentOrganizationId,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
              reportName: reportName,
              urlPreview: `https://newtestfuncpython.blob.core.windows.net/staticreport/${currentOrganization}-${scenarioName}-${reportName}-${currentDate}.html`,
            },
          }
        );

        if (response?.["Successfully Created"]) {
          localStorage.removeItem("smartData");
          localStorage.removeItem("valyouMatrixData");
          localStorage.removeItem("premiumData");
          localStorage.removeItem("lowData");
          localStorage.removeItem("initialData");
          localStorage.removeItem("budgetData");
          smartDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          premiumDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          budgetDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          lowDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          navigate("/saved-reports");
        }
        setOpen(false);
        setReportLoading(false);
        setReportName("");
      } catch (error) {
        console.error("Error uploading HTML file:", error.message);
      }
    }
  };

  const convertToHTML = (text) => {
    const normalizedText = (text || "").toString().replace(/\r\n/g, "\n");
    return normalizedText.split("\n\n").map((paragraph, index) => (
      <p key={index}>
        {paragraph.split("\n").map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    ));
  };

  const offerTemplate = useMemo(
    () => ({
      offerSummary: {
        showBox: false,
        isLoading: true,
        aiCommentary: " ",
        commentary: "ankush",
        prompt: " ",
        all_widget_summaries: {},
        widget_jsons_input: {},
        generateCount: 3,
      },
      offerMetrics: {
        tables: {
          mandatory: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Offer Metrics - Mandatory Use Cases",
            generateCount: 3,
          },
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Offer Metrics - Overall Use Cases",
            generateCount: 3,
          },
        },
      },
      supportedUseCases: {
        tables: {
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Supported Use Cases (Overall)",
            generateCount: 3,
          },
        },
      },
      unsupportedUseCases: {
        tables: {
          mandatory: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unsupported Use Cases (Mandatory)",
            generateCount: 3,
          },
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unsupported Use Cases (Overall)",
            generateCount: 3,
          },
        },
      },
      unusedFeatures: {
        tables: {
          generic: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unusued Features (Generic)",
            generateCount: 3,
          },
          notRequired: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unused Features ('Not Required' Use Cases)",
            generateCount: 3,
          },
        },
      },
    }),
    []
  );
  
  const ComparativeSummaryTemplate = useMemo(
    () => ({
      detailMatrix: {
        showBox: false,
        isLoading: true,
        aiCommentary: "",
        commentary: "...Loading",
        prompt: "",
        tableData:null,
        widgetName: "Detail Metrics",
        generateCount: 3,
      },
      bestOfBest: {
        showBox: false,
        isLoading: true,
        aiCommentary: "",
        commentary: "...Loading",
        prompt: "",
        tableData:null,
        widgetName: "The Best Of the Best",
        generateCount: 3,
      },
      unMetUseCase: {
        showBox: false,
        isLoading: true,
        aiCommentary: "",
        commentary: "...Loading",
        prompt: "",
        tableData:null,
        widgetName: "Top Unmet Use Case across all Vendors/Offer",
        generateCount: 3,
      },
      coverageHeatmap: {
        showBox: false,
        isLoading: true,
        aiCommentary: "",
        commentary: "...Loading",
        prompt: "",
        tableData:null,
        widgetName: "Coverage HeatMap",
        generateCount: 3,
      },
      relevantVendors: {
        showBox: false,
        isLoading: true,
        aiCommentary: "",
        commentary: "...Loading",
        prompt: "",
        tableData:null,
        widgetName: "Relevant Vendors",
        generateCount: 3,
      },
      relevantOffers: {
        showBox: false,
        isLoading: true,
        aiCommentary: "",
        commentary: "...Loading",
        prompt: "",
        tableData:null,
        widgetName: "Relevant Offers",
        generateCount: 3,
      },
      sectionSummary: {
        showBox: false,
        isLoading: true,
        aiCommentary: " ",
        commentary: "...Loading",
        prompt: " ",
        widget_jsons_input: {},
        generateCount: 3,
      },
    }),
    []
  );
  const [accordionState, setAccordionState] = useState(initialAccordionState);
  const [commentary, setCommentary] = useState({});
  const [promptsState, setPromptsState] = useState({});
  const [promptVisible, setPromptVisible] = useState(false);
  const [hoveredBoxes, setHoveredBoxes] = useState({});

  useEffect(() => {
    let initialCommentary = {
      ComparativeSummary: ComparativeSummaryTemplate,
      SmartValyou:
        smartDataForStaticData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: " ",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
              generateCount: 3,
            },
          }
        ) || {},
      PremiumValyou:
        premiumDataForStaticData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
              generateCount: 3,
            },
          }
        ) || {},
      BudgetValyou:
        budgetDataForStaticData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
              generateCount: 3,
            },
          }
        ) || {},
      LowValyou:
        lowDataForStaticData?.items?.reduce((acc, item) => ({ ...acc, [item]: offerTemplate }), {
          quadrantSummary: {
            showBox: false,
            aiCommentary: "",
            commentary: "",
            prompt: "",
            isLoading: true,
            offer_summaries: [],
          },
        }) || {},
    };
    setCommentary(initialCommentary);
    setPromptsState(initialCommentary);
  }, [
    ComparativeSummaryTemplate,
    smartDataForStaticData,
    premiumDataForStaticData,
    budgetDataForStaticData,
    lowDataForStaticData,
    commentaryTemplate,
    offerTemplate,
  ]);
  const toggleAccordion = useCallback((section) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  }, []);
  const handleComparitivePromptChange = useCallback(
    (table , value) => {
      setPromptsState((prevState) => ({
        ...prevState,
        ComparativeSummary: {
          ...prevState?.ComparativeSummary,
          [table]: {
            ...prevState?.ComparativeSummary?.[table],
            prompt: value
          },
        },
      }));
    },
    []
  );
  const handlePromptChange = useCallback(
    (section, offer, field, table, value) => {
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            [field]: {
              ...prevState[section][offer][field],
              tables: {
                ...prevState[section][offer][field].tables,
                [table]: {
                  ...prevState[section][offer][field].tables[table],
                  prompt: value,
                },
              },
            },
          },
        },
      }));
    },
    []
  );
  
  const handleOfferPromptChange = useCallback((section, offer, value) => {
    setPromptsState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          offerSummary: {
            ...prevState[section][offer].offerSummary,
            prompt: value,
          },
        },
      },
    }));
  }, []);

  const handleSectionPromptChange = useCallback((section, value) => {
    setPromptsState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        quadrantSummary: {
          ...prevState[section].quadrantSummary,
          prompt: value,
        },
      },
    }));
  }, []);
  const generateExecutiveCommentary = useCallback(async () => {
    const attemptLeft = executiveSummarydetail?.generateCount;
    if (attemptLeft <= 0) {
      setCommentryBox(true);
      return;
    } else {
      const prompt = executiveSummarydetail.prompt.trim();
      const quadrant = [
        "SmartValyou",
        "PremiumValyou",
        "BudgetValyou",
        "LowValyou",
      ];
      let quadrantSummaries = quadrant.map((section) => {
        if (!commentary[section]?.quadrantSummary?.isLoading) {
          return commentary[section]?.quadrantSummary?.commentary;
        }
        return null;
      });
      quadrantSummaries = quadrantSummaries.filter((summary) => {
        if (summary) {
          return true;
        }
        return false;
      });
      
      if (quadrantSummaries.length > 0) {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          quadrantAnalyses: quadrantSummaries,
          comparativeOverallSummary:
            commentary?.ComparativeSummary?.sectionSummary?.commentary,
          
          userPrompt: prompt,
        };
        setExecutiveSummary((prev) => ({
              ...prev,
              commentary: response?.executiveSummary,
              prompt: "",
              generateCount:response.maxGenerationLimit - response?.currentGenerationCount,
              isLoading: true,
            }));
        const response =await apiService(
          `https://newtestfuncpython.azurewebsites.net/api/generateExecutiveSummary?`,
          {
            method: "POST",
            data: payload,
          })

        setExecutiveSummary((prev) => ({
              ...prev,
              commentary: response?.executiveSummary,
              prompt: "",
              generateCount:response.maxGenerationLimit - response?.currentGenerationCount,
              isLoading: false,
            }));

        localStorage.setItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-ExecutiveSummary`,
              JSON.stringify(response)
            );
         
      }
    }
  }, [
    executiveSummarydetail,
    commentary,
    buyerGuideName,
    currentOrg,
    scenarioName,
    userProfile,
    currentScenarioId,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage,
  ]);
  console.log("executive",executiveSummarydetail)
  const generateCommentary = useCallback(
    async (section, offer, field, table) => {
      try {
        const attemptLeft =
          commentary[section][offer][field].tables[table]?.generateCount;
        if (attemptLeft <= 0) {
          setCommentryBox(true);
          return;
        } else {
          const prompt =
            promptsState?.[section]?.[offer]?.[field]?.tables[
              table
            ]?.prompt.trim();
          const widgetName =
            commentary[section][offer][field].tables[table].widgetName;
          const data =
            commentary[section][offer]?.offerSummary?.widget_jsons_input?.[
              widgetName
            ];

          const MandatoryOffers = valyouMatrixData?.MandatoryOffers || [];
          const OverallOffers = valyouMatrixData?.OverallOffers || [];

          let vendorId = null;
          let offerId = null;
          const index = OverallOffers?.findIndex((item) => {
            return item?.OfferName === offer ? true : false;
          });

          if (index > -1) {
            vendorId = OverallOffers[index]?.vendorId;
            offerId = OverallOffers[index]?.offerId;
          } else {
            const index = MandatoryOffers?.findIndex((item) => {
              return item?.OfferName === offer ? true : false;
            });
            vendorId = MandatoryOffers[index]?.vendorId;
            offerId = MandatoryOffers[index]?.offerId;
          }
          /*
      const index =
        OverallOffers?.findIndex((item) => {
          return item?.OfferName === offer ? true : false;
        }) ||
        MandatoryOffers?.findIndex((item) => {
          return item?.OfferName === offer ? true : false;
        });
      const vendorName =
        OverallOffers[index]?.VendorName || MandatoryOffers[index]?.VendorName;*/

          const payload = {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage.id,
            currentScenario: currentScenarioId,
            currentVendor: vendorId,
            currentOffer: offerId,
            reportWidget: widgetName,
            widgetJson: data,
            quadrantType: sectionQuadrantMap[section],
            userPrompt: prompt,
          };

          setCommentary((prevState) => ({
            ...prevState,
            [section]: {
              ...prevState[section],
              [offer]: {
                ...prevState[section][offer],
                [field]: {
                  ...prevState[section][offer][field],
                  tables: {
                    ...prevState[section][offer][field].tables,
                    [table]: {
                      ...prevState[section][offer][field].tables[table],
                      isLoading: true,
                    },
                  },
                },
              },
            },
          }));

          const response = await apiService(
            "https://newtestfuncpython.azurewebsites.net/api/generateWidgetSummary?",
            {
              method: "POST",
              data: payload,
            }
          );
          localStorage.setItem(
            `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-MandatoryUsecase`,
            JSON.stringify({
              summary: response,
              generateCount: attemptLeft - 1,
            })
          );

          setCommentary((prev) => {
            return {
              ...prev,
              [section]: {
                ...prev[section],
                [offer]: {
                  ...prev[section][offer],
                  [field]: {
                    ...prev[section][offer][field],
                    tables: {
                      ...prev[section][offer][field].tables,
                      [table]: {
                        ...prev[section][offer][field].tables[table],
                        commentary: response.widgetSummary,
                        isLoading: false,
                        generateCount:
                          response.maxGenerationLimit -
                          response.currentGenerationCount,
                      },
                    },
                  },
                  offerSummary: {
                    ...prev[section][offer].offerSummary,
                    all_widget_summaries: {
                      ...prev[section][offer].offerSummary.all_widget_summaries,
                      [widgetName]: response.widgetSummary,
                    },
                    widget_jsons_input: {
                      ...prev[section][offer].offerSummary.widget_jsons_input,
                      [widgetName]: data,
                    },
                  },
                },
              },
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      buyerGuideName,
      commentary,
      currentOrg,
      promptsState,
      scenarioName,
      valyouMatrixData,
      userProfile,
      currentScenarioId,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage,
    ]
  );

  const generateSectionCommentary = useCallback(
    async (section) => {
      const attemptLeft = commentary?.[section]?.quadrantSummary?.generateCount;
      if (attemptLeft <= 0) {
        setCommentryBox(true);
        return;
      } else {
        const prompt = promptsState[section]?.quadrantSummary?.prompt;
        const offer_summaries =
          commentary[section].quadrantSummary.offer_summaries;

        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          quadrantType: sectionQuadrantMap[section],
          offerSummaries: offer_summaries,
          userPrompt: prompt,
        };

        setCommentary((prevState) => ({
          ...prevState,
          [section]: {
            ...prevState[section],
            quadrantSummary: {
              ...prevState[section].quadrantSummary,
              // Clear the prompt after generating commentary
              isLoading: true,
            },
          },
        }));
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/generateQuadrantSummary?",
          {
            method: "POST",
            data: payload,
          }
        );
        setCommentary((prevState) => ({
          ...prevState,
          [section]: {
            ...prevState[section],
            quadrantSummary: {
              ...prevState[section].quadrantSummary,
              commentary: response?.quadrantSummary,
              generateCount:
                response.maxGenerationLimit - response.currentGenerationCount,
              isLoading: false,
            },
          },
        }));
        setPromptsState((prevState) => ({
          ...prevState,
          [section]: {
            ...prevState[section],
            quadrantSummary: {
              ...prevState[section].quadrantSummary,
              commentary: "",
              prompt: "", // Clear the prompt after generating commentary
            },
          },
        }));
        localStorage.setItem(
          `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-sectionSummary`,
          JSON.stringify(response)
        );
      }
    },
    [
      commentary,
      promptsState,
      buyerGuideName,
      userProfile,
      currentScenarioId,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage,
      currentOrg,
      scenarioName,
    ]
  );
  
  const generateOfferCommentary = useCallback(
    async (section, offer) => {
      const attemptLeft =
        commentary[section]?.[offer]?.offerSummary?.generateCount;

      if (attemptLeft <= 0) {
        setCommentryBox(true);
        return;
      } else {
        const prompt =
          promptsState?.[section]?.[offer]?.offerSummary?.prompt.trim();
        const MandatoryOffers = valyouMatrixData?.MandatoryOffers || [];
        const OverallOffers = valyouMatrixData?.OverallOffers || [];
        let vendorId = null;
        let offerId = null;
        const index = OverallOffers?.findIndex((item) => {
          return item?.OfferName === offer ? true : false;
        });

        if (index > -1) {
          vendorId = OverallOffers[index]?.vendorId;
          offerId = OverallOffers[index]?.offerId;
        } else {
          const index = MandatoryOffers?.findIndex((item) => {
            return item?.OfferName === offer ? true : false;
          });
          vendorId = MandatoryOffers[index]?.vendorId;
          offerId = MandatoryOffers[index]?.offerId;
        }

        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          currentVendor: vendorId,
          currentOffer: offerId,
          quadrantType: sectionQuadrantMap[section],
          allWidgetSummaries:
            commentary[section][offer]?.offerSummary.all_widget_summaries,
          userPrompt: prompt,
        };

        setCommentary((prevState) => ({
          ...prevState,
          [section]: {
            ...prevState[section],
            [offer]: {
              ...prevState[section][offer],
              offerSummary: {
                ...prevState[section][offer]?.offerSummary,
                isLoading: true,
              },
            },
          },
        }));

        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/generateOfferSummary?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
          localStorage.setItem(
            `${currentOrg}-${buyerGuideName}-${scenarioName}-${offer}-OfferSummary`,
            JSON.stringify(response)
          );

          setCommentary((prev) => {
            const currentOfferSummary =
              prev[section]?.[offer]?.offerSummary?.commentary;
            let currentOfferSummarries =
              prev[section]?.quadrantSummary?.offer_summaries;

            const currentOfferSummaryIndex = currentOfferSummarries.findIndex(
              (summary) => {
                return currentOfferSummary === summary;
              }
            );

            if (currentOfferSummaryIndex > -1) {
              currentOfferSummarries[currentOfferSummaryIndex] =
                response?.offerSummary;

              return {
                ...prev,
                [section]: {
                  ...prev[section],
                  [offer]: {
                    ...prev[section][offer],
                    offerSummary: {
                      ...prev[section][offer].offerSummary,
                      isLoading: false,
                      commentary: response.offerSummary,
                      generateCount:
                        response.maxGenerationLimit -
                        response.currentGenerationCount,
                    },
                  },
                  quadrantSummary: {
                    ...prev[section].quadrantSummary,
                    offer_summaries: [...currentOfferSummarries],
                  },
                },
              };
            } else {
              return prev;
            }
          });
        }

        setPromptsState((prevState) => ({
          ...prevState,
          [section]: {
            ...prevState[section],
            [offer]: {
              ...prevState[section][offer],
              offerSummary: {
                ...prevState[section][offer]?.offerSummary,
                prompt: "",
              },
            },
          },
        }));
      }
    },
    [
      commentary,
      promptsState,
      buyerGuideName,
      currentOrg,
      scenarioName,
      valyouMatrixData,
      userProfile,
      currentScenarioId,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage,
    ]
  );
  console.log("commentry",commentary);
  console.log("prompt",promptsState)
  const generateComparativeSummary = useCallback(async(section,table)=>{
     const attemptLeft = 
        commentary?.ComparativeSummary?.[table]?.generateCount;
     if (attemptLeft <= 0) {
        setCommentryBox(true);
        return;
      }else{
          const prompt =
          promptsState?.ComparativeSummary?.[table]?.prompt.trim();
          const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          reportComparativeTable:table!=="sectionSummary"?ComparativeTableMap[table]:null,
          comparativeTableJson:table!=="sectionSummary"?commentary?.ComparativeSummary?.[table]:null,
          userPrompt: prompt,
          }
          setPromptsState((prev) => {
                return {
                  ...prev,
                  [section]:{
                    ...prev?.[section],
                    [table]:{
                        prompt:"",
                        
                    }
                  }

              }
              });
          setCommentary((prev) => {
                return {
                  ...prev,
                  [section]:{
                    ...prev?.[section],
                    [table]:{
                        ...prev[section][table],
                        isLoading:true,
                    }
                  }
              }
              });
          
          const endpoint = table!=="sectionSummary"?"generateComparativeSummary":"generateOverAllSummary"
          const response = await apiService(
          `https://newtestfuncpython.azurewebsites.net/api/${endpoint}?`,
          {
            method: "POST",
            data: payload,
          }
          );
          if (table !== "sectionSummary") {
            localStorage.setItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
              JSON.stringify(response)
            );
          } else {
            localStorage.setItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-overAllSummary`,
              JSON.stringify(response)
            );
          }
          const commentry= table!=="sectionSummary"? response?.comparativeSummar:response?.overallSummary
          setCommentary((prevState) => ({
          ...prevState,
          [section]: {
            ...prevState[section],
            [table]: {
              commentary:commentry,
              generateCount:
                        response.maxGenerationLimit -
                        response.currentGenerationCount,
              isLoading:false
            },
          },
        }));
      }
  },
    [
      commentary,
      promptsState,
      buyerGuideName,
      currentOrg,
      scenarioName,
      userProfile,
      currentScenarioId,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage,
    ]
  );
  const toggleShowBox = useCallback((section, offer, field, table) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          [field]: {
            ...prevState[section][offer][field],
            tables: {
              ...prevState[section][offer][field].tables,
              [table]: {
                ...prevState[section][offer][field].tables[table],
                showBox:
                  !prevState[section][offer][field].tables[table]?.showBox,
              },
            },
          },
        },
      },
    }));
  }, []);
  const toggleOfferSummary = useCallback((section, offer) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          offerSummary: {
            ...prevState[section][offer].offerSummary,
            showBox: !prevState[section][offer].offerSummary?.showBox,
          },
        },
      },
    }));
  }, []);
  const toggleSectionSummary = useCallback((section) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        quadrantSummary: {
          ...prevState[section].quadrantSummary,
          showBox: !prevState[section].quadrantSummary?.showBox,
        },
      },
    }));
  }, []);
  const toggleComparativeSummary = useCallback((table) => {
    setCommentary((prevState) => ({
      ...prevState,
      ComparativeSummary: {
        ...prevState?.ComparativeSummary,
        [table]: {
          ...prevState?.ComparativeSummary?.[table],
          showBox: !prevState?.ComparativeSummary?.[table].showBox,
        },
      },
    }));
  }, []);
  const handleMouseEnter = useCallback((key) => {
    setHoveredBoxes((prev) => ({ ...prev, [key]: true }));
  }, []);

  const handleMouseLeave = useCallback((key) => {
    setHoveredBoxes((prev) => ({ ...prev, [key]: false }));
  }, []);
  const handleAddKey = () => {
    if (!disclaimerChecked || chatGptKey.trim() === "") {
      // Show Snackbar warning if the checkbox is unchecked or text field is empty
      setSnackbarOpen(true);
      return;
    }

    if (!saveKey) {
      sessionStorage.setItem("userGptKey", chatGptKey);
    } else {
      localStorage.setItem("userGptKey", chatGptKey);
    }
    setCommentryBox(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const renderTable = useCallback(
    (field, section, offer, table, offerData) => {
      const tableComponents = {
        offerMetrics: {
          mandatory: (
            <MandatoryUseCaseTable
              key={`${section}-${offer}-${field}-mandatory`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              offerData={offerData}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
              generateReport={generateReport}
            />
          ),
          overall: (
            <OverallUseCasesTable
              key={`${section}-${offer}-${field}-overall`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
              generateReport={generateReport}
            />
          ),
        },
        supportedUseCases: {
          overall: (
            <SupportedUseCaseOverallTable
              key={`${section}-${offer}-${field}-supported`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
              generateReport={generateReport}
            />
          ),
        },
        unsupportedUseCases: {
          mandatory: (
            <UnsupportedUseCaseMandatoryTable
              key={`${section}-${offer}-${field}-unsupported-mandatory`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
              generateReport={generateReport}
            />
          ),
          overall: (
            <UnsupportedUseCaseOverallTable
              key={`${section}-${offer}-${field}-unsupported-overall`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
              generateReport={generateReport}
            />
          ),
        },
        unusedFeatures: {
          generic: (
            <UnusedGenericTable
              key={`${section}-${offer}-${field}-unused-generic`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
              generateReport={generateReport}
            />
          ),
          notRequired: (
            <UnusedNotRequiredTable
              key={`${section}-${offer}-${field}-unused-notrequired`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
              generateReport={generateReport}
            />
          ),
        },
      };
      return <Suspense fallback={<div>Loading...</div>}>{tableComponents[field][table]}</Suspense>;
    },
    [commentary, generateReport]
  );
  
  const renderComparitiveTable = useCallback((table) => {
    const tableComponents = {
      bestOfBest: (
        <BestOfBest
          isViewReport={true}
          generateReport={generateReport}
          setCommentary={setCommentary}
          table={table}
        />
      ),
      detailMatrix: (
        <DetailMetrics
          isViewReport={true}
          generateReport={generateReport}
          setCommentary={setCommentary}
          isTableVisible={true}
           table={table}
        />
      ),
      
      unMetUseCase: (
        <UnmetUseCases
          widgetKey={"unmet_use_cases"}
          isViewReport={true}
          generateReport={generateReport}
          setCommentary={setCommentary}
          table={table}
        />
      ),
      coverageHeatmap: (
        <CoverageHeatmap
          isViewReport={true}
          generateReport={generateReport}
          setCommentary={setCommentary}
          table={table}
        />
      ),
      relevantVendors: (
        <Card
          widgetLabels={"Relevant Vendors"}
          isViewReport={true}
          generateReport={generateReport}
          setCommentary={setCommentary}
          table={table}
        />
      ),
      relevantOffers: (
        <Card
          widgetLabels={"Relevant Offers"}
          isViewReport={true}
          generateReport={generateReport}
          setCommentary={setCommentary}
          table={table}
        />
      ),
    };
    return (
      <Suspense fallback={<div>Loading...</div>}>
        {tableComponents[table]}
      </Suspense>
    );
  }, [generateReport]);

  const renderComparativeSummary = useCallback(() => {
    return (
      <Box>
        {commentary &&
          commentary?.ComparativeSummary &&
        <Box sx={{ display: "flex", alignItems: "self-start" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ cursor: "pointer" }}>
              <Box
                sx={{
                  ...commentaryBoxStyles,
                  marginTop: "10px",
                }}
                onMouseEnter={() => handleMouseEnter(`ComparativeSummary`)}
                onMouseLeave={() => handleMouseLeave(`ComparativeSummary`)}
              >
                <Typography variant="h4">OverallSummary</Typography>
                <ComparitiveSummary
                  commentary={commentary}
                  setCommentary={setCommentary}
                  generateReport={generateReport}
                />
                <Box
                  sx={{
                    alignItems: "flex-start",
                    display: hoveredBoxes[`ComparativeSummary`]
                      ? "flex"
                      : "none",
                    transition: "opacity 0.3s",
                  }}
                >
                  <Tooltip title="Copy" disableInteractive arrow>
                    <IconButton size="small">
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Regenerate" disableInteractive arrow>
                    <IconButton
                      size="small"
                      onClick={() => toggleComparativeSummary("sectionSummary")}
                    >
                      <ChangeCircleIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Collapse
              in={commentary?.ComparativeSummary?.["sectionSummary"]?.showBox}
              style={{
                transitionDelay: commentary?.ComparativeSummary?.[
                  "sectionSummary"
                ]?.showBox
                  ? "200ms"
                  : "0ms",
              }}
            >
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <TextField
                  label="Prompt"
                  fullWidth
                  multiline
                  maxRows={4}
                  value={
                    promptsState?.ComparativeSummary?.sectionSummary?.prompt
                  }
                  onChange={(e) =>
                    handleComparitivePromptChange(
                      "sectionSummary",
                      e.target.value
                    )
                  }
                  key={`ComparativeSummary-prompt`}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                      generateComparativeSummary("ComparativeSummary","sectionSummary")}
                    }
                    sx={{ marginTop: "10px" }}
                  >
                    Generate Commentary
                  </Button>
                  <Typography variant="h6">
                    Attempt Left:{" "}
                    {commentary?.ComparativeSummary?.["sectionSummary"]?.generateCount}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </Box>}
        {commentary &&
          commentary?.ComparativeSummary &&
          Object.keys(commentary?.ComparativeSummary)
            .filter((table) => table !== "sectionSummary")
            .map((table) => {
              return (
                <Box
                  key={`ComparativeSummary-${table}`}
                  marginTop={3}
                  onMouseEnter={() =>
                    handleMouseEnter(`ComparativeSummary-${table}`)
                  }
                  onMouseLeave={() =>
                    handleMouseLeave(`ComparativeSummary-${table}`)
                  }
                >
                 <Typography variant="h4">{commentary?.ComparativeSummary?.[table]?.widgetName}</Typography>
                  {renderComparitiveTable(table)}
                  <Box
                    sx={{
                      ...commentaryBoxStyles,
                      marginTop: "10px",
                    }}
                  >
                    <Typography>
                      {commentary?.ComparativeSummary?.[table]?.isLoading ? (
                        <Box mt={4} textAlign="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        convertToHTML(
                          commentary?.ComparativeSummary?.[table]?.commentary
                        )
                      )}{" "}
                    </Typography>
                    <Box
                      sx={{
                        alignItems: "flex-start",
                        display: true ? "flex" : "none",
                        transition: "opacity 0.3s",
                      }}
                    >
                      <Tooltip title="Copy" disableInteractive arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              commentary?.ComparativeSummary?.[table]
                                ?.commentary
                            );
                          }}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Regenerate" disableInteractive arrow>
                        <IconButton
                          size="small"
                          onClick={() => toggleComparativeSummary(table)}
                        >
                          <ChangeCircleIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Collapse
                      in={commentary?.ComparativeSummary?.[table]?.showBox}
                    >
                      <Box sx={{ marginTop: "10px" }}>
                        <TextField
                          label="Table Prompt"
                          fullWidth
                          multiline
                          maxRows={4}
                          value={
                            promptsState?.ComparativeSummary?.[table]?.prompt
                          }
                          onChange={(e) =>
                            handleComparitivePromptChange(table, e.target.value)
                          }
                          key={`Comparitive-${table}-prompt`}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                                 generateComparativeSummary("ComparativeSummary",table)
                               }
                            }
                            sx={{ marginTop: "10px" }}
                          >
                            Generate Commentary
                          </Button>
                          <Typography variant="h6">
                            Attempt Left:{" "}
                            {
                              commentary?.ComparativeSummary?.[table]
                                ?.generateCount
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </Collapse>
                  </Box>
                </Box>
              );
            })}
        
      </Box>
    );
  }, [commentary,promptsState,handleComparitivePromptChange, handleMouseEnter, handleMouseLeave, renderComparitiveTable, toggleComparativeSummary,generateReport, hoveredBoxes,generateComparativeSummary]);

  const renderOfferDetails = useCallback(
    (section, data, loading, otherData) => {
      return loading ? (
        <Typography variant="h6">{section} data is loading...</Typography>
      ) : data?.length === 0 ? (
        commentary && commentary[section] ? (
          <QuadrantSummary
            commentary={commentary}
            section={section}
            setCommentary={setCommentary}
            data={data}
            generateReport={generateReport}
          />
        ) : (
          <Box mt={4} textAlign="center">
            <CircularProgress />
          </Box>
          // <Loader open={true} />
        )
      ) : (
        <Box>
          {commentary && commentary[section] && (
            <Box marginTop={3}>
              <Typography variant="h3">Quadrant Summary</Typography>
              {commentary[section]?.quadrantSummary && (
                <Box
                  sx={{ ...commentaryBoxStyles, marginTop: "10px" }}
                  onMouseEnter={() => handleMouseEnter(`${section}-quadrantSummary`)}
                  onMouseLeave={() => handleMouseLeave(`${section}-quadrantSummary`)}
                >
                  <Box>
                    <QuadrantSummary
                      commentary={commentary}
                      section={section}
                      setCommentary={setCommentary}
                      data={data}
                      generateReport={generateReport}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "flex-start",
                      display: hoveredBoxes[`${section}-quadrantSummary`] ? "flex" : "none",
                      transition: "opacity 0.3s",
                    }}
                  >
                    <Tooltip title="Copy" disableInteractive arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            commentary[section]?.quadrantSummary?.commentary
                          );
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Regenerate" disableInteractive arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          toggleSectionSummary(section);
                        }}
                      >
                        <ChangeCircleIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <Collapse in={commentary[section].quadrantSummary?.showBox}>
                <Box sx={{ alignItems: "center", marginTop: "10px" }}>
                  <TextField
                    label="Quadrant Prompt"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={promptsState[section].quadrantSummary?.prompt}
                    onChange={(e) => handleSectionPromptChange(section, e.target.value)}
                    key={`${section}`}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => generateSectionCommentary(section)}
                      sx={{ marginTop: "10px" }}
                    >
                      Generate Commentary
                    </Button>
                    <Typography variant="h6">
                      Attempt Left:{" "}
                      {commentary[section]?.quadrantSummary?.generateCount}
                    </Typography>
                  </Box>
                </Box>
              </Collapse>
            </Box>
          )}
          {commentary &&
            commentary[section] &&
            Object.keys(commentary[section])
              .filter((offer) => offer !== "Unlock Vendor" && offer !== "quadrantSummary")
              .map((offer) => {
                return (
                  <Box key={`${section}-${offer}`} marginTop={3}>
                    <Typography variant="h4" marginTop={3}>
                      {offer}
                    </Typography>
                    <Box>
                      <Typography variant="subtitle2" marginTop={3}>
                        Offer Summary
                      </Typography>
                      {commentary[section][offer]?.offerSummary?.commentary && (
                        <Box
                          sx={{ ...commentaryBoxStyles, marginTop: "10px" }}
                          onMouseEnter={() => handleMouseEnter(`${section}-${offer}-offerSummer`)}
                          onMouseLeave={() => handleMouseLeave(`${section}-${offer}-offerSummer`)}
                        >
                          <Typography>
                            <OfferSummery
                              section={section}
                              offer={offer}
                              commentary={commentary}
                              setCommentary={setCommentary}
                              data={data}
                              generateReport={generateReport}
                              offerData={otherData[offer]}
                            />
                          </Typography>
                          <Box
                            sx={{
                              alignItems: "flex-start",
                              display: hoveredBoxes[`${section}-${offer}-offerSummer`]
                                ? "flex"
                                : "none",
                              transition: "opacity 0.3s",
                            }}
                          >
                            <Tooltip title="Copy" disableInteractive arrow>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    commentary[section][offer].offerSummary.commentary
                                  );
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Regenerate" disableInteractive arrow>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  toggleOfferSummary(section, offer);
                                }}
                              >
                                <ChangeCircleIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                      <Collapse
                        in={commentary[section][offer].offerSummary?.showBox}
                      >
                        <Box sx={{ alignItems: "center", marginTop: "10px" }}>
                          <TextField
                            label="Field Prompt"
                            fullWidth
                            multiline
                            maxRows={4}
                            value={promptsState[section][offer].offerSummary?.prompt}
                            onChange={(e) =>
                              handleOfferPromptChange(section, offer, e.target.value)
                            }
                            key={`${section}-${offer}`}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                generateOfferCommentary(section, offer)
                              }
                              sx={{ marginTop: "10px" }}
                            >
                              Generate Commentary
                            </Button>
                            <Typography variant="h6">
                              Attempt Left:{" "}
                              {
                                commentary[section][offer].offerSummary
                                  ?.generateCount
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                    {fields.map((field) => (
                      <Box key={`${section}-${field}`} marginTop={3}>
                        <Typography variant="subtitle2">
                          {fieldsHeading[field].split(/(?=[A-Z])/).join(" ")}
                        </Typography>

                        {Object.keys(
                          commentary[section][offer][field].tables
                        ).map((table, tableIndex) => {
                          return (
                            <Box
                              key={`${section}-${offer}-${field}-table-${tableIndex}`}
                              marginTop={3}
                              onMouseEnter={() =>
                                handleMouseEnter(
                                  `${section}-${offer}-${field}-${table}`
                                )
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(
                                  `${section}-${offer}-${field}-${table}`
                                )
                              }
                            >
                              {commentary[section][offer][field].tables[table]
                                ?.widgetName &&
                                renderTable(
                                  field,
                                  section,
                                  offer,
                                  table,
                                  otherData[offer]
                                )}
                              {commentary[section][offer][field].tables[table]
                                ?.commentary && (
                                <Box
                                  sx={{
                                    ...commentaryBoxStyles,
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography>
                                    {commentary[section][offer][field].tables[
                                      table
                                    ].isLoading ? (
                                      <Box mt={4} textAlign="center">
                                        <CircularProgress />
                                      </Box>
                                    ) : (
                                      convertToHTML(
                                        commentary[section][offer][field]
                                          .tables[table]?.commentary
                                      )
                                    )}{" "}
                                  </Typography>
                                  <Box
                                    sx={{
                                      alignItems: "flex-start",
                                      display: hoveredBoxes[
                                        `${section}-${offer}-${field}-${table}`
                                      ]
                                        ? "flex"
                                        : "none",
                                      transition: "opacity 0.3s",
                                    }}
                                  >
                                    <Tooltip
                                      title="Copy"
                                      disableInteractive
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            commentary[section][offer][field]
                                              .tables[table]?.commentary
                                          );
                                        }}
                                      >
                                        <ContentCopyIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Regenerate"
                                      disableInteractive
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          toggleShowBox(
                                            section,
                                            offer,
                                            field,
                                            table
                                          )
                                        }
                                      >
                                        <ChangeCircleIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              )}

                              <Collapse
                                in={
                                  commentary[section][offer][field].tables[
                                    table
                                  ]?.showBox
                                }
                              >
                                <Box sx={{ marginTop: "10px" }}>
                                  <TextField
                                    label="Table Prompt"
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    value={
                                      promptsState[section][offer][field]
                                        ?.tables[table].prompt
                                    }
                                    onChange={(e) =>
                                      handlePromptChange(
                                        section,
                                        offer,
                                        field,
                                        table,
                                        e.target.value
                                      )
                                    }
                                    key={`${section}-${offer}-${field}-table-${tableIndex}-prompt`}
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        generateCommentary(
                                          section,
                                          offer,
                                          field,
                                          table
                                        )
                                      }
                                      sx={{ marginTop: "10px" }}
                                    >
                                      Generate Commentary
                                    </Button>
                                    <Typography variant="h6">
                                      Attempt Left:{" "}
                                      {
                                        commentary[section][offer][field]
                                          .tables[table]?.generateCount
                                      }
                                    </Typography>
                                  </Box>
                                </Box>
                              </Collapse>
                            </Box>
                          );
                        })}
                      </Box>
                    ))}
                  </Box>
                );
              })}
        </Box>
      );
    },
    [
      commentary,
      hoveredBoxes,
      promptsState,
      generateReport,
      handleMouseEnter,
      handleMouseLeave,
      toggleShowBox,
      handlePromptChange,
      generateCommentary,
      renderTable,
      generateOfferCommentary,
      handleOfferPromptChange,
      toggleOfferSummary,
      handleSectionPromptChange,
      toggleSectionSummary,
      generateSectionCommentary,
    ]
  );

  if (smartLoading || lowLoading || premiumLoading || budgetLoading ) {
    return (
      <Box mt={4} textAlign="center">
        <Typography variant="h5">Loading data, please wait...</Typography>
      </Box>
    );
  }
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div ref={contentRef} id="htmlContent">
        <HeaderNaming
          data={{
            "End-user Organisation" :
              userProfile?.profileType === "endUser"
                ? userProfile?.companyName
                : currentOrganization,
            "Buyer Guide Name": buyerGuideName,
            "Scenario Name": scenarioName,
          }}
        />
        <Box sx={{ width: "100%", minWidth: "350px" }}>
          <Box sx={{ marginBottom: { xs: 18, md: 15 } }}>
            <Typography variant="h3" sx={{ textAlign: "center", marginBottom: 5 }}>
              Buyer Guide Report
            </Typography>
            <Box sx={{ width: "100%", alignItems: "center" }}>
              <Box sx={{ flexDirection: "column", width: "70%", float: "left" }}>
                <Typography>Company Name: {companyName}</Typography>
                <Typography>Date: {date}</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  float: "right",
                  width: { xs: "200px", md: "250px" },
                  fontSize: { xs: "14px", md: "16px" },
                }}
                onClick={() => {
                  if (!reportdata?.urlPreview) {
                    setOpen(true);
                  } else {
                    navigate("/saved-reports");
                  }
                }}
              >
                {reportdata?.urlPreview ? "Veiw Saved Report" : "Finalize and Save Report"}
              </Button>
            </Box>
          </Box>
          <Box>
            {sections.map((section) => (
              <Box
                key={section}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h1">{section.split(/(?=[A-Z])/).join(" ")}</Typography>
                  <IconButton
                    size="large"
                    sx={{ cursor: "pointer" }}
                    onClick={() => toggleAccordion(section)}
                  >
                    {accordionState[section] ? <ExpandMore /> : <ChevronRight />}
                  </IconButton>
                </Box>
                <Collapse in={accordionState[section]}>
                  <Box>
                    {section === "ExecutiveSummary" ? (
                      <>
                        <Suspense fallback={<div>Loading chart...</div>}>
                          <Box id="blob" sx={{ flex: 1 }}>
                            {primaryMetric === "offerScore" ? (
                              <ChartComponent
                                chartType="Bubble Chart"
                                initialData={initialDataForStatic}
                                gridLines={valyouMatrixData?.Gridlines}
                                flag={false}
                              />
                            ) : (
                              <>
                                <Typography variant="subtitle1">
                                  The Valyou Matrix (Scatter Chart)
                                </Typography>
                                <ChartComponent
                                  chartType="Scatter Chart"
                                  initialData={initialDataForStatic}
                                  flag={false}
                                />
                              </>
                            )}
                          </Box>
                        </Suspense>
                        <Box sx={{ display: "flex", alignItems: "self-start" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ cursor: "pointer" }}>
                              <Box
                                sx={{
                                  ...commentaryBoxStyles,
                                  marginTop: "10px",
                                }}
                                onMouseEnter={() => handleMouseEnter(`ExecutiveSummary`)}
                                onMouseLeave={() => handleMouseLeave(`ExecutiveSummary`)}
                              >
                                <ExecutiveSummary
                                  commentary={commentary}
                                  executiveSummarydetail={executiveSummarydetail}
                                  setExecutiveSummary={setExecutiveSummary}
                                  generateReport={generateReport}
                                />
                                <Box
                                  sx={{
                                    alignItems: "flex-start",
                                    display: hoveredBoxes[`ExecutiveSummary`] ? "flex" : "none",
                                    transition: "opacity 0.3s",
                                  }}
                                >
                                  <Tooltip title="Copy" disableInteractive arrow>
                                    <IconButton size="small">
                                      <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Regenerate" disableInteractive arrow>
                                    <IconButton
                                      size="small"
                                      onClick={() => setPromptVisible(!promptVisible)}
                                    >
                                      <ChangeCircleIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={promptVisible}
                              style={{
                                transitionDelay: promptVisible ? "200ms" : "0ms",
                              }}
                            >
                              <Box sx={{ width: "100%", marginTop: "20px" }}>
                                <TextField
                                  label="Prompt"
                                  fullWidth
                                  multiline
                                  maxRows={4}
                                  value={promptsState?.ExecutiveSummary?.prompt}
                                  onChange={(e) =>
                                    setExecutiveSummary((prev)=>{
                                    return {
                                        ...prev,
                                        prompt: e?.target?.value
                                             
                                    }
                                    })
                                  }
                                  key={`ExecutiveSummary-prompt`}
                                />
                                 <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => generateExecutiveCommentary()}
                                  sx={{ width: "250px", marginTop: "10px" }}
                                >
                                  Generate Commentary
                                </Button>
                                <Typography variant="h6">
                                      Attempt Left:{" "}
                                      {
                                        
                                          executiveSummarydetail?.generateCount
                                      }
                                    </Typography>
                                </Box>
                              </Box>
                            </Collapse>
                          </Box>
                        </Box>
                      </>
                    ) : section !== "ComparativeSummary" ? (
                      renderOfferDetails(
                        section,
                        section === "SmartValyou"
                          ? smartDataForStaticData?.items
                          : section === "PremiumValyou"
                          ? premiumDataForStaticData?.items
                          : section === "BudgetValyou"
                          ? budgetDataForStaticData?.items
                          : section === "LowValyou"
                          ? lowDataForStaticData?.items
                          : [],
                        section === "SmartValyou"
                          ? smartLoading
                          : section === "PremiumValyou"
                          ? premiumLoading
                          : section === "BudgetValyou"
                          ? budgetLoading
                          : section === "LowValyou"
                          ? lowLoading
                          : false,
                        section === "SmartValyou"
                          ? smartDataForStaticData?.otherData
                          : section === "PremiumValyou"
                          ? premiumDataForStaticData?.otherData
                          : section === "BudgetValyou"
                          ? budgetDataForStaticData?.otherData
                          : section === "LowValyou"
                          ? lowDataForStaticData?.otherData
                          : {}
                      )
                    ) : (
                      renderComparativeSummary()
                    )}
                  </Box>
                </Collapse>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ backgroundColor: "white" }}>Enter-ReportName</DialogTitle>
        <DialogContent sx={{ color: "white", backgroundColor: "white" }}>
          <TextField
            data-test-id="Report-Name"
            margin="dense"
            label="Report Name"
            type="search"
            fullWidth
            value={reportName}
            onChange={onChangeReportName}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "white" }}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={
              isReportLoading
                ? null
                : () => {
                    handleExport();
                  }
            }
            color="primary"
          >
            {isReportLoading ? <CircularProgress size={24} /> : "Add-Report"}
          </Button>
        </DialogActions>
      </Dialog>
      {!(localStorageUserGptKey || sessionStorageUserGptKey) && (
        <Dialog open={openCommentryBox} onClose={() => setCommentryBox(false)}>
          <DialogTitle
            variant="h3"
            sx={{ textAlign: "center", backgroundColor: "white" }}
          >
            Limit Exhuasted
          </DialogTitle>

          <DialogContent sx={{ color: "black", backgroundColor: "white" }}>
            <Typography variant="h6">
              {" "}
              You have tried all attempt generate summary using you chat gpt api
              key.{" "}
            </Typography>
            <TextField
              data-test-id="Report-Name"
              margin="dense"
              label="Enter the Chat GPT key"
              type="search"
              fullWidth
              value={chatGptKey}
              onChange={onChangeChatGptkey}
            />

            {/* Disclaimer Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={disclaimerChecked}
                  onChange={(e) => setDisclaimerChecked(e.target.checked)}
                  color="primary"
                />
              }
              label="I acknowledge that I am submitting my ChatGPT API key, which will be used for generating the summary."
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveKey}
                  onChange={(e) => setsaveKey(e.target.checked)}
                  color="primary"
                />
              }
              label="save API key"
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setCommentryBox(false);
              }}
              color="secondary"
              sx={{
                backgroundColor: "#40BAB4",
                color: "white",
                transition: "none",
                "&:hover": {
                  backgroundColor: "#40BAB4", // Ensure the background color remains the same on hover
                  transition: "none",
                },
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={handleAddKey}
              color="primary"
              sx={{
                backgroundColor: "#4F40BA",
                color: "white",
                transition: "none",
                "&:hover": {
                  backgroundColor: "#4F40BA", // Ensure the background color remains the same on hover
                  transition: "none",
                },
              }}
            >
              ADD-KEY
            </Button>
          </DialogActions>

          {/* Snackbar for Warning */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              No ChatGPT API key added! Please enter a key and accept the
              disclaimer.
            </Alert>
          </Snackbar>
        </Dialog>
      )}
    </ThemeProvider>
  );
};

const commentaryBoxStyles = {
  borderRadius: "5px",
  padding: "10px",
  backgroundColor: "transparent",
  transition: "border-color 0.3s, background-color 0.3s", // Add transition for smooth effect
  "&:hover": {
    border: "1px solid #40bab4 inset",
    borderColor: "#40bab4", // Change border color on hover
    backgroundColor: "rgba(64, 186, 180, 0.2)", // Semi-transparent background color
  },
};

export default memo(ViewReport);
