import { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, Tooltip } from "@mui/material";
import theme from "../../theme/theme";
import { AllocationContext } from "./AllocationContext";

export default function DropDown({ params, isDisabled, setSectionData, inputsLabel, setPopover, setPopoverContent, setAllocatedData }) {
  const [optionList, setOptionList] = useState([]);
  const [maxLimit, setMaxLimit] = useState(null);
  const [placeholder, setPlaceHolder] = useState("#");
  const [value, setValue] = useState(null);
  const { setCurrentBuyerGuidePackage } = useContext(AllocationContext);

  useEffect(() => {
    if (params?.row?.allocationType === "UnPrescribed") {
      if (params?.field === "totalUnits") {
        setPlaceHolder("#");
      } else if (params?.field === "userUnitsTotal") {
        setPlaceHolder("#");
      } else if (params?.field === "endUserOrganizationUnitsTotal") {
        setPlaceHolder("(Optional)");
      } else if (params?.field === "buyerGuideUnitsTotal") {
        setPlaceHolder("(Optional)");
      }
    } else if (params?.row?.allocationType === "Fully Prescribed") {
      if (params?.field === "endUserOrganizationUnitsTotal") {
        setPlaceHolder("#");
      } else if (params?.field === "buyerGuideUnitsTotal") {
        setPlaceHolder("#");
      } else if (params?.field === "totalUnits") {
        setPlaceHolder("#");
      } else if (params?.field === "userUnitsTotal") {
        setPlaceHolder("#");
      }
    } else if (params?.row?.allocationType === "Partially Prescribed (UCBG)") {
      if (params?.field === "buyerGuideUnitsTotal") {
        setPlaceHolder("#");
      } else if (params?.field === "totalUnits") {
        setPlaceHolder("#");
      } else if (params?.field === "userUnitsTotal") {
        setPlaceHolder("#");
      } else if (params?.field === "endUserOrganizationUnitsTotal") {
        setPlaceHolder("(Optional)");
      }
    } else if (params?.row?.allocationType === "Partially Prescribed (EUO)") {
      if (params?.field === "endUserOrganizationUnitsTotal") {
        setPlaceHolder("#");
      } else if (params?.field === "totalUnits") {
        setPlaceHolder("#");
      } else if (params?.field === "userUnitsTotal") {
        setPlaceHolder("#");
      } else if (params?.field === "buyerGuideUnitsTotal") {
        setPlaceHolder("(Optional)");
      }
    }
  }, [params, placeholder]);

  useEffect(() => {
    if (params?.row?.allocationType === "UnPrescribed") {
      if (params?.field === "totalUnits") {
        const options = Array.from({ length: params?.row?.totalUnits }, (_, index) => index + 1);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "userUnitsTotal" && !!params?.row?.totalUnitsInput) {
        const options = Array.from({ length: params?.row?.totalUnitsInput }, (_, index) => index + 1).filter((number) => params?.row?.totalUnitsInput % number === 0);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "endUserOrganizationUnitsTotal" && !!params?.row?.userUnitsInput) {
        const options = Array.from({ length: params?.row?.totalUnitsInput }, (_, index) => index + 1)?.filter((number) => (params?.row?.totalUnitsInput / params?.row?.userUnitsInput) % number === 0);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "buyerGuideUnitsTotal" && !!params?.row?.userUnitsInput) {
        if (params?.row?.totalUnitsInput && params?.row?.userUnitsInput) {
          const isEndUserOrgUnitsValid = !!params?.row?.endUserOrganizationUnitsInput;

          const options = Array.from({ length: params?.row?.totalUnitsInput }, (_, index) => index + 1)?.filter((number) => {
            const denominator = isEndUserOrgUnitsValid ? params?.row?.userUnitsInput * params?.row?.endUserOrganizationUnitsInput : params?.row?.userUnitsInput;

            return (params?.row?.totalUnitsInput / denominator) % number === 0;
          });

          setMaxLimit(Math.max(...options));
          setOptionList(options);
        }
      }
    } else if (params?.row?.allocationType === "Fully Prescribed") {
      if (params?.field === "endUserOrganizationUnitsTotal") {
        const options = Array.from({ length: params?.row?.endUserOrganizationUnitsTotal }, (_, index) => index + 1);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "buyerGuideUnitsTotal") {
        const options = Array.from({ length: params?.row?.buyerGuideUnitsTotal }, (_, index) => index + 1);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "totalUnits" && !!params?.row?.endUserOrganizationUnitsInput && !!params?.row?.buyerGuideUnitsInput) {
        const product = params?.row?.endUserOrganizationUnitsInput * params?.row?.buyerGuideUnitsInput;
        const count = Math.floor(params?.row?.totalUnits / product);
        const options = Array.from({ length: count }, (_, i) => product * (i + 1));
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "userUnitsTotal" && !!params?.row?.totalUnitsInput && !!params?.row?.buyerGuideUnitsInput && !!params?.row?.endUserOrganizationUnitsInput) {
        const product = params?.row?.endUserOrganizationUnitsInput * params?.row?.buyerGuideUnitsInput;
        const quotient = params?.row?.totalUnitsInput / product;
        const options = Array.from({ length: quotient }, (_, i) => i + 1).filter((i) => quotient % i === 0);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      }
    } else if (params?.row?.allocationType === "Partially Prescribed (UCBG)") {
      if (params?.field === "buyerGuideUnitsTotal") {
        const options = Array.from({ length: params?.row?.buyerGuideUnitsTotal }, (_, index) => index + 1);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "totalUnits" && !!params?.row?.buyerGuideUnitsInput) {
        const options = Array.from({ length: Math.floor(params?.row?.totalUnits / params?.row?.buyerGuideUnitsInput) }, (_, index) => (index + 1) * params?.row?.buyerGuideUnitsInput);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "userUnitsTotal" && !!params?.row?.totalUnitsInput && !!params?.row?.buyerGuideUnitsInput) {
        const quotient = params?.row?.totalUnitsInput / params?.row?.buyerGuideUnitsInput;
        const options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
          .filter((i) => quotient % i === 0)
          .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]))
          .sort((a, b) => a - b);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "endUserOrganizationUnitsTotal" && !!params?.row?.userUnitsInput && !!params?.row?.buyerGuideUnitsInput) {
        const quotient = params?.row?.totalUnitsInput / (params?.row?.buyerGuideUnitsInput * params?.row?.userUnitsInput);
        const options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
          .filter((i) => quotient % i === 0)
          .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]));
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      }
    } else if (params?.row?.allocationType === "Partially Prescribed (EUO)") {
      if (params?.field === "endUserOrganizationUnitsTotal") {
        const options = Array.from({ length: params?.row?.endUserOrganizationUnitsTotal }, (_, index) => index + 1);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "totalUnits" && !!params?.row?.endUserOrganizationUnitsInput) {
        const options = Array.from(
          { length: Math.floor(params?.row?.totalUnits / params?.row?.endUserOrganizationUnitsInput) },
          (_, index) => (index + 1) * params?.row?.endUserOrganizationUnitsInput
        );
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "userUnitsTotal" && !!params?.row?.totalUnitsInput && !!params?.row?.endUserOrganizationUnitsInput) {
        const quotient = params?.row?.totalUnitsInput / params?.row?.endUserOrganizationUnitsInput;

        const options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
          .filter((i) => quotient % i === 0)
          .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]))
          .sort((a, b) => a - b);
        setMaxLimit(Math.max(...options));
        setOptionList(options);
      } else if (params?.field === "buyerGuideUnitsTotal" && !!params?.row?.userUnitsInput && !!params?.row?.endUserOrganizationUnitsInput) {
        const quotient = params?.row?.totalUnitsInput / (params?.row?.endUserOrganizationUnitsInput * params?.row?.userUnitsInput);
        const options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
          .filter((i) => quotient % i === 0)
          .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]));
        options.length > 0 ? setMaxLimit(Math.max(...options)) : setMaxLimit([]);
        setOptionList(options);
      }
    }
  }, [params]);

  return (
    <Tooltip
      title={isDisabled ? "Please Enter Highlighted Field First" : ""}
      slotProps={{
        tooltip: {
          sx: { background: theme.palette.secondary.main },
        },
      }}
    >
      <Autocomplete
        disablePortal
        disabled={isDisabled}
        options={optionList}
        getOptionLabel={(option) => option.toString()}
        sx={{
          flex: 1,
          "& .MuiInputLabel-root": {
            color: isDisabled ? "gray" : "black",
          },
        }}
        value={value}
        slotProps={{
          clearIndicator: {
            onClick: () => {
              setValue(null);
              setAllocatedData((prevData) => {
                const updatedData = [...prevData];
                const index = updatedData.findIndex((item) => item.id === params?.row?.id);

                if (index !== -1) {
                  // If the object with the given id exists, update it
                  updatedData[index] = {
                    ...updatedData[index],
                    [fieldMapForAllocatedData[inputsLabel]]: inputsLabel === "totalUnitsInput" ? null : [],
                  };
                }

                return updatedData; // Return the updated array
              });

              setSectionData((prevData) => prevData.map((packag) => (packag.id === params?.row?.id ? { ...packag, [inputsLabel]: null } : packag)));
            },
          },
        }}
        ListboxProps={{
          sx: {
            maxHeight: "15vh", // Sets the maximum height for the options list
          },
        }}
        onChange={(e, newValue) => {
          setValue(newValue);
          if (params?.field === "totalUnits" && !!newValue) {
            setAllocatedData((prevData) => {
              const updatedData = [...prevData]; // Create a copy of the array
              const index = updatedData.findIndex((item) => item.id === params?.row?.id);
              const purchasedPackageName = params?.row?.purchasedPackageName;
              const allocationType = params?.row?.allocationType;

              if (index !== -1) {
                // If the entry exists, update it
                updatedData[index] = {
                  ...updatedData[index],
                  totalUnits: newValue,
                  purchasedPackages: purchasedPackageName,
                  allocationType: allocationType,
                };
              } else {
                // If the entry does not exist, add a new entry
                updatedData.push({ id: params?.row?.id, totalUnits: newValue, purchasedPackages: purchasedPackageName, allocationType: allocationType });
              }
              return updatedData;
            });
          }
          setSectionData((prevData) => prevData.map((packag) => (packag.id === params?.row?.id ? { ...packag, [inputsLabel]: newValue } : packag)));
        }}
        renderInput={(params) => {
          return <TextField {...params} label={!!maxLimit ? `Max value upto ${maxLimit}` : ""} placeholder={placeholder} />;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;

          return (
            <li key={key} {...optionProps} style={{ padding: "0px" }}>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  font: theme.typography.fontFamily,
                  color: "black",
                }}
                disableRipple
                onClick={() => {
                  if (params?.field !== "totalUnits" && !!option) {
                    setPopoverContent({ ...params, option });
                    setPopover(true);
                    if (params?.field === "buyerGuideUnitsTotal") {
                      setCurrentBuyerGuidePackage(params?.row?.purchasedPackageName);
                    }
                  }
                }}
              >
                {option.toString()}
              </Button>
            </li>
          );
        }}
      />
    </Tooltip>
  );
}

const fieldMapForAllocatedData = {
  buyerGuideUnitsInput: "bgList",
  endUserOrganizationUnitsInput: "euoList",
  totalUnitsInput: "totalUnits",
  userUnitsInput: "userList",
};

/**
 * if (!!params?.row?.endUserOrganizationUnitsInput) {
          const options = Array.from({ length: params?.row?.totalUnitsInput }, (_, index) => index + 1)?.filter(
            (number) => (params?.row?.totalUnitsInput / (params?.row?.userUnitsInput * params?.row?.endUserOrganizationUnitsInput)) % number === 0
          );
          setMaxLimit(Math.max(...options));
          setOptionList(options);
        } else if (params?.row?.endUserOrganizationUnitsInput === null || params?.row?.endUserOrganizationUnits === 0) {
          console.log("not there");
          const options = Array.from({ length: params?.row?.totalUnitsInput }, (_, index) => index + 1)?.filter(
            (number) => (params?.row?.totalUnitsInput / params?.row?.userUnitsInput) % number === 0
          );
          setMaxLimit(Math.max(...options));
          setOptionList(options);
        }
 */
