import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { UserProfileContext } from "../../../context/UserContext";
import { apiService } from "../../../utils/utils";
import CustomButton from "../../../components/CustomButton";

const UserUnits = ({ id, setAllocatedData, maxFields, handleClose, allocatedData, setResponse, setPopup,selectedOption }) => {
  const [maxCombinationUnits] = useState(maxFields);
  const [formData, setFormData] = useState([]); // Default combinationUnits as a number
  const theme = useTheme();
  const { setNotification } = useContext(UserProfileContext);
  const [buttonLoading, setButtonLoading] = useState(false);

  // const handleButtonClick = (option) => {
  //   setSelectedOption(option);
  //   setPopup(false);
  // };
  console.log(formData);
  // Email validation function
  const validateEmail = (email) => {
    const characters = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return characters.test(String(email).toLowerCase());
  };

  // Handle changes for a specific form field
  const handleChange = (index, field, value) => {
    setFormData((prevFormData) =>
      prevFormData.map((item, i) =>
        i === index
          ? {
              ...item,
              [field]:
                field === "combinationUnits" && (isNaN(value) || value < 1)
                  ? 1
                  : field === "combinationUnits"
                  ? Number(value) > maxCombinationUnits
                    ? maxCombinationUnits - totalQuantity()
                    : Number(value)
                  : value,
            }
          : item
      )
    );
  };

  useEffect(() => {
    const dataItem = allocatedData.find((data) => data.id === id);
    if (dataItem && dataItem.userList) {
      setFormData(dataItem.userList);
    } else {
      setFormData([{ emailId: "", combinationUnits: 0 }]);
    }
  }, [allocatedData, id]);

  // Remove a form row
  const handleRemove = (index) => {
    setFormData((prevFormData) => prevFormData.filter((_, i) => i !== index));
  };

  // Add a new form row
  // totalQuantity() >= maxFields || formData.length >= maxFields
  const handleAdd = async () => {
    if (!validateEmail(formData[formData.length - 1].emailId)) {
      setNotification({
        open: true,
        message: "Please enter a valid email",
        severity: "warning",
      });
    } else {
      setButtonLoading(true);
      const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/checkUserAvailabilty", {
        method: "POST",
        data: { checkEmail: formData[formData.length - 1].emailId },
      });
      setResponse(response);
      if (!response?.userExists) {
        setPopup(true);
        if (selectedOption) {
          if (totalQuantity() < maxFields && formData.length < maxFields) {
            setFormData([...formData, { emailId: "", combinationUnits: 0 }]); // Default value for new rows
          } else {
            setNotification({
              open: true,
              message: `The total quantity must be less than ${maxFields} to add more fields.`,
              severity: "error",
            });
          }
        } else {
          if (formData.length === 1) {
            setFormData((prevFormData) => {
              const updatedFormData = [...prevFormData];

              // Update the last object's properties
              updatedFormData[updatedFormData.length - 1] = {
                ...updatedFormData[updatedFormData.length - 1],
                emailId: "", // Set to empty string or desired value
                combinationUnits: 0, // Set to 0 or desired value
              };

              return updatedFormData;
            });
          } else {
            handleRemove(formData.length - 1);
          }
        }
      } else {
        if (totalQuantity() < maxFields && formData.length < maxFields) {
          setFormData([...formData, { emailId: "", combinationUnits: 0 }]); // Default value for new rows
        }
        // else {
        //   setNotification({
        //     open: true,
        //     message: `The total quantity must be less than ${maxFields} to add more fields.`,
        //     severity: "error",
        //   });
        // }
      }
      setButtonLoading(false);
    }
  };
  const getLastEmailId = () => {
    if (formData.length === 0) {
      return null; // Return null or a default value if the array is empty
    }
    return formData[formData.length - 1].emailId; // Access the emailId of the last object
  };

  // Calculate the total quantity
  const totalQuantity = () => {
    return formData.reduce((total, item) => total + item.combinationUnits, 0); // No need to convert, it's already a number
  };

  // Submit handler with validation
  const handleSubmit = () => {
    let isValid = true;

    formData.forEach((item) => {
      if (!validateEmail(item.emailId) || !item.combinationUnits || item.combinationUnits < 1) {
        isValid = false;
      }
    });

    if (isValid) {
      setAllocatedData((prevData) => {
        const updatedData = [...prevData];
        const index = updatedData.findIndex((item) => item.id === id);

        if (index !== -1) {
          updatedData[index] = { ...updatedData[index], userList: formData };
        } else {
          updatedData.push({ id, userList: formData });
        }

        return updatedData;
      });
      handleClose();
    } else {
      setNotification({
        open: true,
        message: "Please ensure all email addresses are valid and each quantity is at least 1.",
        severity: "error",
      });
    }
  };

  // Render a single form row
  const renderFormRow = (index) => (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        width: "100%",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          [theme.breakpoints.down("md")]: { flexDirection: "column" },
          width: "100%",
        }}
      >
        <TextField
          fullWidth
          label={`Email - ${index + 1}`}
          value={formData[index].emailId}
          onChange={(e) => handleChange(index, "emailId", e.target.value)}
          variant="outlined"
          sx={{ flex: 1 }}
          type="email"
          size="medium"
          error={!validateEmail(formData[index].emailId) && formData[index].emailId !== ""}
          slotProps={{ inputLabel: { color: "black" } }}
          helperText={!validateEmail(formData[index].emailId) && formData[index].emailId !== "" ? "Enter a valid email" : ""}
        />
        <TextField
          fullWidth
          label="Quantity"
          sx={{ [theme.breakpoints.up("md")]: { width: "20%" } }}
          value={formData[index].combinationUnits}
          onChange={(e) => handleChange(index, "combinationUnits", e.target.value)} // Value is stored as number
          variant="outlined"
          type="number"
          size="medium"
          placeholder="1"
          slotProps={{ htmlInput: { min: 1, max: maxCombinationUnits }, inputLabel: { color: "black" } }}
        />
      </Box>
      <IconButton onClick={() => handleRemove(index)} aria-label="delete" disabled={formData.length <= 1}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        padding: 2,
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        boxShadow: 2,
        margin: "auto",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
          [theme.breakpoints.down("md")]: { flexDirection: "column", alignItems: "flex-start" },
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", [theme.breakpoints.down("md")]: { width: "100%" } }}>
          Please Provide Email and Quantity
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ textAlign: "left", whiteSpace: "nowrap", color: maxCombinationUnits - totalQuantity() < 0 ? "red" : "black" }}>
          <Typography component="span" variant="inherit" style={{ fontWeight: "bold", marginRight: "5px", color: "inherit" }}>
            Remaining Units :
          </Typography>
          {maxCombinationUnits - totalQuantity()}
        </Typography>
      </Box>
      {formData.map((_, index) => renderFormRow(index))}
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.down("md")]: { flexDirection: "column" },
          gap: "10px",
        }}
      >
        <CustomButton
          variant="contained"
          onClick={handleAdd}
          sx={{ flex: 1, backgroundColor: theme.palette.primary.main }}
          disabled={getLastEmailId() === "" || buttonLoading}
          text={buttonLoading ? "Checking Email..." : "Add"}
        />

        <CustomButton variant="contained" onClick={handleSubmit} sx={{ flex: 1, backgroundColor: theme.palette.primary.main }} disabled={totalQuantity() !== maxFields} text={"Submit"} />
      </Box>

      {/* --------------------------------------------------popup below------------------------------------------ */}
    </Box>
  );
};

export default UserUnits;
