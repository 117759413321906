import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Radio,
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../context/UserContext";
import { apiService } from "../../utils/utils";
import DataGridTable from "../DataGridTable";
import NoDataAvilable from "../NoDataAvilable";
import "../../pages/GridLayout/GridComponent.css";
import Loader from "../Loader";

const Consultant = ({ theme, setOpen, updateUi, setUpdateUi }) => {
  const navigate = useNavigate();
  const {
    userProfile,
    orgList,
    setOrgList,
    setCurrentOrganization,
    interestedBuyerGuides,
    setCurrentOrganizationId,
    setIsConsultant,
    interestedBuyerGuidesLoading,
  } = useContext(UserProfileContext);

  const [isLoading, setIsLoading] = useState(true);
  const [, setIsDeleting] = useState(false);
  const [, setMessage] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleDelete = async (removeOrganisation) => {
    try {
      // Using apiService that returns a Promise
      await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/deleteEndUserOrg",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            removeOrganisation,
          },
        },
        setIsDeleting,
        setMessage
      );
      // If successful, update UI
      setUpdateUi(!updateUi);
    } catch (error) {
      // Handle error properly here
      console.error("Failed to delete organisation:", error);
    }
  };

  const handleRadioChange = (index) => {
    setSelectedOrg(index);
  };

  useEffect(() => {
    const fetchOrgList = async () => {
      try {
          await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getEndUserOrganisationList?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
            },
          },
          setIsLoading,
          setOrgList,
          
        );
      } catch {
        // Handle any errors that occurred during the fetch
        // console.error("Error fetching organisation list:", error);
      }
    };

    // Call the fetch function
    fetchOrgList();
  }, [userProfile, updateUi, setOrgList]);

  useEffect(() => {
    if (Array.isArray(orgList) && orgList.length > 0) {
      setIsLoading(false);
    }
  }, [orgList]);

  useMemo(() => {
    if (orgList && orgList?.length > 0) {
      const columnNames = [
        "Select",
        "Organization Name",
        "Industry",
        "Allocated By",
        "Actions",
      ].map((header, index) => ({
        field: `col${index}`,
        headerName: header,
        flex: index !== 0 ? 1 : "initial",
        minWidth: index === 0 ? 120 : 220,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box
            sx={{
              overflowY: "hidden", // Apply overflowY: hidden to each column
              display: "flex",
              justifyContent: index !== 5 ? "center" : "flex-start", //to target each column of table
              alignItems: "center",
              width: "100%",
              height: "100%", // Adjust height as needed
            }}
          >
            {params.value}
          </Box>
        ),
      }));
      setColumns(columnNames);
    }

    const rowData = orgList?.map((row, index) => {
      const perRow = {
        id: index, // Add unique ID for the row
        // Insert radio button as the first element of the row
        col0: (
          <Radio
            checked={selectedOrg === index}
            onChange={() => {
              handleRadioChange(index);
              setCurrentOrganization(row?.orgname); // Assuming `row` contains the org data
              localStorage.setItem("currentOrganization", row?.orgname);
              setCurrentOrganizationId(row?.id);
              localStorage.setItem("currentOrganizationId", row?.id);
              setIsConsultant(true);
            }}
          />
        ),
      };

      // Track the current column index, starting after the radio button (col0)
      let colIndex = 1;

      // Loop through the row's keys and add data as columns, excluding 'id'
      Object.keys(row).forEach((key) => {
        if (key !== "id") {
          perRow[`col${colIndex}`] = row[key]; // Assign dynamic column names like col1, col2, etc.
          colIndex++; // Increment the column index for each new entry
        }
      });

      // Add the delete button at the end of each row
      perRow[`col${colIndex}`] = (
        <IconButton data-testid="delete-icon" edge="end" aria-label="delete" onClick={() => handleDelete(row?.id)}>
          <DeleteIcon  data-test-id="delete-icon" />
        </IconButton>
      );

      return perRow; // Return the structured row
    });
    setRows(rowData);
    // eslint-disable-next-line
  }, [orgList, selectedOrg]);

  return isLoading ? (
    // <Box
    //   height="250px"
    //   width="100%"
    //   justifyContent="center"
    //   alignItems="center"
    //   overflow="hidden"
    //   display="flex"
    // >
    //   <CircularProgress />
    // </Box>
    <Loader open={isLoading}/>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "flex-start",
        alignItems: "start",
        height: "100%",
        width: "100%",
        gap: "15px",
      }}
    >
      {orgList?.length > 0 ? (
        <>
          <Box
            className="thinner-scrollbar"
            sx={{
              outline: "0.1px solid gray",
              width: "100%",
              borderRadius: "20px",
              overflow: "hidden",
            }}
          >
            <DataGridTable
              data-test-id="table-head"
              name="selectEndUserOrg"
              rows={rows}
              columns={columns}
            />
          </Box>

          <Button
            sx={{ width: "250px" }}
            color="primary"
            variant="contained"
            disableRipple
            disabled={
              interestedBuyerGuidesLoading ||
              (selectedOrg === null && !interestedBuyerGuidesLoading)
            }
            onClick={() => {
              console.log(!interestedBuyerGuidesLoading);
              if (interestedBuyerGuides && interestedBuyerGuides.length > 0) {
                navigate("/select-buyer-guide/buyer-guide-of-interest");
              } else {
                navigate("/select-buyer-guide/available-buyer-guides");
              }
            }}
          >
            <Typography color="white">
              {interestedBuyerGuidesLoading && selectedOrg !== null ? (
                <CircularProgress data-testid="loading" />
              ) : (
                " Save & Continue"
              )}
            </Typography>
          </Button>
        </>
      ) : (
        <Box sx={{ width: "100%" }}>
          <NoDataAvilable
            message={
              <>
                <Typography data-testid="No EUO" sx={{ whiteSpace: 'normal'}}>
                  No End User Orginization found. Click on{" "}
                  <Typography color={"secondary"} sx={{ whiteSpace: "normal" }}>
                    "New End-User Organization"
                  </Typography>
                  button to create one.
                </Typography>
              </>
            }
          />
        </Box>
      )}
      <Button
        sx={{ width: "250px" }}
        color="secondary"
        variant="contained"
        disableRipple
        onClick={() => setOpen(true)}
      >
        <Typography color="white"> New End-User Organization</Typography>
      </Button>
    </Box>
  );
};

export default Consultant;
