import { useState, useEffect, useContext } from "react";
import { AllocationContext } from "../AllocationContext";
import { UserProfileContext } from "../../../context/UserContext";
import { apiService } from "../../../utils/utils";

export const useAllocation = () => {
  const { setLoading, setSectionData, setPopover, allocatedData, setPreviewPopover, setPopup } = useContext(AllocationContext);
  const { userProfile, availableUnitsForAllocation, setNotification, fetchAvailableUnitsForAllocation } = useContext(UserProfileContext);

  const [response, setResponse] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    fetchAvailableUnitsForAllocation();
    // eslint-disable-next-line
  }, []);

  const handelClose = () => {
    setPopover(false);
  };

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    setPopup(false);
  };

  const handlePreviewClose = () => {
    setPreviewPopover(false);
  };

  const handelAllocationOfPrescribedUnits = async () => {
    try {
      const data = allocatedData.map((data) => {
        if (!data.userList || data.userList.length === 0 || !data.totalUnits || data.totalUnits === 0) {
          setNotification({
            open: true,
            message: `Please Prescribe Units for Total and User Units`,
            severity: "error",
          });
          return null;
        }

        return {
          id: data.id,
          purchasedPackageName: data?.purchasedPackages,
          allocationType: data?.allocationType,
          totalUnits: data?.totalUnits,
          userList: data?.userList,
          userUnits: data?.userList?.reduce((sum, item) => sum + Number(item.combinationUnits), 0),
          bgList: data?.bgList || [],
          euoList: data?.euoList?.[0]?.id ? data?.euoList?.map((item) => item.id) : data?.euoList?.map((item) => item.title) || [],
          euoFlag: data?.euoList?.length > 0 ? (data?.euoList?.[0]?.id !== null ? true : false) : null,
        };
      });

      if (data.length > 0 && !data.includes(null)) {
        const response = await apiService(`https://newtestfuncpython.azurewebsites.net/api/allocatePurchasedPackage`, {
          method: "POST",
          data: {
            email: userProfile.email,
            records: data,
          },
        });
        if (response) {
          setLoading(true);
          setNotification({
            open: true,
            message: response?.message,
            severity: "success",
          });
          await fetchAvailableUnitsForAllocation();
          setLoading(false);
        }
      } else {
        setNotification({
          open: true,
          message: "Nothing to Allocate, Please Presribe",
          severity: "error",
        });
      }
      setPopover(false);
    } catch (error) {
      console.log("Allocation Error :", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const availableUnitsForAllocationInSession = JSON.parse(sessionStorage.getItem("availableUnitsForAllocation")) || [];

    const createAvailableUnits = (units) => {
      return units?.map((packag) => ({
        id: packag.id,
        purchasedPackageName: packag.purchasedPackageName,
        allocationType: packag.allocationType,
        endUserOrganizationUnitsTotal: packag.endUserOrganizationUnits,
        buyerGuideUnitsTotal: packag.buyerGuideUnits,
        totalUnits: packag.totalUnits,
        userUnitsInput: 0,
        endUserOrganizationUnitsInput: 0,
        buyerGuideUnitsInput: 0,
        totalUnitsInput: 0,
        euoList: packag.euoList,
        buyerlist: packag.bgList,
      }));
    };

    if (availableUnitsForAllocationInSession.length > 0 && availableUnitsForAllocation.length > 0) {
      const availableUnitsForAllocationInCorrectedForm = createAvailableUnits(availableUnitsForAllocation);
      const sessionMap = new Map(availableUnitsForAllocationInSession.map((row) => [`${row.purchasedPackageName}-${row.allocationType}`, row]));
      const mergedAvailableUnitsForAllocation = availableUnitsForAllocationInCorrectedForm.map((row1) => {
        const key = `${row1.purchasedPackageName}-${row1.allocationType}`;
        return sessionMap.get(key) || row1;
      });
      setSectionData(mergedAvailableUnitsForAllocation);
    } else if (availableUnitsForAllocation.length > 0) {
      const availableUnitsForAllocationLatest = createAvailableUnits(availableUnitsForAllocation);
      setSectionData(availableUnitsForAllocationLatest);
    }
    setLoading(false);
  }, [availableUnitsForAllocation, setSectionData, setLoading]);

  return {
    allocatedData,
    response,
    selectedOption,
    userProfile,
    handelClose,
    handleButtonClick,
    handlePreviewClose,
    handelAllocationOfPrescribedUnits,
    setPopover,
    setPreviewPopover,
    setResponse,
    setPopup,
  };
};

/**
 * chips
 * disalble options that been already by admin to user
 * user unit
 * preview of vendor in preview mode
 */
