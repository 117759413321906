import React, { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import Consultant from "../components/SelectEndUserComponents/Consultant";
import NewOrgDialog from "../components/SelectEndUserComponents/NewOrgDialog";
import { UserProfileContext } from "../context/UserContext";
import { apiService } from "../utils/utils";
import Loader from "../components/Loader";

const userData = {
  Name: "username",
  profileType: "endUser", // default to 'End User'
  EndUserOrganizationName: "CompanyName",
  industryName: "indus1",
};

const userSection = {
  data: userData,
  columns: ["Name", "profileType", "End-User Organization"],
};

const SelectEndUser = ({ section }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  //const { state } = useLocation();
  const { userProfile, orgList, interestedBuyerGuides, isProfileLoading, notification, handleCloseNotification, setNotification } = useContext(UserProfileContext);
  const [, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [updateUi, setUpdateUi] = useState(false);
  const user = userProfile || {};

  const handleIndustryInputChange = (event) => {
    setIndustryName(event.target.value);
  };

  const handleOrgInputChange = (event) => {
    setOrgName(event.target.value);
  };

  const handleOrgSubmit = async () => {
    let know = false;
    orgList.forEach((value) => {
      if (value?.orgname === orgName) {
        know = true;
      }
    });

    if (!know) {
      await AddNewEndUserOrganization();
      setOrgName("");
      setIndustryName("");
      setOpen(false);
    } else {
      setNotification({
        message: "Organization name already exists or is empty.",
        severity: "warning",
        open: true,
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangeProfile = () => {
    navigate("/users");
  };
  const AddNewEndUserOrganization = async () => {
    apiService(
      `https://newtestfuncpython.azurewebsites.net/api/AddEndUserOrganisation?`,
      {
        method: "POST",
        data: {
          email: user?.email,
          new_organisation: orgName,
          new_industry: industryName,
        },
      },
      setLoading,
      setMessage,
      (err) => {
        console.log("Error in adding new EndUserOrganization", err);
      }
    ).then(() => {
      setNotification({
        open: true,
        message: "Please Provide Organization and Industry Name",
        severity: "warning",
      });
      setUpdateUi(!updateUi);
    });
  };
  const profileType = user?.profileType;
  if (profileType === "endUser") {
    if (!isProfileLoading) {
      if (interestedBuyerGuides && interestedBuyerGuides.length > 0) {
        navigate("/select-buyer-guide/buyer-guide-of-interest");
      } else {
        navigate("/select-buyer-guide/available-buyer-guides");
      }
    } else {
      return (
        // <Box mt={4} textAlign="center">
        <Loader data-testid="loading" open={isProfileLoading} />
        // </Box>
      );
    }
  }

  if (profileType !== "endUser" && !isProfileLoading) {
    return (
      <>
        <Consultant theme={theme} handleChangeProfile={handleChangeProfile} setOpen={setOpen} orgName={orgName} industryName={industryName} updateUi={updateUi} setUpdateUi={setUpdateUi} />

        <NewOrgDialog
          open={open}
          onClose={() => setOpen(false)}
          orgName={orgName}
          industryName={industryName}
          onOrgInputChange={handleOrgInputChange}
          onIndustryInputChange={handleIndustryInputChange}
          onOrgSubmit={handleOrgSubmit}
          AddNewEndUserOrganization={AddNewEndUserOrganization}
          isLoading={isLoading}
        />

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
          <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: "100%" }}>
            Organization name already exists or is empty.
          </Alert>
        </Snackbar>
        <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
          <Alert onClose={handleCloseNotification} severity={notification.severity}>
            {notification.message}
          </Alert>
        </Snackbar>
      </>
    );
  } else {
    return (
      // <Box mt={4} textAlign="center">
      //   <CircularProgress />
      // </Box>
      <Loader open={true} />
    );
  }
};

const App = () => <SelectEndUser section={userSection} />;

export default App;

//  [
//   {
//     emailID: "user1@gmail.com",
//     bgid1: [vendorid(s)],
//     bg2: [vendorid(s)],
//   },
//   {
//     emailID: "user2@gmail.com",
//     bgid: [vendorid(s)],
//     bg2: [vendorid(s)],
//   },
// ];
