import React from "react";
import { AllocationProvider } from "./AllocationContext";
import Allocation from "./Allocation";

export default function AllocationWrapper() {
  return (
    <AllocationProvider>
      <Allocation />
    </AllocationProvider>
  );
}
