import { Close } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, ListItemText, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import DataGridTable from "../../components/DataGridTable";
import theme from "../../theme/theme";

export default function PreviewAllocation({ open, handleClose, data, allocateUnits }) {
  const [row, setRow] = useState([]);

  const UserUnitsList = ({ userList = [] }) => {
    return userList.length > 0 ? (
      <List>
        {userList?.map((unit, index) => (
          <List component="span" disablePadding>
            <ListItem key={index}>
              <ListItemText primary={unit.emailId} secondary={`${unit.combinationUnits} user units`} />
            </ListItem>
          </List>
        ))}
      </List>
    ) : (
      <>
        <Typography variant="h5" sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
          N/A
        </Typography>
      </>
    );
  };

  const UnitsList = ({ unitList = [] }) => {
    return unitList.length > 0 ? (
      <List>
        {unitList?.map((unit, index) => (
          <List component="span" disablePadding>
            <ListItem key={index}>
              <ListItemText primary={unit.title} />
            </ListItem>
          </List>
        ))}
      </List>
    ) : (
      <>
        <Typography variant="h5" sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
          N/A
        </Typography>
      </>
    );
  };

  const column = [
    {
      field: "purchasedPackages",
      headerName: "Purchased Packages",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => <Typography variant="button">{params.formattedValue}</Typography>,
    },

    {
      field: "allocationType",
      headerName: "Allocation Type",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => <Typography variant="button">{params.formattedValue}</Typography>,
    },
    {
      field: "userList",
      headerName: "User Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}>
          <UserUnitsList userList={params.row.userList} />,
        </Box>
      ),
    },
    {
      field: "euoList",
      headerName: "End-User Organization Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}>
          <UnitsList unitList={params.row.euoList} />,
        </Box>
      ),
    },

    {
      field: "bgList",
      headerName: "Buyer List",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}>
          <UnitsList unitList={params.row.bgList} />
        </Box>
      ),
    },
    {
      field: "vendorList",
      headerName: "Selected Vendor",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}>
          <UnitsList unitList={params.row.bgList} />
        </Box>
      ),
    },

    {
      field: "totalUnits",
      headerName: "Total Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => <Typography variant="button">{params.formattedValue}</Typography>,
    },
  ];

  useEffect(() => {
    const row = data.map((item) => ({
      id: item.id,
      allocationType: item.allocationType,
      purchasedPackages: item.purchasedPackages,
      totalUnits: item.totalUnits,
      userList: item.userList || [],
      euoList: item.euoList || [],
      bgList: item.bgList || [],
    }));

    setRow(row);
  }, [data]);
  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "40px",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "space-between",
          width: "90%",
          height: "80%",
          gap: "30px",
          alignItems: "center",
          marginBottom: "10px",
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "20px",
          flexDirection: "column",
        }}
      >
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", top: 5, right: 5 }}>
          <Close />
        </IconButton>

        <Box sx={{ width: "100%", height: "100%", marginTop: "30px" }}>
          {data.length > 0 ? (
            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <DataGridTable columns={column} rows={row} menu={{ getRowHeight: () => 100 }} />
              <CustomButton text={"Allocate"} onClick={allocateUnits} sx={{ width: "100px" }} />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...theme.typography.h1, color: "gray", userSelect: "none" }}>No Prescribed Data Found</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

/**
 * purchased package
 * allocation type
 * user unit
 * end user
 * buyer guide
 * total unit
 */
