import React, { useContext } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress } from "@mui/material";
import { UserProfileContext } from "../../context/UserContext";

const NewOrgDialog = ({ open, onClose, orgName, industryName, onOrgInputChange, onIndustryInputChange, onOrgSubmit, AddNewEndUserOrganization, isLoading }) => {
  const { setNotification } = useContext(UserProfileContext);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ backgroundColor: "white" }}>New End-User Organization</DialogTitle>
      <DialogContent sx={{ color: "white", backgroundColor: "white" }}>
        <TextField data-test-id="organization-name" autoFocus margin="dense" label="Organization Name" type="search" fullWidth value={orgName} onChange={onOrgInputChange} />
        <TextField data-test-id="industry-name" margin="dense" label="Industry Name" type="search" fullWidth value={industryName} onChange={onIndustryInputChange} />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "white" }}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (orgName === "" || industryName === "") {
              setNotification({
                open: true,
                message: "Please Provide Organization and Industry Name",
                severity: "warning",
              });
            } else {
              onOrgSubmit();
            }
          }}
          color="primary"
        >
          {isLoading ? <CircularProgress size={24} /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewOrgDialog;

/**
 * org is not compusory
 *
 */
