import React, { useEffect } from "react";
import QuadrantCommentary from "./QuadrantCommentary";
import { Box ,Typography } from "@mui/material";
import Loader from "../../../components/Loader";
function QuadrantSummary({ commentary, section, setCommentary, data, generateReport }) {
  useEffect(()=>{
       if(data.length === 0){
       setCommentary((prev)=>{
          return {
          ...prev,
          [section]: {
            ...prev[section],
            quadrantSummary: {
              ...prev[section].quadrantSummary,
              isLoading: false,
            },
          },
        };
      });
    }
  }, [data, section, setCommentary]);
  if (data.length === 0) {
    return <Typography variant="h6">No data available.</Typography>;
  } else {
    return (
      <Box>
        {commentary[section]?.quadrantSummary.offer_summaries.length === data?.length ? (
          <QuadrantCommentary
            commentary={commentary}
            section={section}
            setCommentary={setCommentary}
            offer_summaries={
             commentary[section].quadrantSummary.offer_summaries
            }
            generateReport={generateReport}
          />
        ) : (
          // <Box mt={4} textAlign="center">
          //   <CircularProgress />
          // </Box>
          <Loader open={true} />
        )}
      </Box>
    );
  }
}

export default QuadrantSummary;
