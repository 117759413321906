import React, { Suspense, useState, useEffect } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  TextField,
  Autocomplete,
  Box,
  Checkbox,
  useMediaQuery,
  // CircularProgress,
} from "@mui/material"; // MUI components
import "../GridLayout/GridComponent.css";
import { vendorWidgetComponent, vendorWidgetOptions } from "./helper";
import LabelCopyViewClose from "../../components/LabelCopyViewClose";
import Loader from "../../components/Loader";

const ReactGridLayout = WidthProvider(RGL);

export default function VendorOfferDetail() {
  const issmallscreen = useMediaQuery((theme) => theme.breakpoints.down("md")); // Media query to adjust layout for small screens

  const [layout, setLayout] = useState(initialLayout);
  const [isTableVisible, setIsTableVisible] = useState(
    JSON.parse(localStorage.getItem("offerDetailsIsTableVisible")) || true
  );
  const [widgets, setWidgets] = useState(
    // JSON.parse(localStorage.getItem("offerDetailsWidgets")) ||
    vendorWidgetOptions.reduce((acc, option) => {
      acc[option.key] = true; // Default: all widgets are visible
      return acc;
    }, {})
  );
  useEffect(() => {
    localStorage.setItem("offerDetailsWidgets", JSON.stringify(widgets));
  }, [widgets]);

  const toggleWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const isActive = !prevWidgets[widgetKey]; // Toggle widget visibility
      const updatedWidgets = { ...prevWidgets, [widgetKey]: isActive };

      // Update layout based on widget visibility
      const newLayout = isActive
        ? [
            ...layout,
            {
              ...initialLayout.find((item) => item.i === widgetKey),
            },
          ]
        : layout.filter((item) => item.i !== widgetKey);

      setLayout(newLayout); // Set new layout
      return updatedWidgets;
    });
  };

  const toggleTableVisibility = () => {
    setIsTableVisible((prev) => !prev); // Toggle the state for table visibility
  };

  const handleRemoveWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const { [widgetKey]: _, ...remainingWidgets } = prevWidgets; // Remove widget key
      return remainingWidgets;
    });

    setLayout((prevLayout) => prevLayout.filter((item) => item.i !== widgetKey)); // Remove from layout
  };

  const handleCopyWidget = () => {
    // Placeholder for widget copy functionality
    return;
  };

  const defaultProps = {
    isDraggable: true, // Widgets are draggable
    isResizable: true, // Widgets are resizable
    items: layout.length, // Number of items in the layout
    rowHeight: 70, // Height of each row in the grid
    onLayoutChange: function () {}, // Placeholder function for layout change handling
  };

  function generateWidgets() {
    return layout.map((layoutItem) => {
      const WidgetComponent = vendorWidgetComponent[layoutItem.i]; // Get the corresponding widget component
      if (!WidgetComponent) {
        console.error(`Component not found for key: ${layoutItem.i}`);
        return null;
      }

      const shortLabels = vendorWidgetOptions.find(
        (option) => option.key === layoutItem.i
      )?.shortname;

      const widgetLabel = vendorWidgetOptions.find((option) => option.key === layoutItem.i)?.label; // Get the widget label

      return (
        <Box
          key={layoutItem.i}
          className="grid-item"
          sx={{ borderRadius: "10px", border: "1px solid", overflow: "hidden" }}
          id={layoutItem.i}
        >
          <Box>
            <Box>
              <LabelCopyViewClose
                widgetLabel={widgetLabel}
                widgetKey={layoutItem.i}
                isTableVisible={isTableVisible}
                handleCopyWidget={handleCopyWidget}
                toggleTableVisibility={toggleTableVisibility}
                handleRemoveWidget={handleRemoveWidget}
                shortLabels={shortLabels}
              />
              <Box
                className="widget-content no-drag"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100%",
                  height: "auto",
                  maxHeight: "100%",
                  overflow: "auto",
                }}
              >
                <Suspense
                  fallback={
                    // <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    //   <CircularProgress />
                    // </Box>
                    <Loader open={true} />
                  }
                >
                  <WidgetComponent
                    widgetLabels={widgetLabel}
                    isTableVisible={isTableVisible}
                    widgetKey={layoutItem.i}
                    handleCopyWidget={handleCopyWidget}
                    toggleTableVisibility={toggleTableVisibility}
                    handleRemoveWidget={handleRemoveWidget}
                    setLayout={setLayout}
                    issmallscreen={issmallscreen}
                  />
                </Suspense>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    });
  }
  function handleLayoutChange(updatedLayout) {
    setLayout(updatedLayout); // Update layout with new positions/sizes
    defaultProps.onLayoutChange(updatedLayout); // Call default layout change handler
  }
  return (
    <Box sx={{ display: "flex", gap: "20px 0px", flexDirection: "column", overflow: "hidden" }}>
      {/* Widget selection dropdown */}
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Autocomplete
          multiple
          options={vendorWidgetOptions} // List of widgets to add/remove
          getOptionLabel={(option) => option.label}
          value={vendorWidgetOptions.filter((option) => widgets[option.key])}
          onChange={(event, newValue) => {
            const updatedWidgets = vendorWidgetOptions.reduce((acc, option) => {
              acc[option.key] = newValue.some((newOpt) => newOpt.key === option.key);

              if (acc[option.key] !== widgets[option.key]) toggleWidget(option.key);

              return acc;
            }, {});

            setWidgets(updatedWidgets);
          }}
          renderInput={(params) => (
            <TextField
              data-test-id="text-field"
              {...params}
              variant="outlined"
              placeholder="Add/Remove Widgets"
              style={{ minWidth: "200px" }} //issue was in this line which was causing overlapping of widgets. reason:-> height was set to 40px;
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li data-test-id={`${option.label}`} {...props}>
              <Checkbox
                data-test-id={`checkbox-${option.label}`}
                checked={selected}
                style={{ marginRight: 2 }}
              />
              {option.label}
            </li>
          )}
          ChipProps={{
            sx: {
              backgroundColor: "#40bab414",
              color: "#000",
            },
          }}
          sx={{ width: "100%", textAlign: "center", padding: "10px" }}
        />
      </Box>

      {/* React grid layout */}
      <ReactGridLayout
        layout={layout}
        cols={issmallscreen ? 1 : 12}
        onLayoutChange={handleLayoutChange}
        {...defaultProps}
        draggableCancel=".no-drag"
        draggableHandle=".widget"
        autoSize={true}
      >
        {generateWidgets()}
      </ReactGridLayout>
    </Box>
  );
}

const initialLayout = [
  { i: "mandatory_cases", x: 0, y: 0, w: 6, h: 3 },
  { i: "overall_cases", x: 6, y: 0, w: 6, h: 3 },
  { i: "top_support_overall", x: 0, y: 14, w: 6, h: 3 },
  { i: "top_support_mandatory", x: 14, y: 14, w: 6, h: 3 },
  { i: "top_unsupported_mandtory", x: 0, y: 34, w: 6, h: 3 },
  { i: "top_unsupported_overall", x: 34, y: 44, w: 6, h: 3 },
  { i: "top_unused_generic", x: 0, y: 54, w: 6, h: 3 },
  { i: "top_unused_notRequired", x: 6, y: 54, w: 6, h: 3 },
];
