import { lazy } from "react";
import MandatoryUseCaseTable from "../Widget/OfferDetailsWidget/MandatoryUseCaseTable";
import OverallUseCasesTable from "../Widget/OfferDetailsWidget/OverallUseCasesTable";
import SupportedUseCaseMandatoryTable from "../Widget/OfferDetailsWidget/SupportedUseCaseMandatoryTable";
import SupportedUseCaseOverallTable from "../Widget/OfferDetailsWidget/SupportedUseCaseOverallTable";
import UnsupportedUseCaseMandatoryTable from "../Widget/OfferDetailsWidget/UnsupportedUseCaseMandatoryTable";
import UnsupportedUseCaseOverallTable from "../Widget/OfferDetailsWidget/UnsupportedUseCaseOverallTable";
import UnusedGenericTable from "../Widget/OfferDetailsWidget/UnusedGenericTable";
import UnusedNotRequiredTable from "../Widget/OfferDetailsWidget/UnusedNotRequiredTable";
import CommonVendorDetailWidget from "../Widget/VendorDetailsWidget/CommonVendorDetailWidget";
import CommonVendorDetailTable from "../Widget/VendorDetailsWidget/CommonVendorDetailTable";

// Dynamically import widget components
const DataGridComponent = lazy(() => import("../Widget/ComparativeAnalysisWidget/DetailMetrics"));
const BestOfBest = lazy(() => import("../Widget/ComparativeAnalysisWidget/BestOfBest"));
const UnmetUseCases = lazy(() => import("../Widget/ComparativeAnalysisWidget/UnmetUseCases"));
const CoverageHeatmap = lazy(() => import("../Widget/ComparativeAnalysisWidget/CoverageHeatmap"));
const Card = lazy(() => import("../Widget/ComparativeAnalysisWidget/Card"));

// Map widget keys to their components
const widgetComponents = {
  chart_box: DataGridComponent, //      this component is taking  (isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout) as prop
  best_of_best: BestOfBest, //          this component is taking  (isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget,) as prop
  unmet_use_cases: UnmetUseCases, //    this component is taking  (isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout) as prop

  coverage_heatmap: CoverageHeatmap, // this component is taking  (showDetails, setShowDetails, isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout) as prop
  // Reuse NewCard for multiple widget keys
  ...Object.fromEntries(
    [
      "smart_card",
      "premium_card",
      "low_card",
      "budget_card",
      "relevant_vendors",
      "relevant_offers",
    ].map((key) => [key, Card]) //    this component is taking  (isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout) as prop
  ),
};

// List of widget options for rendering in a dropdown or other UI elements
const widgetOptions = [
  { label: "Comparative Analysis Summary", key: "chart_box", shortname: "Comparative Summary" },
  { label: "Smart", key: "smart_card", shortname: "Smart" },

  { label: "The Best of the Best", key: "best_of_best", shortname: "Best of the Best" },
  { label: "Premimum", key: "premium_card", shortname: "Premimum" },

  {
    label: "Top Unmet Use Cases across all Vendors/ Offers",
    key: "unmet_use_cases",
    shortname: "Unmet Cases",
  },
  { label: "Low", key: "low_card", shortname: "Low" },

  { label: "Coverage Heatmap", key: "coverage_heatmap", shortname: "Coverage" },
  { label: "Budget", key: "budget_card", shortname: "Budget" },

  { label: "Relevant Vendors", key: "relevant_vendors", shortname: "Relevant Vendors" },
  { label: "Relevant Offers", key: "relevant_offers", shortname: "Relevant Offers" },
];

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 * Below is the helpers for VendorsOfferDetail file only
 */

const vendorWidgetComponent = {
  ...Object.fromEntries(
    ["mandatory_cases", "overall_cases"].map((key) => [key, CommonVendorDetailWidget])
  ),
  ...Object.fromEntries(
    [
      "top_support_mandatory",
      "top_support_overall",
      "top_unsupported_mandtory",
      "top_unsupported_overall",
      "top_unused_generic",
      "top_unused_notRequired",
    ].map((key) => [key, CommonVendorDetailTable])
  ),
};

const vendorWidgetOptions = [
  {
    label: "Vendor Metrics - Mandatory Use Cases",
    key: "mandatory_cases",
    shortname: "Metrics - Mandatory",
  },
  {
    label: "Vendor Metrics - Overall Use Cases",
    key: "overall_cases",
    shortname: "Metrics - Overall",
  },
  {
    label: "Top 5 Supported Use Cases (Mandatory)",
    key: "top_support_mandatory",
    shortname: "Supported Mandatory",
  },
  {
    label: "Top 5 Supported Use Cases (Overall)",
    key: "top_support_overall",
    shortname: "Supported Overall",
  },
  {
    label: "Top 5 Unsupported Use Cases (Mandatory)",
    key: "top_unsupported_mandtory",
    shortname: "Unsupported Mandatory",
  },
  {
    label: "Top 5 Unsupported Use Cases (Overall)",
    key: "top_unsupported_overall",
    shortname: "Unsupported Overall",
  },
  {
    label: "Top 5 Unused Features Widget (Generic)",
    key: "top_unused_generic",
    shortname: "Unused Generic",
  },
  {
    label: "Top 5 Unused Features Widget (‘Not Required’ Use Cases)",
    key: "top_unused_notRequired",
    shortname: "Not Required",
  },
];

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 * Below is the helpers for OfferDetail file only
 */

const offerWidgetComponent = {
  mandatory_cases: MandatoryUseCaseTable,
  overall_cases: OverallUseCasesTable,
  top_support_mandtory: SupportedUseCaseMandatoryTable,
  top_support_overall: SupportedUseCaseOverallTable,
  top_unsupported_mandatory: UnsupportedUseCaseMandatoryTable,
  top_unsupported_overall: UnsupportedUseCaseOverallTable,
  top_unused_generic: UnusedGenericTable,
  top_unused_notRequired: UnusedNotRequiredTable,
};

const offerWidgetOptions = [
  {
    label: "Offer Metrics - Mandatory Use Cases",
    key: "mandatory_cases",
    shortname: "Metrics - Mandatory",
  },
  {
    label: "Offer Metrics - Overall Use Cases",
    key: "overall_cases",
    shortname: "Metrics - Overall",
  },
  {
    label: "Top 5 Supported Use Cases (Mandatory)",
    key: "top_support_mandtory",
    shortname: "Supported Mandatory",
  },
  {
    label: "Top 5 Supported Use Cases (Overall)",
    key: "top_support_overall",
    shortname: "Supported Overall",
  },
  {
    label: "Top 5 Unsupported Use Cases (Mandatory)",
    key: "top_unsupported_mandatory",
    shortname: "Unsupported Mandatory",
  },
  {
    label: "Top 5 Unsupported Use Cases (Overall)",
    key: "top_unsupported_overall",
    shortname: "Unsupported Overall",
  },
  {
    label: "Top 5 Unused Features Widget (Generic)",
    key: "top_unused_generic",
    shortname: "Unused Generic",
  },
  {
    label: "Top 5 Unused Features Widget (‘Not Required’ Use Cases)",
    key: "top_unused_notRequired",
    shortname: "Not Required",
  },
];

export {
  /**comparitive analysis */
  widgetOptions,
  widgetComponents,

  /**vendor details */
  vendorWidgetOptions,
  vendorWidgetComponent,

  /**offer details */
  offerWidgetOptions,
  offerWidgetComponent,
};
