export const sectionQuadrantMap = {
  
  
  "SmartValyou":"Smart",
  "PremiumValyou":"Premium",
  "BudgetValyou":"Budget",
  "LowValyou":"Low"

}

export const ComparativeTableMap = {
    "detailMatrix":"Detail Matrix",
    "bestOfBest":"Best of Best",
    "unMetUseCase": "Top Unmet Use Case across all Vendors/Offer"        ,
    "coverageHeatmap":"Coverage HeatMap",
    "relevantVendors":"Relevant Vendors",
    "relevantOffers":"Relevant Offers",
}


