import { Fragment, useContext, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, Chip, Divider, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import theme from "../../../theme/theme";
import CustomButton from "../../../components/CustomButton";
import { Add, Delete } from "@mui/icons-material";
import { AllocationContext } from "../AllocationContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function BuyerAndEUO({ id, list, setAllocatedData, quantity, label, handleClose }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [maxCombinationUnits] = useState(quantity);
  const { setVendorPopup, handelVendorSelection } = useContext(AllocationContext);

  const handleAddClick = () => {
    if (inputValue.trim() !== "") {
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        { title: inputValue.trim(), id: null }, // Add inputValue as an object with the title key
      ]);
      setInputValue(""); // Clear the text field after adding
    }
  };
  const handleDeleteClick = (title) => {
    setSelectedOptions((prevOptions) => prevOptions.filter((option) => option.title !== title));
  };

  const handleSubmit = () => {
    setAllocatedData((prevData) => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex((item) => item.id === id);

      if (index !== -1) {
        // If the object exists, update its `userList`
        updatedData[index] = { ...updatedData[index], [fieldMapForAllocatedData[label]]: selectedOptions };
      } else {
        // If the object doesn't exist, add a new object with `id` and `userList`
        updatedData.push({ id, [fieldMapForAllocatedData[label]]: selectedOptions });
      }

      return updatedData;
    });
    setSelectedOptions([]);
    handleClose();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", position: "relative", width: "100%", height: "100%", margin: "auto", backgroundColor: "#f5f5f5", borderRadius: 2, boxShadow: 2 }}>
      <Box sx={{ height: "100%", overflowY: "auto", paddingTop: "15px" }}>
        {list.length === 0 ? (
          <Box sx={{ padding: 3, paddingTop: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "10px",
                [theme.breakpoints.down("md")]: { flexDirection: "column", alignItems: "flex-start" },
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "73%", [theme.breakpoints.down("md")]: { width: "100%" } }}
              >
                Please Enter the End User Org. Name
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ textAlign: "left", width: "26%", whiteSpace: "nowrap" }}>
                <Typography component="span" variant="inherit" style={{ fontWeight: "bold", marginRight: "5px" }}>
                  Remaining Units :
                </Typography>
                {maxCombinationUnits - selectedOptions.length}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <TextField
                label={label}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                variant="outlined"
                size="large"
                type="text"
                sx={{ flex: 1 }}
                placeholder="Org. Name"
                slotProps={{
                  inputLabel: {
                    color: "black",
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // Ensure the input is valid before calling handleAddClick
                    if (inputValue && selectedOptions.length < quantity) {
                      handleAddClick();
                    }
                  }
                }}
              />
              <IconButton
                disabled={selectedOptions.length === quantity}
                aria-label="add-button"
                size="large"
                onClick={handleAddClick}
                sx={{ backgroundColor: theme.palette.primary.main, borderRadius: "5px", width: "50px", height: "100%", display: "felx", ":hover": { backgroundColor: theme.palette.primary.dark } }}
              >
                <Add sx={{ color: "#fff" }} />
              </IconButton>
            </Box>
            <List dense={true}>
              {selectedOptions.map((d, index) => (
                <Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={d.title}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    />
                    <IconButton onClick={() => handleDeleteClick(d.title)}>
                      <Delete />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Box>
        ) : (
          <Box sx={{ padding: 3, paddingTop: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "10px",
                [theme.breakpoints.down("md")]: { flexDirection: "column", alignItems: "flex-start" },
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", flex: 1, [theme.breakpoints.down("md")]: { width: "100%" } }}
              >
                {label === "Buyer Guide list" ? "Please Select Buyer Guide From List" : "Please Select End User Org. From List"}
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
                <Typography component="span" variant="inherit" style={{ fontWeight: "bold", marginRight: "5px" }}>
                  Remaining Units :
                </Typography>
                {maxCombinationUnits - selectedOptions.length}
              </Typography>
            </Box>
            <Autocomplete
              multiple
              id="checkboxes-tags"
              limitTags={3}
              options={list}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              onChange={(e, value) => {
                setSelectedOptions(value);
              }}
              onScroll={(e) => {
                e.stopPropagation(); // Prevent scroll propagation to parent
              }}
              getOptionDisabled={(option) => {
                return selectedOptions.length === quantity && !selectedOptions.includes(option.id);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      label={option.title}
                      size="large"
                      {...tagProps}
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: "#fff",
                        fontSize: "16px",
                        "& .MuiChip-deleteIcon": {
                          backgroundColor: "#ffffffff",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  );
                })
              }
              renderOption={(props, option, { selected }, index) => {
                const { onClick, ...optionProps } = props;
                return (
                  <li
                    key={index}
                    {...optionProps}
                    onClick={(e) => {
                      onClick(e);
                      if (label === "Buyer Guide list" && !selected) {
                        handelVendorSelection(option);
                        setVendorPopup(true);
                      }
                    }}
                  >
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.title}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  placeholder="Select End-Users"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#000",
                    },
                  }}
                />
              )}
            />
          </Box>
        )}
      </Box>
      <CustomButton
        sx={{ m: 2, width: "120px", backgroundColor: theme.palette.primary.main }}
        text={"Allocate"}
        variant={"contained"}
        aria-label="allocate-button"
        onClick={handleSubmit}
        disabled={selectedOptions.length < quantity}
      />
    </Box>
  );
}
const fieldMapForAllocatedData = {
  "Buyer Guide list": "bgList",
  "End-User List": "euoList",
};
