import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../utils/utils.js";
import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../context/UserContext.jsx";
import LeftPane from "./LeftPane";
import RightPane from "./RightPane";
import { Add, Remove } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import FlipMove from "react-flip-move";

import { useTheme } from "@emotion/react";
const ContainerWithPanes = () => {
  const navigate = useNavigate();
  const {
    currentOrg,
    userProfile,
    scenarioName,
    currentOrganization,
    buyerGuideName,
    currentBuyerGuideId,
    currentScenarioId,
    currentOrganizationId,
    currentPackage,
  } = useContext(UserProfileContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [baseUseCases, setBaseUseCases] = useState([]);
  const [leftPaneUseCases, setLeftPaneUseCases] = useState([]);
  const [rightPaneUseCases, setRightPaneUseCases] = useState([]);
  const [useCaseHistory, setUseCaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noChildren, setNoChildren] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [importanceInputs, setImportanceInputs] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningRightPane, setShowWarningRightPane] = useState(false);
  const [focusedElementId, setFocusedElementId] = useState(null);
  const [selectedElementId, setSelectedElementId] = useState(null);
  const [importanceValues, setImportanceValues] = useState([]);
  const [mandatoryStatus , setmandatoryStatus] = useState([]);
  const textFieldRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const subUseCaseClickedRef = useRef(false);
  const clickedUseCase = useRef(null);
  const parentOfLeftPane = useRef(null);
  const theme = useTheme();
  const headerdata = {
    "End-user Organisation" :
      userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  const getBaseUseCases = useCallback(() => {
    apiService(
      "https://newtestfuncpython.azurewebsites.net/api/getUpdateUseCases?",
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentScenario: currentScenarioId,
          currentPackage: currentPackage.id,
        },
      },
      setLoading,
      setBaseUseCases,
      (error) => {
        console.error("Failed to load base use cases:", error);
      }
    );
  }, [
    userProfile?.email,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage.id,
    currentScenarioId,
  ]);

  const setBaseUseCasesData = () => {
    apiService(
      `https://newtestfuncpython.azurewebsites.net/api/updateUseCases?`,
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: currentBuyerGuideId,
          currentScenario: currentScenarioId,
          currentPackage: currentPackage.id,
          newImportanceValues: importanceValues,
          newMandatoryStatus: mandatoryStatus,
        },
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getBaseUseCases();
   
  }, [getBaseUseCases]);
  
  useEffect(() => {
    if (baseUseCases && baseUseCases?.length > 0) {
      setLeftPaneUseCases(sortUseCases(baseUseCases));
      setRightPaneUseCases(sortUseCases(baseUseCases[0].children));
      clickedUseCase.current = baseUseCases[0].element_id;
      setSelectedElementId(baseUseCases[0].element_id);
      setFocusedElementId(baseUseCases[0].element_id);
    }
  }, [baseUseCases]);

  const sortUseCases = (useCases) => {
    return useCases.sort((a, b) => b.importance - a.importance);
  };

  const toggleCheckedState = (useCase, checked) => {
    useCase.checked = checked;
    if (useCase.children) {
      useCase.children.forEach((child) => toggleCheckedState(child, checked));
    }
  };

  const handleCheckboxToggle = (useCases, setUseCases, index, isBaseUseCase) => {
    const updatedUseCases = [...useCases];
    
    if (isBaseUseCase) {
      const newCheckedState = !updatedUseCases[index].checked;
      toggleCheckedState(updatedUseCases[index], newCheckedState);
      if (rightPaneUseCases === updatedUseCases.children) {
        setRightPaneUseCases({ ...updatedUseCases[index].children });
      }
    } else {
      updatedUseCases[index].checked = !updatedUseCases[index].checked;
    }
    setUseCases(sortUseCases(updatedUseCases));
  };
 
  const handleEditImportance = (elementId, importance) => {
    let importanceValue = parseFloat(importance);
    if (importanceValue < 0) {
      importanceValue = 0.0;
    } else if (importanceValue > 5) {
      importanceValue = 5.0;
    }

    importanceValue = Math.round(importanceValue * 10) / 10;

    const updatedUseCases = leftPaneUseCases.map((useCase) =>
      useCase.element_id === elementId ? { ...useCase, importance: importanceValue } : useCase
    );
    
    setLeftPaneUseCases(sortUseCases(updatedUseCases));

    if (rightPaneUseCases.some((useCase) => useCase.element_id === elementId)) {
      const updatedRightPaneUseCases = rightPaneUseCases.map((useCase) =>
        useCase.element_id === elementId ? { ...useCase, importance: importanceValue } : useCase
      );
      setRightPaneUseCases(sortUseCases(updatedRightPaneUseCases));
    }
    
    setImportanceValues((prev) => {
      const existingIndex = prev.findIndex((item) => item.useCaseId === elementId);
      if (existingIndex !== -1) {
        const updated = [...prev];
        updated[existingIndex] = {
          useCaseId: elementId,
          importance: importanceValue,
        };
        return updated;
      } else {
        return [...prev, { useCaseId: elementId, importance: importanceValue }];
      }
    });
  };
  
  const handleSelectChildUseCase = (child, isBaseUseCase) => {
    setLoading(true);
    subUseCaseClickedRef.current = true;
    const clickUseCase = clickedUseCase.current;
    
    const parentElementIndex = leftPaneUseCases?.findIndex((item)=>{return item?.element_id===clickUseCase});

    if (child.children && child.children.length > 0) {
      setTimeout(() => {
        if (!isBaseUseCase) {
          setUseCaseHistory((prevHistory) => [
            ...prevHistory,
            {
              left: leftPaneUseCases,
              right: rightPaneUseCases,
              element_id: clickUseCase,
              parentOfLeftPane : parentOfLeftPane.current,
            },
          ]);
          setLeftPaneUseCases(rightPaneUseCases);
          setRightPaneUseCases(sortUseCases(child.children));
          setSelectedElementId(child.element_id);
          setFocusedElementId(child.element_id);
        } else {
          setLeftPaneUseCases(leftPaneUseCases);
          setRightPaneUseCases(sortUseCases(child.children));
          setSelectedElementId(child.element_id);
          setFocusedElementId(child.element_id);
        }

        setIsCollapsed(false);
        setNoChildren(false);
        setLoading(false);
      }, 500);
    } else {
      setTimeout(() => {
        setUseCaseHistory((prevHistory) => [
          ...prevHistory,
          {
            left: leftPaneUseCases,
            right: rightPaneUseCases,
            element_id: clickUseCase,
            parentOfLeftPane : parentOfLeftPane.current
          },
        ]);

        setLeftPaneUseCases(rightPaneUseCases);
        setRightPaneUseCases([]);
        setNoChildren(true);
        setLoading(false);
        setFocusedElementId(child.element_id);
        setSelectedElementId(child.element_id);
      }, 500);
    }
    clickedUseCase.current = child.element_id;
    parentOfLeftPane.current = leftPaneUseCases[parentElementIndex]
  };

  useEffect(() => {
    if (focusedElementId !== null && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
    }
  }, [leftPaneUseCases, focusedElementId]);

  const handleKeyPress = (event, elementId) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  const renderUseCaseList = (useCases, handleSelect, setUseCases, isBaseUseCase) => {
    
    return (
      <FlipMove duration={500} easing="ease-out" style={{ width: "100%" }}>
        {useCases &&
          useCases?.length > 0 &&
          useCases.map((useCase, index) => {
            return (
              <ListItem
                key={useCase.element_id}
                button
                onClick={(e) => {
                  if (e.target.tagName !== "INPUT" && e.target.tagName !== "TEXTAREA") {
                    handleSelect(useCase);
                    if (isBaseUseCase) {
                      setSelectedElementId(useCase.element_id);
                      setFocusedElementId(useCase.element_id);
                      clickedUseCase.current = useCase.element_id;
                    }
                  }
                }}
                style={{
                  backgroundColor:
                    selectedElementId === useCase.element_id && isBaseUseCase
                      ? "rgba(64, 186, 180, 0.08)"
                      : "transparent",
                  transition: "background-color 0.3s ease",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}
              >
                {isBaseUseCase && (
                  <Checkbox
                    data-test-id={`checkbox-${useCase.name}`}
                    color="secondary"
                    checked={useCase.checked}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxToggle(useCases, setUseCases, index, isBaseUseCase);
                    }}
                  />
                )}
                <Box width={"1em"} height={"1em"} sx={{margin:"1em", borderRadius:"0.2em"}} backgroundColor={useCase?.mandatory?theme.palette.secondary.main:null}></Box>
                <ListItemText
                  data-test-id={`name-${useCase.name}`}
                  primary={useCase.name}
                  sx={{ width: "50%" }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    width: "fit-content",
                    padding: "5px 5px",
                  }}
                  onClick={(e) => {
                    if (!isBaseUseCase) {
                      e.stopPropagation();
                      handleSelectChildUseCase(useCase);
                    }
                  }}
                  sx={{ cursor: isBaseUseCase ? "text" : "pointer" }}
                >
                  <TextField
                    data-test-id={`text-${useCase.name}`}
                    id={`importance-${useCase.element_id}`}
                    variant="standard"
                    color="secondary"
                    type="number"
                    inputRef={focusedElementId === useCase.element_id ? textFieldRef : null}
                    value={
                      importanceInputs[
                        `${isBaseUseCase ? "base" : "child"}-${useCase.element_id}`
                      ] !== undefined
                        ? importanceInputs[
                            `${isBaseUseCase ? "base" : "child"}-${useCase.element_id}`
                          ]
                        : useCase.importance.toFixed(1)
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.target.select();
                    }}
                    onChange={(e) => {
                      let value = +e.target.value;
                      console.log(useCase)
                      if(useCase?.mandatory && value <=0)
                      { 
                         value = 0.1;
                      }
    
                        
                      setImportanceValues((prev) => {
                        const existingIndex = prev.findIndex(
                          (item) => item.useCaseId === useCase?.element_id
                        );
                        
                        if (existingIndex !== -1) {
                          const updated = [...prev];
                          updated[existingIndex] = {
                            useCaseId: useCase?.element_id,
                            importance: value,
                          };
                          return updated;
                        } else {
                          return [
                            ...prev,
                            {
                              useCaseId: useCase?.element_id,
                              importance: value,
                            },
                          ];
                        }
                      });

                      setImportanceInputs((prevInputs) => ({
                        ...prevInputs,
                        [`${isBaseUseCase ? "base" : "child"}-${useCase.element_id}`]: value,
                      }));
                    }}
                    onKeyPress={(e) => handleKeyPress(e, useCase.element_id)}
                    onBlur={(e) => {
                      handleEditImportance(useCase.element_id, +e.target.value);
                      setImportanceInputs((prevInputs) => ({
                        ...prevInputs,
                        [`${isBaseUseCase ? "base" : "child"}-${useCase.element_id}`]: undefined,
                      }));
                      if (isBaseUseCase) {
                        setFocusedElementId(null);
                      }
                    }}
                    onFocus={() => {
                      if (isBaseUseCase) {
                        setFocusedElementId(useCase.element_id);
                      }
                    }}
                    inputProps={{
                      step: 0.1,
                      min: 0.0,
                      max: 5.0,
                      style: { textAlign: "center" },
                    }}
                    sx={{
                      width: "50%",
                      "& .MuiInputBase-input": {
                        WebkitTextFillColor: "black",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom:
                          focusedElementId === useCase.element_id ? "2px solid" : "none",
                        width: "80%",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom:
                          focusedElementId === useCase.element_id ? "2px solid" : "none",
                        width: "80%",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom:
                          focusedElementId === useCase.element_id ? "2px solid" : "none",
                        width: "80%",
                      },
                      pointerEvents: isBaseUseCase ? "auto" : "none",
                    }}
                    disabled={!isBaseUseCase}
                  />
                  {useCase.children && useCase.children.length > 0 ? (
                    <Tooltip title="View Children" arrow>
                      {useCase.element_id === clickedUseCase.current && isBaseUseCase ? (
                        <IconButton
                          edge="end"
                          aria-label="more"
                          onClick={(e) => {
                            e.stopPropagation();
                            clickedUseCase.current = null;
                            setRightPaneUseCases([]);
                          }}
                        >
                          <Remove data-test-id={`remove-${useCase.name}`} />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectChildUseCase(useCase, isBaseUseCase);
                          }}
                        >
                          <Add data-test-id={`add-${useCase.name}`} />
                        </IconButton>
                      )}
                    </Tooltip>
                  ) : (
                    <Box style={{ width: "28px", height: "28px" }}></Box>
                  )}
                </Box>
              </ListItem>
            );
          })}
      </FlipMove>
    );
  };

  useEffect(() => {
    if (subUseCaseClickedRef.current && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
      subUseCaseClickedRef.current = false;
    }

    if (Array.isArray(baseUseCases) && baseUseCases.length > 0) {
      setLoading(false);
    }
  }, [leftPaneUseCases, baseUseCases]);

  // if (loading) {
  //   return (
  //     <Box mt={4} textAlign="center">
  //     </Box>
  //   );
  // }

  return (
    <Box sx={{ overflowX: "hidden", position: "relative", paddingBottom: "150px" }}>
      <HeaderNaming data={headerdata} />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        width="100%"
        minHeight="100vh"
      >
        <LeftPane
          leftPaneUseCases={leftPaneUseCases}
          setLeftPaneUseCases={setLeftPaneUseCases}
          rightPaneUseCases={rightPaneUseCases}
          setRightPaneUseCases={setRightPaneUseCases}
          useCaseHistory={useCaseHistory}
          setImportanceValues={setImportanceValues}
          setUseCaseHistory={setUseCaseHistory}
          loading={loading}
          setLoading={setLoading}
          noChildren={noChildren}
          setNoChildren={setNoChildren}
          selectAllChecked={selectAllChecked}
          setSelectAllChecked={setSelectAllChecked}
          importanceInputs={importanceInputs}
          setImportanceInputs={setImportanceInputs}
          showWarning={showWarning}
          setShowWarning={setShowWarning}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          focusedElementId={focusedElementId}
          setFocusedElementId={setFocusedElementId}
          renderUseCaseList={renderUseCaseList}
          handleCheckboxToggle={handleCheckboxToggle}
          handleEditImportance={handleEditImportance}
          selectedElementId={selectedElementId}
          toggleCheckedState={toggleCheckedState}
          setSelectedElementId={setSelectedElementId}
          setmandatoryStatus={setmandatoryStatus}
          mandatoryStatus={mandatoryStatus}
          ref={{clickedUseCase,parentOfLeftPane}}
        />
        {isMobile && (
          <>
            <RightPane
              rightPaneUseCases={rightPaneUseCases}
              setRightPaneUseCases={setRightPaneUseCases}
              leftPaneUseCases={leftPaneUseCases}
              setLeftPaneUseCases={setLeftPaneUseCases}
              useCaseHistory={useCaseHistory}
              setUseCaseHistory={setUseCaseHistory}
              loading={loading}
              setLoading={setLoading}
              noChildren={noChildren}
              setNoChildren={setNoChildren}
              importanceInputs={importanceInputs}
              setImportanceInputs={setImportanceInputs}
              showWarningRightPane={showWarningRightPane}
              setShowWarningRightPane={setShowWarningRightPane}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              focusedElementId={focusedElementId}
              setFocusedElementId={setFocusedElementId}
              handleCheckboxToggle={handleCheckboxToggle}
              renderUseCaseList={renderUseCaseList}
              handleEditImportance={handleEditImportance}
              selectedElementId={selectedElementId}
              toggleCheckedState={toggleCheckedState}
              handleSelectChildUseCase={handleSelectChildUseCase}
            />
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
              <Button
                style={{ width: "250px", marginTop: "20px" }}
                color="primary"
                variant="contained"
                disableRipple
                onClick={() => {
                  setBaseUseCasesData();
                  navigate("/edit-scenario/scenario-settings/bundle-option");
                }}
              >
                <Typography data-test-id="save&continue" color="white">
                  Save & Continue
                </Typography>
              </Button>
              <Button
                style={{ width: "250px", marginTop: "10px" }}
                color="secondary"
                variant="contained"
                disableRipple
                onClick={() => {
                  setBaseUseCasesData();
                  navigate("/results/comparative-analysis", {
                    state: {
                      rightPaneUseCases,
                      leftPaneUseCases,
                      useCaseHistory,
                    },
                  });
                }}
              >
                <Typography data-test-id="dashboard" color="white">
                  Save & Exit to Main Dashboard
                </Typography>
              </Button>
            </Box>
          </>
        )}
        {!isMobile && (
          <RightPane
            rightPaneUseCases={rightPaneUseCases}
            setRightPaneUseCases={setRightPaneUseCases}
            leftPaneUseCases={leftPaneUseCases}
            setLeftPaneUseCases={setLeftPaneUseCases}
            useCaseHistory={useCaseHistory}
            setUseCaseHistory={setUseCaseHistory}
            loading={loading}
            setLoading={setLoading}
            noChildren={noChildren}
            setNoChildren={setNoChildren}
            importanceInputs={importanceInputs}
            setImportanceInputs={setImportanceInputs}
            showWarningRightPane={showWarningRightPane}
            setShowWarningRightPane={setShowWarningRightPane}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            focusedElementId={focusedElementId}
            setFocusedElementId={setFocusedElementId}
            handleCheckboxToggle={handleCheckboxToggle}
            renderUseCaseList={renderUseCaseList}
            handleEditImportance={handleEditImportance}
            selectedElementId={selectedElementId}
            toggleCheckedState={toggleCheckedState}
            handleSelectChildUseCase={handleSelectChildUseCase}
          />
        )}
      </Box>
      {!isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
          padding="20px"
        >
          <Button
            style={{ width: "250px", marginTop: "20px" }}
            color="primary"
            variant="contained"
            disableRipple
            onClick={() => {
              setBaseUseCasesData();
              navigate("/edit-scenario/scenario-settings/bundle-option");
            }}
          >
            <Typography data-test-id="save&continue" color="white">
              Save & Continue
            </Typography>
          </Button>
          <Button
            style={{ width: "250px", marginTop: "10px" }}
            color="secondary"
            variant="contained"
            disableRipple
            onClick={() => {
              setBaseUseCasesData();
              navigate("/results/comparative-analysis", {
                state: {
                  rightPaneUseCases,
                  leftPaneUseCases,
                  useCaseHistory,
                },
              });
            }}
          >
            <Typography data-test-id="dashboard" color="white">
              Save & Exit to Main Dashboard
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ContainerWithPanes;
