import React from "react";
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from "react-router-dom";
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import Users from "./pages/Users";
import Activity from "./pages/Activity";
import ShareReport from "./pages/ShareReport";
import ReviewComplete from "./pages/ReviewComplete";
import Wipusecase from "./pages/Wipusecase";
import SelectBuyerGuide from "./pages/TopNavPages/SelectBuyerGuide";
import UpdateUseCase from "./pages/TopNavPages/UpdateUseCase";
import SavedReports from "./pages/SavedReports.jsx";
import ViewReport from "./pages/ViewReports.jsx";
import ReportSettings from "./pages/ReportSettings.jsx";
import CollaborateUseCase from "./pages/TopNavPages/CollaborateUseCase";
import ComparativeAnalysis from "./pages/TopNavPages/DashBoard/ComparativeAnalysis";
import VendorDetails from "./pages/TopNavPages/DashBoard/VendorDetails";
import OfferDetails from "./pages/TopNavPages/DashBoard/OfferDetails";
import StartNewUseCase from "./pages/StartNewUseCase";
import VendorOptions from "./pages/FineTuneTopNav/VendorOptions";
import FineTune from "./pages/FineTuneTopNav/FineTune";
import UpdateUseCasesFineTune from "./pages/FineTuneTopNav/UpdateUseCasesFineTune";
import { loginRequest } from "./auth-config";
import Login from "./pages/Login";
import Layout from "./layout/Layout";
import EndUserPackages from "./pages/EndUserPackages";
import SelectEndUser from "./pages/SelectEndUser"; // Updated import name
import SelectBuyer from "./pages/SelectBuyer";
import InterestedBuyer from "./pages/InterestedBuyer";
import SelectScenario from "./pages/SelectScenario";
import PurchasePackSection from "./pages/PurchangePackSection";
import SubscriptionDetails from "./pages/SubscriptionDetails";
import PrivateRoute from "./routes/PrivateRoute";
import { UserProfileProvider } from "./context/UserContext.jsx";
import { StaticReportProvider } from "./context/StaticReportContext.jsx";
import BundleSelection from "./pages/FineTuneTopNav/BundleSelection.jsx";
import ThresholdOption from "./pages/FineTuneTopNav/ThresholdOption.jsx";
import MandatoryDisplayOption from "./pages/FineTuneTopNav/MandatoryDisplayOption.jsx";
import ReportOptions from "./pages/FineTuneTopNav/ReportOptions.jsx";
import UsePurchasedPackage from "./pages/UsePurchasedPackage.jsx";
import WelcomePage from "./pages/Welcome.jsx";
// import { apiService } from "./utils/utils.js";
import MainComponent from "../src/Analysis-Tool/MainComponent.js";
import AnalystWelcome from "./Analysis-Tool/AnalystWelcome.jsx";
import { CreateNewBuyerGuide } from "./Analysis-Tool/CreateNewBuyerGuide.jsx";
import BuyerGuideMenu from "./Analysis-Tool/BuyerGuideMenu.js";
import ReviewResultPage from "./Analysis-Tool/ReviewResultPage.js";
import StaticReport from "./pages/StaticReport.jsx";
import ModelAndParameter from "./Analysis-Tool/Model&Parameter.jsx";
import UsecaseToFeature from "./Analysis-Tool/UsecaseToFeature.jsx";
import VendorOfferPage from "./Analysis-Tool/VendorOfferPage.js";
import { AddNewOffer } from "./Analysis-Tool/AddNewOffer.js";
import VendorOfferMenu from "./Analysis-Tool/VendorOfferMenu.js";
import BuyerGuideMappingOneToOne from "./Analysis-Tool/BuyerGuideMappingOneToOne.js";
import MappingType from "./Analysis-Tool/MapingType.js";
import ReviewVendorOfferPage from "./Analysis-Tool/ReviewVendorOfferPage.js";
import GeneratedFeatures from "./Analysis-Tool/GeneratedFeatures.js";
import MappingRecommendation from "./Analysis-Tool/MappingRecommendation.jsx";
import VendorOfferMenuMapping from "./Analysis-Tool/VendorOfferMenuMapping.js";
import VendorOfferPageMapping from "./Analysis-Tool/VendorOfferPageMapping.js";
import AllocationWrapper from "./pages/Allocation/AllocationWrapper.jsx";

const Pages = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleSignInRedirect = async () => {
    instance.loginRedirect({ ...loginRequest });
  };
  const handleSignUpRedirect = async () => {
    instance.loginRedirect({ ...loginRequest, prompt: "create" });
  };

  const handleSignInRedirectForEmployee = async () => {
    instance.loginRedirect({
      ...loginRequest,
      authority: "https://login.microsoftonline.com/smartdecisionai.onmicrosoft.com",
    });
    sessionStorage.setItem("userType", "employee");
  };

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Layout>
            <Routes>
              {/* This  is for LHS Navigation */}
              <Route path="/users" element={<Users />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    {/* <SelectEndUser /> */}
                    <WelcomePage />
                  </PrivateRoute>
                }
              />
              <Route path="/start-new-use-case" element={<StartNewUseCase />} />
              {/* activity section */}
              <Route
                path="/activity"
                element={
                  <PrivateRoute>
                    <Activity />
                  </PrivateRoute>
                }
              >
                <Route
                  path="reviewcomplete"
                  element={
                    <PrivateRoute>
                      <ReviewComplete />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="wipusecase"
                  element={
                    <PrivateRoute>
                      <Wipusecase />
                    </PrivateRoute>
                  }
                />
              </Route>
              {/* end of activity section */}
              <Route
                path="/sharereport"
                element={
                  <PrivateRoute>
                    <ShareReport />
                  </PrivateRoute>
                }
              />
              {/* This is for Top Navigation Bar */}
              <Route
                path="/select-end-user-org"
                element={
                  <PrivateRoute>
                    <SelectEndUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="/select-buyer-guide"
                element={
                  <PrivateRoute>
                    <SelectBuyerGuide />
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-change-use-cases"
                element={
                  <PrivateRoute>
                    <UpdateUseCase />
                  </PrivateRoute>
                }
              />
              <Route
                path="/collaborate-on-use-cases"
                element={
                  <PrivateRoute>
                    <CollaborateUseCase />
                  </PrivateRoute>
                }
              />
              {/* result section  */}
              <Route path="/results" element={<Outlet />}>
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <Navigate to="comparative-analysis" replace />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="comparative-analysis"
                  element={
                    <PrivateRoute>
                      <ComparativeAnalysis />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="vendor-details"
                  element={
                    <PrivateRoute>
                      <VendorDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="offer-details"
                  element={
                    <PrivateRoute>
                      <OfferDetails />
                    </PrivateRoute>
                  }
                />
              </Route>
              {/* end result section */}
              <Route
                path="/select-scenario"
                element={
                  <PrivateRoute>
                    <SelectScenario />
                  </PrivateRoute>
                }
              />
              {/* edit-scenario section */}
              <Route path="/edit-scenario" element={<Outlet />}>
                <Route index element={<Navigate to="vendor-options" replace />} />
                <Route
                  path="vendor-options"
                  element={
                    <PrivateRoute>
                      <VendorOptions />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="update-use-cases"
                  element={
                    <PrivateRoute>
                      <UpdateUseCasesFineTune />
                    </PrivateRoute>
                  }
                />

                {/* scenario-settings section */}
                <Route path="scenario-settings" element={<Outlet />}>
                  <Route index element={<Navigate to="bundle-option" replace />} />
                  <Route
                    path="bundle-option"
                    element={
                      <PrivateRoute>
                        <BundleSelection />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="threshold-option"
                    element={
                      <PrivateRoute>
                        <ThresholdOption />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="display-option"
                    element={
                      <PrivateRoute>
                        <MandatoryDisplayOption />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="report-settings"
                    element={
                      <PrivateRoute>
                        <ReportOptions />
                      </PrivateRoute>
                    }
                  />
                </Route>
                {/* scenario-settings section end */}
              </Route>
              {/* edit-scenario section end */}

              {/* select buyer guide  */}
              <Route path="/select-buyer-guide" element={<Outlet />}>
                <Route index element={<Navigate to="available-buyer-guides" replace />} />
                <Route
                  path="available-buyer-guides"
                  element={
                    <PrivateRoute>
                      <SelectBuyer />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="buyer-guide-of-interest"
                  element={
                    <PrivateRoute>
                      <InterestedBuyer />
                    </PrivateRoute>
                  }
                />
              </Route>
              {/* select buyer guide end */}

              <Route
                path="/claim-single-package"
                element={
                  <PrivateRoute>
                    <UsePurchasedPackage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/welcome"
                element={
                  <PrivateRoute>
                    <Navigate to="/" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/finetune"
                element={
                  <PrivateRoute>
                    <FineTune />
                  </PrivateRoute>
                }
              />
              <Route
                path="/packages"
                element={
                  <PrivateRoute>
                    <EndUserPackages />
                  </PrivateRoute>
                }
              />
              <Route
                path="/available-units-for-allocation"
                element={
                  <PrivateRoute>
                    <AllocationWrapper />
                  </PrivateRoute>
                }
              />
              <Route
                path="/purchase-section"
                element={
                  <PrivateRoute>
                    <PurchasePackSection />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-subscriptions"
                element={
                  <PrivateRoute>
                    <SubscriptionDetails />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<> not found</>} />
              <Route
                path="/view-reports"
                element={
                  <PrivateRoute>
                    <ViewReport />
                  </PrivateRoute>
                }
              />
              <Route
                path="/saved-reports"
                element={
                  <PrivateRoute>
                    <SavedReports />
                  </PrivateRoute>
                }
              />
              <Route
                path="/analyst-welcome"
                element={
                  <PrivateRoute>
                    <AnalystWelcome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/create-new-buyer-guide"
                element={
                  <PrivateRoute>
                    <CreateNewBuyerGuide />
                  </PrivateRoute>
                }
              />
              <Route
                path="/analysis"
                element={
                  <PrivateRoute>
                    <MainComponent />
                  </PrivateRoute>
                }
              />
              <Route
                path="/buyer-guide-menu"
                element={
                  <PrivateRoute>
                    <BuyerGuideMenu />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sub-use-cases"
                element={
                  <PrivateRoute>
                    <ReviewResultPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/report-settings"
                element={
                  <PrivateRoute>
                    <ReportSettings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/show-static-report"
                element={
                  <PrivateRoute>
                    <StaticReport />
                  </PrivateRoute>
                }
              />
              <Route
                path="/model-parameter"
                element={
                  <PrivateRoute>
                    <ModelAndParameter />
                  </PrivateRoute>
                }
              />
              <Route
                path="/usecase-to-feature"
                element={
                  <PrivateRoute>
                    <UsecaseToFeature />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vendor-offer-page"
                element={
                  <PrivateRoute>
                    <VendorOfferPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vendor-offer-menu"
                element={
                  <PrivateRoute>
                    <VendorOfferMenu />
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-new-offer"
                element={
                  <PrivateRoute>
                    <AddNewOffer />
                  </PrivateRoute>
                }
              />
              <Route
                path="/review-vendor-offer"
                element={
                  <PrivateRoute>
                    <ReviewVendorOfferPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mapping-type"
                element={
                  <PrivateRoute>
                    <MappingType />
                  </PrivateRoute>
                }
              />
              <Route
                path="/buyer-guide-mapping-one-to-one"
                element={
                  <PrivateRoute>
                    <BuyerGuideMappingOneToOne />
                  </PrivateRoute>
                }
              />
              {/* vendor-offer-page-mapping */}
              <Route
                path="/vendor-offer-page-mapping "
                element={
                  <PrivateRoute>
                    <VendorOfferMenuMapping />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vendor-offer-page-mapping"
                element={
                  <PrivateRoute>
                    <VendorOfferPageMapping />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vendor-offer-menu-mapping"
                element={
                  <PrivateRoute>
                    <VendorOfferMenuMapping />
                  </PrivateRoute>
                }
              />
              <Route
                path="/generated-features"
                element={
                  <PrivateRoute>
                    <GeneratedFeatures />
                  </PrivateRoute>
                }
              />
              <Route
                path="/overall-recommendations"
                element={
                  <PrivateRoute>
                    <MappingRecommendation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/usecase-recommendations"
                element={
                  <PrivateRoute>
                    <MappingRecommendation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/feature-recommendation"
                element={
                  <PrivateRoute>
                    <MappingRecommendation />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Layout>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login handleSignInRedirect={handleSignInRedirect} handleSignUpRedirect={handleSignUpRedirect} handleSignInRedirectForEmployee={handleSignInRedirectForEmployee} />
      </UnauthenticatedTemplate>
    </>
  );
};

function App({ msalInstance }) {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <UserProfileProvider>
           <StaticReportProvider>
            <Pages />
           </StaticReportProvider>
          </UserProfileProvider>
        </MsalProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
