import { Autocomplete, Box, Checkbox, Chip, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { AllocationContext } from "../AllocationContext";
import theme from "../../../theme/theme";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomButton from "../../../components/CustomButton";
import { limitAsPerPackage } from "../helper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Vendor() {
  const { vendorList, selctedBG, setVendorTab, currentBuyerGuidePackage,selectedVendors, setSelectedVendors } = useContext(AllocationContext);
  const [remainingVendor] = useState(limitAsPerPackage[currentBuyerGuidePackage]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", position: "relative", width: "100%", height: "100%", margin: "auto", backgroundColor: "#f5f5f5", borderRadius: 2, boxShadow: 2 }}>
      <Box sx={{ height: "100%", overflowY: "auto", paddingTop: "15px" }}>
        <Box sx={{ padding: 3, paddingTop: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              marginBottom: "10px",
              [theme.breakpoints.down("md")]: { flexDirection: "column", alignItems: "flex-start" },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", flex: 1, [theme.breakpoints.down("md")]: { width: "100%" } }}
            >
              Please Select Vendors for Below Buyer Guide: 
              <Box sx={{color:theme.palette.primary.main,fontSize:theme.typography.h4.fontSize, whiteSpace:"break-spaces"}}>
              {selctedBG?.title}
              </Box>
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              <Typography component="span" variant="inherit" style={{ fontWeight: "bold", marginRight: "5px" }}>
                Remaining Units :
              </Typography>
              {remainingVendor - selectedVendors.length}
            </Typography>
          </Box>
          <Autocomplete
            multiple
            id="checkboxes-tags"
            limitTags={2}
            options={vendorList}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            
            onChange={(e, value) => {
              setSelectedVendors(value.map((item) => item.id)); //set
            }}
            onScroll={(e) => {
              e.stopPropagation(); // Prevent scroll propagation to parent
            }}
            getOptionDisabled={(option) => {
              return selectedVendors.length === remainingVendor && !selectedVendors.includes(option.id);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={option.title}
                    size="large"
                    {...tagProps}
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: "#fff",
                      fontSize: "16px",
                      "& .MuiChip-deleteIcon": {
                        backgroundColor: "#ffffffff",
                        borderRadius: "50%",
                      },
                    }}
                  />
                );
              })
            }
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.title}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Select Vendors for `}
                placeholder="Search Vendor"
                sx={{
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      <CustomButton
        sx={{ m: 2, width: "120px", backgroundColor: theme.palette.primary.main }}
        text={"Allocate"}
        variant={"contained"}
        aria-label="vendor-allocate-button"
        onClick={() => {
          setVendorTab(false);
        }}
      />
    </Box>
  );
}
