import BuyerAndEUO from "./SpecificAllocation/BuyerAndEUO";
import UserUnits from "./SpecificAllocation/UserUnits";

//Radio Buttons All the data is stored here
const radioData = [
  {
    heading: "Users Units",
    option1: "self",
    text1: "Self (max 1 unit)",
    option2: "prescibe",
    text2: "Let me prescribe",
  },
  {
    heading: "End User Organizations Units",
    option1: "userDefined",
    text1: "User Defined",
    option2: "prescibe",
    text2: "Let me prescribe",
  },
  {
    heading: "Buyer Guide Units",
    option1: "userDefined",
    text1: "User Defined",
    option2: "prescibe",
    text2: "Let me prescribe",
  },
  {
    heading: "Vendor to be Unlocked",
    option1: "userDefined",
    text1: "User Defined",
    option2: "prescibe",
    text2: "Let me prescribe",
  },
];

const restrictUser = (pramas) => {
  if (pramas?.row?.allocationType === "UnPrescribed") {
    if (pramas?.field === "totalUnits") return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    else if (pramas?.field === "userUnitsTotal") {
      return !pramas?.row?.totalUnitsInput;
    } else if (pramas?.field === "endUserOrganizationUnitsTotal" || pramas?.field === "buyerGuideUnitsTotal") {
      return !pramas?.row?.userUnitsInput || !pramas?.row?.totalUnitsInput;
    }
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
  } else if (pramas?.row?.allocationType === "Fully Prescribed") {
    if (pramas?.field === "buyerGuideUnitsTotal") return false;
    else if (pramas?.field === "endUserOrganizationUnitsTotal") return false;
    else if (pramas?.field === "totalUnits") {
      return !pramas?.row?.buyerGuideUnitsInput || !pramas?.row?.endUserOrganizationUnitsInput;
    } else if (pramas?.field === "userUnitsTotal") {
      return (
        !pramas?.row?.buyerGuideUnitsInput || !pramas?.row?.endUserOrganizationUnitsInput || !pramas?.row?.totalUnitsInput // true if null or 0
      );
    }
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
  } else if (pramas?.row?.allocationType === "Partially Prescribed (UCBG)") {
    if (pramas?.field === "buyerGuideUnitsTotal") return false;
    else if (pramas?.field === "totalUnits") {
      return !pramas?.row?.buyerGuideUnitsInput;
    } else if (pramas?.field === "userUnitsTotal") {
      return (
        !pramas?.row?.buyerGuideUnitsInput || // true if null or 0
        !pramas?.row?.totalUnitsInput
      );
    } else if (pramas?.field === "endUserOrganizationUnitsTotal") {
      return (
        !pramas?.row?.buyerGuideUnitsInput || // true if null or 0
        !pramas?.row?.totalUnitsInput ||
        !pramas?.row?.userUnitsInput
      );
    }
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
  } else if (pramas?.row?.allocationType === "Partially Prescribed (EUO)") {
    if (pramas?.field === "endUserOrganizationUnitsTotal") return false;
    else if (pramas?.field === "totalUnits") {
      return !pramas?.row?.endUserOrganizationUnitsInput;
    } else if (pramas?.field === "userUnitsTotal") {
      return !pramas?.row?.totalUnitsInput || !pramas?.row?.endUserOrganizationUnitsInput;
    } else if (pramas?.field === "buyerGuideUnitsTotal") {
      return (
        !pramas?.row?.endUserOrganizationUnitsInput || // true if null or 0
        !pramas?.row?.totalUnitsInput ||
        !pramas?.row?.userUnitsInput
      );
    }
  }
  return true;
};

const euolist = [
  { title: "TCS - IT Services", id: 741 },
  { title: "Infosys - IT Services", id: 52 },
  { title: "Wipro - IT Services", id: 963 },
  { title: "Reliance - Retail Services", id: 759 },
  { title: "L&T - Engineering Services", id: 153 },
];
const buyerlist = [
  { title: "Cloud-Native Security Solutions", id: 123 },
  { title: "Software-Defined Networking (SDN)", id: 456 },
  { title: "Enterprise Resource Planning (ERP)", id: 789 },
  { title: "Data Encryption and Key Management", id: 159 },
  { title: "Log Management and Analysis", id: 753 },
  { title: "Endpoint Detection and Response (EDR)", id: 486 },
];
const popoverMap = {
  userUnitsTotal: <UserUnits />,
  ...Object.fromEntries(
    ["endUserOrganizationUnitsTotal", "buyerGuideUnitsTotal"].map((key) => [key, <BuyerAndEUO />]) //    this component is taking  (isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout) as prop
  ),
};

const titles = {
  userUnitsTotal: "User Units",
  endUserOrganizationUnitsTotal: "End User Organization",
  buyerGuideUnitsTotal: "Buyer Guide",
};
const unitCheck = (popoverContent, setAllocatedData, handleClose, allocatedData, setResponse, setPopup, selectedOption, setVendorPopup) => {
  if (popoverContent?.field === "userUnitsTotal") {
    return (
      <UserUnits
        id={popoverContent?.row?.id}
        setAllocatedData={setAllocatedData}
        maxFields={popoverContent.option}
        handleClose={handleClose}
        allocatedData={allocatedData}
        setResponse={setResponse}
        setPopup={setPopup}
        selectedOption={selectedOption}
      />
    );
  } else if (popoverContent?.field === "endUserOrganizationUnitsTotal") {
    return (
      <BuyerAndEUO
        id={popoverContent?.row?.id}
        setAllocatedData={setAllocatedData}
        quantity={popoverContent?.option}
        list={popoverContent?.row?.euoList}
        label={"End-User List"}
        handleClose={handleClose}
      />
    );
  } else if (popoverContent?.field === "buyerGuideUnitsTotal") {
    return (
      <BuyerAndEUO
        id={popoverContent?.row?.id}
        setAllocatedData={setAllocatedData}
        quantity={popoverContent?.option}
        list={popoverContent?.row?.buyerlist}
        label={"Buyer Guide list"}
        handleClose={handleClose}
      />
    );
  }
};

const allocationType = {
  UnPrescribed: "UnPrescribed",
  "Partially Prescribed (EUO)": "Partially Prescribed (End User Org.)",
  "Partially Prescribed (UCBG)": "Partially Prescribed (Buyer Guide)",
  "Fully Prescribed": "Fully Prescribed",
};

const limitAsPerPackage = {
  "All Available": 999,
  "Duel Dynamics": 2,
  "Focus Five": 5,
  "Solo Starter": 1,
};
const vendorData = [
  { id: 101, title: "Global Supplies Inc." },
  { id: 102, title: "Tech Solutions Ltd." },
  { id: 103, title: "Prime Distributors" },
  { id: 104, title: "Alpha Wholesale" },
  { id: 105, title: "Greenfield Partners" },
  { id: 106, title: "Citywide Traders" },
  { id: 107, title: "Blue Ocean Imports" },
  { id: 108, title: "Summit Industries" },
  { id: 109, title: "Northern Lights Co." },
  { id: 110, title: "Everest Enterprises" },
];

export { radioData, restrictUser, euolist, buyerlist, popoverMap, titles, unitCheck, allocationType, vendorData, limitAsPerPackage };
