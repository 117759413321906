import React from "react";
import { Box, Typography } from "@mui/material";
import PopupAction from "../../components/PopupAction";
import CustomButton from "../../components/CustomButton";
import PreviewAllocation from "./PreviewAllocation";
import Vendor from "./SpecificAllocation/Vendor";
import { titles, unitCheck } from "./helper";
import theme from "../../theme/theme";

export const Popups = ({
  popover,
  previewPopover,
  popup,
  vendorPopup,
  vendorTab,
  popoverContent,
  allocatedData,
  handelClose,
  handlePreviewClose,
  setAllocatedData,
  setResponse,
  setPopup,
  selectedOption,
  handleButtonClick,
  handelUserResponse,
  setVendorTab,
  handelAllocationOfPrescribedUnits,
  response,
}) => {
  return (
    <>
      {popover && (
        <PopupAction
          handleClose={handelClose}
          open={popover}
          title={titles[popoverContent?.field]}
          extraCss={popoverContent?.field?.includes("endUser") || popoverContent?.field?.includes("buyerGuide") ? { minHeight: "50%", width: "80%" } : { width: "50%" }}
        >
          {unitCheck(popoverContent, setAllocatedData, handelClose, allocatedData, setResponse, setPopup, selectedOption)}
        </PopupAction>
      )}
      {previewPopover && <PreviewAllocation open={previewPopover} handleClose={handlePreviewClose} data={allocatedData} allocateUnits={handelAllocationOfPrescribedUnits} />}

      <PopupAction open={popup} title={"Allocate Units to a New Email"} extraCss={{ width: "50%", height: "30%" }} showCross={false}>
        <Box style={{ textAlign: "center", padding: "10px", position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h6" gutterBottom>
            Email:
            <Typography variant="p" gutterBottom sx={{ color: "red", marginX: "5px" }}>
              '{response?.email}'
            </Typography>
            does not exist in our database, but you can still allocate units to this email. Would you like to proceed?
          </Typography>
          <Box sx={{ gap: "10px", position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center" }}>
            <CustomButton
              onClick={() => {
                handleButtonClick(true);
              }}
              sx={{ backgroundColor: theme.palette.primary.main }}
              text={"Yes"}
            />

            <CustomButton
              onClick={() => {
                handleButtonClick(false);
              }}
              sx={{ backgroundColor: theme.palette.secondary.main }}
              text={"No"}
            />
          </Box>
        </Box>
      </PopupAction>

      <PopupAction open={vendorPopup} title={"Vendor Allocation"} extraCss={{ width: "30%", height: "20%" }} showCross={false}>
        <Box style={{ textAlign: "center", padding: "10px", position: "relative", display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
          <Typography variant="h6" gutterBottom sx={{ width: "100%" }}>
            Do you want to allocate Vendors for this Buyer Guide ?
          </Typography>
          <Box sx={{ gap: "10px", position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center" }}>
            <CustomButton onClick={() => handelUserResponse(true)} sx={{ backgroundColor: theme.palette.primary.main,width:"120px" }} text={"Yes"} />
            <CustomButton onClick={() => handelUserResponse(false)} sx={{ backgroundColor: theme.palette.secondary.main,width:"120px" }} text={"No"} />
          </Box>
        </Box>
      </PopupAction>
      {vendorTab && (
        <PopupAction
          handleClose={() => {
            setVendorTab(false);
          }}
          open={popover}
          title={"Vendor Allocation"}
          extraCss={{ width: "50%" }}
        >
          <Vendor />
        </PopupAction>
      )}
    </>
  );
};
