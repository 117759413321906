import React, { useState, useContext, useEffect } from "react";
import { Typography, Box, Button, Chip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";

import { apiService, claimAllThePackages } from "../utils/utils";
import NestedRadio from "../components/NestedRadio";
import theme from "../theme/theme";

const UsePurchasedPackage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentOrganizationId, currentOrganization, buyerGuideName, userProfile, currentPackage, currentScenarioId, currentBuyerGuideId, setCurrentPackage, setNotification } =
    useContext(UserProfileContext);

  const [headerSelected, setHeaderSelected] = useState("Unlock Vendors");
  const [childSelected, setChildSelected] = useState("Based on Offer Metrics");
  const [newSinglePackage, setNewSinglePackage] = useState(() => {
    const storedPackage = localStorage.getItem("newSinglePackage");
    return storedPackage ? JSON.parse(storedPackage) : {}; // Return parsed object or default to an empty object
  });

  const radioData = [
    {
      header: "Unlock Vendors",
      options: ["Based on Offer Metrics", "Based on Vendor Names"],
    },
    {
      header: "Don't Unlock Vendors yet",
      options: ["Take me to 'Results' page", "Take me to 'My Subscriptions' page"],
    },
  ];

  const headerData = {
    "End-user Organisation" : userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Purchased Package": (
      // <>
      //   {currentPackage?.package_type}
      //   {newSinglePackage !== "" && !!newSinglePackage.packageType && (
      //     <sub> you have {newSinglePackage.packageType} to claim</sub>
      //   )}
      // </>

      <>
        <Box component={"span"} marginRight={1}>
          {currentPackage?.package_type}
        </Box>
        {newSinglePackage !== "" && (
          <Chip
            label={`${newSinglePackage?.packageType} to claim` || ""}
            size="small"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            }}
          />
        )}
      </>
    ),
    "Volume Unit": "1",
  };
  const combination = [
    {
      id: currentBuyerGuideId,
      PackageDetails: {
        PackageType: newSinglePackage?.packageType,
        Match: "NO",
      },
    },
  ];
  const handleNavigate = async () => {
    localStorage.removeItem("newSinglePackage");
    if (childSelected === "Based on Offer Metrics") {
      const response = await claimAllThePackages(userProfile?.email, currentOrganizationId, combination, "https://newtestfuncpython.azurewebsites.net/api/claimSinglePackage?");
      if (response) {
        setCurrentPackage({
          id: response?.[0]?.[currentBuyerGuideId]?.PackageID,
          package_type: response?.[0]?.[currentBuyerGuideId]?.PackageType,
        });
        localStorage.setItem(
          "currentPackage",
          JSON.stringify({
            id: response?.[0]?.[currentBuyerGuideId]?.PackageID,
            package_type: response?.[0]?.[currentBuyerGuideId]?.PackageType,
          })
        );
        !!currentScenarioId ? navigate("/results/comparative-analysis") : navigate("/select-scenario");
      }
    } else if (childSelected === "Based on Vendor Names") {
      const response = await claimAllThePackages(userProfile?.email, currentOrganizationId, combination, "https://newtestfuncpython.azurewebsites.net/api/claimSinglePackage?");
      if (response) {
        setCurrentPackage({
          id: response?.[0]?.[currentBuyerGuideId]?.PackageID,
          package_type: response?.[0]?.[currentBuyerGuideId]?.PackageType,
        });
        localStorage.setItem(
          "currentPackage",
          JSON.stringify({
            id: response?.[0]?.[currentBuyerGuideId]?.PackageID,
            package_type: response?.[0]?.[currentBuyerGuideId]?.PackageType,
          })
        );
        navigate("/edit-scenario/vendor-options");
      }
    } else if (childSelected === "Take me to 'Results' page") navigate("/results/comparative-analysis");
    else if (childSelected === "Take me to 'My Subscriptions' page") {
      navigate("/my-subscriptions");
    }
  };

  const createPurchasePackageNode = async (inputData) => {
    console.log(inputData);
    const response = await apiService(`https://newtestfuncpython.azurewebsites.net/api/orchestrators/DurableFunctionsOrchestrator1`, {
      method: "GET",
      data: {
        inputData,
      },
    });
    if (response?.statusQueryGetUri) {
      //fetching data from url got from durableFunctions
      const data = await apiService(response?.statusQueryGetUri, {
        method: "GET",
      });
      if (data) {
        //toast of success purchase
        data?.output?.messages?.forEach((msg) => {
          setNotification({
            open: true,
            message: msg,
            severity: "success",
          });
        });

        //packageType
        localStorage.setItem("newSinglePackage", JSON.stringify(response.packages[0]));
        setNewSinglePackage(data?.output?.createdPackage[0]);
      }
    }
  };
  function generatePackages(input, customerId) {
    const packageMap = {
      0: "Solo Starter",
      1: "Duel Dynamics",
      2: "Focus Five",
      3: "All Available",
    };

    const packages = [];

    // Iterate over the duration object keys (0 for Monthly, 1 for Yearly)
    Object.keys(input?.duration).forEach((durationKey) => {
      let duration = "";
      if (input?.duration?.[durationKey]?.length > 0) {
        duration = +durationKey === 0 ? "Monthly" : "Yearly";
      }

      const selectedPackages = input?.duration?.[durationKey];

      selectedPackages?.forEach((packageNumber) => {
        const packageType = packageMap[packageNumber];

        // Determine the number of subscriptionIDs needed
        const packageQuantity = input?.volumeUnitsByPackage?.[packageNumber];

        const packageDetail = {
          packageType: packageType,
          customerId: customerId,
          packageQuantity: packageQuantity,
          subscriptionDuration: duration,
        };

        packages.push(packageDetail);
      });
    });

    return packages;
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // const sessionId = queryParams.get("session_id");
    const customerId = queryParams.get("customer_id");
    const packageDetailsStr = queryParams.get("packageDetails");
    const productsForSubStr = queryParams.get("productsForSub");
    let packageDetails;
    let products;
    if (packageDetailsStr && productsForSubStr) {
      packageDetails = JSON.parse(decodeURIComponent(packageDetailsStr));
      products = JSON.parse(decodeURIComponent(productsForSubStr));
    }

    if (customerId && packageDetails && products) {
      // console.log("customerId", customerId);
      // console.log("packageDetails", packageDetails);
      const packages = generatePackages(packageDetails, customerId);

      const inputData = {
        packages,
        customerId,
        products,
        email: userProfile?.email,
      };

      createPurchasePackageNode(inputData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box>
      <HeaderNaming data={headerData} />
      <Typography>
        Let's use the recentely purchased (
        <Typography
          sx={{
            color: theme.palette.secondary.main,
            font: theme.typography.fontFamily,
            display: "inline-flex",
          }}
        >
          {newSinglePackage?.packageType}
        </Typography>
        ) package to "unlock" vendors and their respective offers and, see complete analysis.
      </Typography>

      <Box>
        <NestedRadio setHeaderSelected={setHeaderSelected} headerSelected={headerSelected} setChildSelected={setChildSelected} childSelected={childSelected} radioData={radioData} />
      </Box>
      <Box>
        {headerSelected === "Unlock Vendors" && (
          <Button variant="contained" color="primary" onClick={handleNavigate} sx={{ marginTop: "16px", width: "250px" }}>
            Claim & Proceed to Unlock
          </Button>
        )}
        {headerSelected === "Don't Unlock Vendors yet" && (
          <Button variant="contained" color="secondary" onClick={handleNavigate} sx={{ marginTop: "16px", width: "250px" }}>
            Exit to selected page
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UsePurchasedPackage;

// https://newtestfuncpython.azurewebsites.net/runtime/webhooks/durabletask/instances/db79e7e793d4462488e634c55b875191?taskHub=newTestFuncPython&connection=Storage&code=MrFoXBDrowU6bDjcfgE9vj7D5Iv-2v_OQBlR-qFhntQTAzFup6rMDA==

/**
 * "messages": [
    "Purchase Package for Solo Starter of quantity 2 purchased successfully!",
    "Purchase Package for Duel Dynamics of quantity 2 purchased successfully!"
   ],
   "createdPackageIds": [
     "All Available"
   ]
 */
