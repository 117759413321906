import React, { createContext, useState } from "react";
import { vendorData } from "./helper";

export const AllocationContext = createContext();

export const AllocationProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [popover, setPopover] = useState(false);
  const [previewPopover, setPreviewPopover] = useState(false);
  const [currentBuyerGuidePackage, setCurrentBuyerGuidePackage] = useState(""); //to check which buyergide packagek package
  const [popoverContent, setPopoverContent] = useState({});
  const [allocatedData, setAllocatedData] = useState([]); // data that is going to be send as data in Allocation API
  const [popup, setPopup] = useState(false);
  const [vendorPopup, setVendorPopup] = useState(false); //state for popup for asking to allocate vendor
  const [vendorList, setVendorList] = useState([]); //list of vendor got from API call
  const [vendorTab, setVendorTab] = useState(false); // state for showing vendor tab if user response in positive
  const [selctedBG, setSelctedBG] = useState({}); //seelcted buyer guide ID
  const [selectedVendors, setSelectedVendors] = useState([]);

  const handelVendorSelection = (option = {}) => {
    setSelctedBG(option);
    setVendorPopup(false);
  };
  const handelUserResponse = (decision) => {
    if (decision) {
      setVendorPopup(false);
      //instead of timeout call API here to fetch vendor of particular bgID
      setTimeout(() => {
        setVendorList(vendorData);
      }, 2000);
      setVendorTab(true);
    }
    setVendorPopup(false);
  };

  const value = {
    loading,
    setLoading,
    sectionData,
    setSectionData,
    popover,
    setPopover,
    previewPopover,
    setPreviewPopover,
    popoverContent,
    setPopoverContent,
    allocatedData,
    setAllocatedData,
    popup,
    setPopup,
    vendorPopup,
    setVendorPopup,
    vendorList,
    setVendorList,
    handelVendorSelection,
    vendorTab,
    handelUserResponse,
    currentBuyerGuidePackage,
    setCurrentBuyerGuidePackage,
    setVendorTab,
    selctedBG,
    selectedVendors,
    setSelectedVendors,
  };

  return <AllocationContext.Provider value={value}>{children}</AllocationContext.Provider>;
};
