import { Box } from "@mui/material";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { apiService } from "../../../utils/utils";
import { UserProfileContext } from "../../../context/UserContext";
const convertToHTML = (text) => {
    const normalizedText = (text || "").toString().replace(/\r\n/g, "\n");
    return normalizedText.split("\n\n").map((paragraph, index) => (
      <p key={index}>
        {paragraph.split("\n").map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    ));
  };
const quadrant = ["SmartValyou", "PremiumValyou", "BudgetValyou", "LowValyou"];
function ExecutiveCommentry({ commentary, executiveSummarydetail, setExecutiveSummary,generateReport }) {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {
    currentOrg,
    buyerGuideName,
    scenarioName,
    userProfile,
    currentScenarioId,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage,
  } = useContext(UserProfileContext);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      let quadrantSummaries = quadrant.map((section) => {
        if (!commentary[section]?.quadrantSummary?.isLoading) {
          return commentary[section]?.quadrantSummary?.commentary;
        }
        return null;
      });
      quadrantSummaries = quadrantSummaries.filter((summary) => {
        if (summary) {
          return true;
        }
        return false;
      });

      if (quadrantSummaries.length > 0) {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          quadrantAnalyses: quadrantSummaries,
          comparativeOverallSummary:
            commentary?.ComparativeSummary?.sectionSummary?.commentary,
        };
        const endPoint = generateReport
          ? "generateExecutiveSummary"
          : "getExecutiveSummary";
        await apiService(
          `https://newtestfuncpython.azurewebsites.net/api/${endPoint}?`,
          {
            method: "POST",
            data: payload,
          },
          setLoading,
          (response) => {
            console.log("API response:", response);
            setExecutiveSummary((prev) => ({
              ...prev,
              commentary: response?.executiveSummary,
          
              isLoading: false,
            }));
            localStorage.setItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-ExecutiveSummary`,
              JSON.stringify(response)
            );
          },
          setError
        );
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  }, [
  
    currentOrg,
    scenarioName,
    buyerGuideName,
    commentary,
    setExecutiveSummary,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage.id,
    currentScenarioId,
    userProfile,
    generateReport,
  ]);
  useEffect(() => {
    const executiveSummary = JSON.parse(
      localStorage.getItem(
        `${currentOrg}-${buyerGuideName}-${scenarioName}-ExecutiveSummary`
      )
    );
    if (!executiveSummary?.executiveSummary) {
      fetchData();
    } else {
      setExecutiveSummary((prev) => ({
        ...prev,
        commentary: executiveSummary?.executiveSummary,
        generateCount:executiveSummary?.maxGenerationLimit-executiveSummary?.currentGenerationCount,
        isLoading: false,
      }));
      setLoading(false);
    }
  }, [
    fetchData,
    buyerGuideName,
    currentOrg,
    scenarioName,
    setExecutiveSummary,
  ]);
  if (error) {
    console.log(error);
  }
  return (
    <Box>
      {loading ? "...Loading" : convertToHTML(executiveSummarydetail?.commentary )}
    </Box>
  );
}

export default ExecutiveCommentry;
