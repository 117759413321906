import React, { useState } from "react";
import { Box, Typography, List, ListItem, ListItemText, IconButton } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";

const ListToggleWidget = React.forwardRef(({ name, items = [], count = 0 }, ref) => {
  const [viewMode, setViewMode] = useState("both");

  // Toggle view mode handler
  const handleToggleView = (mode) => setViewMode(mode);

  // Conditional rendering of view mode
  const renderContent = () => {
    switch (viewMode) {
      case "count":
        return (
          <Typography data-test-id={`count-${name}`} variant="h6" sx={{ widoth: "100%" }}>
            Count: {count}
          </Typography>
        );
      case "list":
        return (
          <List data-test-id={`list-${name}`}>
            {items.map((item, index) => (
              <ListItem key={index} sx={{ padding: "0px" }}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        );
      case "both":
        return (
          <>
            <Typography data-test-id={`count-${name}`} variant="h6">
              Count: {count}
            </Typography>
            <List style={{ padding: "0px" }} data-test-id={`list-${name}`}>
              {items.map((item, index) => (
                <ListItem style={{ padding: "0px" }} key={index}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        // gap: "8px",
        height: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "50px",
          padding: "0 16px",
        }}
      >
        <IconButton
          data-test-id={`viewmodule-${name}`}
          color={viewMode === "count" ? "primary" : "default"}
          onClick={() => handleToggleView("count")}
        >
          <ViewModuleIcon />
        </IconButton>

        <IconButton
          data-test-id={`viewlist-${name}`}
          color={viewMode === "list" ? "primary" : "default"}
          onClick={() => handleToggleView("list")}
        >
          <ViewListIcon />
        </IconButton>

        <IconButton
          data-test-id={`viewcomfy-${name}`}
          color={viewMode === "both" ? "primary" : "default"}
          onClick={() => handleToggleView("both")}
        >
          <ViewComfyIcon />
        </IconButton>
      </Box>
      {renderContent()}
    </Box>
  );
});

export default ListToggleWidget;
