import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Checkbox } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { apiService, claimAllThePackages } from "../utils/utils";
import { UserProfileContext } from "../context/UserContext";
import theme from "../theme/theme.js";

import HeaderNaming from "../components/HeaderNaming/HeaderNaming.jsx";
import DataGridTable from "../components/DataGridTable.jsx";
import DropdownSelect from "../components/InterestedBuyer/DropdownSelect.jsx";
import CustomName from "../components/InterestedBuyer/CustomName.jsx";
import "./GridLayout/GridComponent.css";
import Loader from "../components/Loader.jsx";

import NoDataAvailable from "../components/NoDataAvilable.jsx";
const InterestedBuyer = () => {
  const navigate = useNavigate();

  // Extracting data and setters from UserProfileContext for user and organization-related info.
  const {
    userProfile,
    currentOrganization,
    interestedBuyerGuides,
    setBuyerGuideName,
    currentOrganizationId,
    setNotification,
    currentPackage,
    setCurrentPackage,
    fetchInterestedBuyerGuides,
    currentOrg,
    setCurrentBuyerGuideId,
  } = useContext(UserProfileContext);

  const [loading, setLoading] = useState(true); // Add loading state

  // // Local state to manage the data and UI interaction.
  const [sectionData, setSectionData] = useState([]);
  const [errorToShow, setErrorToShow] = useState([]); // Show Error in respected rows
  const [guideCheck, setGuideCheck] = useState([]); // Selection of Guides in "Checkbox" and "Purchase Package Claimed" column
  const [allGuidesCheck, setAllGuidesCheck] = useState(false);
  const [quantities, setRemainingQuantities] = useState({
    NO: {},
    EUO: {},
  });
  const [selectedPackages, setSelectedPackages] = useState({});

  const countChange = (data, rowId) => {
    const { PackageType: selectedValue, Match: matchType } = data;

    // Previous selection in this row for the match type
    const prevSelectedValue = selectedPackages[rowId]?.[matchType];

    if (prevSelectedValue === selectedValue) return;
    // Only increase the quantity of the previously selected package if it exists and differs from the current selection
    if (prevSelectedValue && prevSelectedValue !== selectedValue) {
      setRemainingQuantities((prev) => ({
        ...prev,
        [matchType]: {
          ...prev[matchType],
          [prevSelectedValue]: prev[matchType][prevSelectedValue] + 1, // Increase quantity of previously selected package
        },
      }));
    }

    // Decrease the quantity of the newly selected package
    setRemainingQuantities((prev) => ({
      ...prev,
      [matchType]: {
        ...prev[matchType],
        [selectedValue]: prev[matchType][selectedValue] - 1, // Decrease the quantity of the newly selected package
      },
    }));

    // Update the selected package for this row
    setSelectedPackages((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [matchType]: selectedValue,
      },
    }));
  };

  // Controls visibility of certain columns (e.g. Purchased Package Claimed column).
  const [purchasedPackageClaimedColumnToShow, setPurchasedPackageClaimedColumnToShow] = useState(false); // Enable/Disable Columns
  const [checkboxColumnToShow, setCheckboxColumnToShow] = useState(false); // Enable/Disable Buttons

  // Manage the enabling/disabling of action buttons.
  const [claimPurchasedPackage, setClaimPurchasedPackage] = useState(false); // 'Claim Purchased Package' and 'Show Available Buyer Guides'
  const [noLongerInterested, setNoLongerInterested] = useState(false); // No Longer Intrested state

  // Prepare data for the header component.
  const data = {
    "End-user Organisation" : userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
  };

  useEffect(() => {
    console.log(interestedBuyerGuides);
    const result = {
      NO: {},
      EUO: {},
    };
    let processedFirstObject = false;

    // Use forEach instead of map, since we are not returning anything
    interestedBuyerGuides &&
      interestedBuyerGuides.forEach((item) => {
        if (processedFirstObject) return;

        // Iterate over MatchDetails array
        item?.MatchDetails?.forEach(({ PackageType, Quantity, Match }) => {
          // Only process if Match is present and is "EUO" or "NO"
          if ((Match && Match === "EUO") || Match === "NO") {
            if (!result[Match]) {
              result[Match] = {}; // Initialize if not present
            }
            // Accumulate quantities
            result[Match][PackageType] = (result[Match][PackageType] || 0) + Quantity;
            processedFirstObject = true; // Mark the first object as processed
            return; // Stop iterating over MatchDetails
          }
        });
      });

    setRemainingQuantities(result);
  }, [interestedBuyerGuides]);

  // Effect to map guides and prepare section data when interestedBuyerGuides is updated.
  useEffect(() => {
    setLoading(true);
    if (Array.isArray(interestedBuyerGuides) && interestedBuyerGuides?.length > 0) {
      // console.log("interestedBuyerGuide", interestedBuyerGuides);
      const sectionDataForPage = interestedBuyerGuides?.map((guide, index) => ({
        id: guide.id,
        rowId: index,
        name: guide.name,
        status: guide.status,
        action: guide.action ? guide.action : [],
        purchasedPackageClaimed: {
          id: guide.id,
          selected: "None",
          isClaimed: guide?.is_Claimed,
          options: guide.MatchDetails,
        },
      }));
      setSectionData(sectionDataForPage);
      setLoading(false); // Stop loading once data is ready.
    } else {
      setSectionData([]);
      setLoading(false); // Stop loading once data is ready.
    }
  }, [interestedBuyerGuides]);

  // Effect to determine which columns (checkbox, package claimed) to display based on data.
  useEffect(() => {
    setPurchasedPackageClaimedColumnToShow(sectionData?.some((guide) => guide.purchasedPackageClaimed?.options !== null));
    setCheckboxColumnToShow(
      sectionData?.some((guide) => guide.purchasedPackageClaimed?.options === null || (guide.purchasedPackageClaimed?.options?.length > 0 && guide.purchasedPackageClaimed.isClaimed === false))
    );

    // Update the state of "Select All" checkbox and action buttons based on selection and validity.
    const allChecked =
      sectionData?.length > 0 &&
      sectionData.every((guide) => guideCheck?.includes(guide.id) || (guide.purchasedPackageClaimed?.options?.length > 0 && guide.purchasedPackageClaimed.isClaimed === true));

    setAllGuidesCheck(allChecked && guideCheck?.length > 0);

    // Check if any selected guide can claim a valid package, updating the state for "Claim Purchased Package" button.
    let claimVaid = sectionData?.some((guide) =>
      guideCheck?.some(
        (id) => id === guide.id && guide.purchasedPackageClaimed?.selected !== "None" && guide.purchasedPackageClaimed.isClaimed === false && guide.purchasedPackageClaimed?.options !== null
      )
    );

    setClaimPurchasedPackage(guideCheck?.length > 0 && claimVaid); // to toggle between "Claim Purchase Package" and "Show Available Buyer Guides" buttons

    // Check if a guide package has a "PERFECT Match" and update state for "No Longer Interested" button.
    const isfixed = sectionData?.some((guide) =>
      guideCheck?.some(
        (id) => id === guide.id && guide.purchasedPackageClaimed?.options && guide.purchasedPackageClaimed?.isClaimed === false && guide.purchasedPackageClaimed?.options[0]?.Match === "PERFECT"
      )
    );
    setNoLongerInterested(guideCheck.length > 0 && !isfixed); // to Hide or Show "No Longer Interested Button"
  }, [sectionData, guideCheck]);

  // Effect to handle adding errors to sectionData when invalid packages are selected.
  useEffect(() => {
    if (errorToShow?.length > 0) {
      const errorIds = errorToShow?.map((pack) => pack.id);

      const latestSectionData = sectionData?.map((guide) => {
        if (!errorIds?.includes(guide.id)) {
          return guide;
        } else {
          let newGuide = guide;
          newGuide.purchasedPackageClaimed.error = true;
          return newGuide;
        }
      });
      setSectionData(latestSectionData);
    }
  }, [errorToShow, guideCheck, sectionData]);

  // Effect to remove errors when guides are unchecked or a valid package is selected.
  useEffect(() => {
    if (errorToShow?.length > 0) {
      const errorIds = errorToShow?.map((packag) => packag.id);

      // Checking for checkbox if it got unchecked to remove the error logo
      const errorIdsToBeRemoved = errorIds?.filter((errorId) => {
        if (!guideCheck?.includes(errorId)) return true;

        return sectionData?.some((guide) => {
          return guide.id === errorId && (guide.purchasedPackageClaimed?.options === null || guide.purchasedPackageClaimed?.selected === "None");
        });
      });

      const latestSectionData = sectionData?.map((guide) => {
        if (!errorIdsToBeRemoved?.includes(guide.id)) {
          return guide;
        } else {
          let newGuide = guide;
          delete newGuide.purchasedPackageClaimed.error;
          return newGuide;
        }
      });
      setSectionData(latestSectionData);
      setErrorToShow(
        errorToShow?.filter((pakag) => {
          if (!errorIdsToBeRemoved?.includes(pakag.id)) return true;
          else return false;
        })
      );
    }
  }, [guideCheck, sectionData, errorToShow]);

  // Handles the checkbox selection for individual guides.
  const handleCheckboxChange = (id) => {
    setGuideCheck((prevState) => (prevState?.includes(id) ? prevState?.filter((guideId) => guideId !== id) : [...prevState, id]));
  };

  // Handles the "Select All" checkbox functionality.
  const handleSelectAllChange = () => {
    if (allGuidesCheck) {
      setGuideCheck([]);
    } else {
      const updatedSectionData = sectionData?.filter(
        (guide) => (guide?.purchasedPackageClaimed?.options?.length > 0 && guide.purchasedPackageClaimed.isClaimed === false) || guide?.purchasedPackageClaimed?.options === null
      );
      setGuideCheck(updatedSectionData?.map((guide) => guide.id));
    }
    setAllGuidesCheck(!allGuidesCheck);
  };

  // Returns the selection status of guides, useful for submitting data.
  const returnSelectedGuides = () => {
    let interested_tab_checkbox_states = {};
    for (let val of interestedBuyerGuides) {
      interested_tab_checkbox_states[val.id] = guideCheck?.includes(val.id);
    }
    return interested_tab_checkbox_states;
  };

  // Handles the main action button click for showing available guides or claiming packages.
  const handleShowAvailable = async () => {
    if (!claimPurchasedPackage) navigate("/select-buyer-guide/available-buyer-guides");
    else {
      const guidesNotToBeSelected = sectionData?.filter((guide) => {
        if (!guideCheck?.includes(guide.id)) return false;
        else return guide.purchasedPackageClaimed?.selected === "None";
      });
      if (guidesNotToBeSelected.length === 0) {
        const email = userProfile?.email;

        const currentOrganisation = currentOrganizationId;

        const combination = guideCheck?.map((id) => {
          const currentGuide = sectionData?.find((guide) => guide?.id === id);

          const latestDetails = {
            PackageType: currentGuide?.purchasedPackageClaimed?.selected?.PackageType,
            Match: currentGuide?.purchasedPackageClaimed?.selected?.Match,
          };

          return {
            id,
            PackageDetails: latestDetails,
          };
        });

        //claim package api -------------------------------------------
        const response = await claimAllThePackages(email, currentOrganisation, combination, "https://newtestfuncpython.azurewebsites.net/api/claimPurchasedPackage?");
        if (response) {
          const key = Object.keys(response?.message || {})[0]; // Get the first key (either 'error' or 'success')
          const value = response?.message[key]; // Get the value associated with the key
          setNotification({
            open: true,
            message: value,
            severity: key,
          });
          setLoading(true);
          await fetchInterestedBuyerGuides();
          setGuideCheck([]);
          setLoading(false);
        }
      } else {
        setErrorToShow(guidesNotToBeSelected);
      }
    }
  };

  // Snackbar for showing notifications on certain actions.
  const handleSendBack = async () => {
    await apiService("https://newtestfuncpython.azurewebsites.net/api/notInterestedBuyerGuides?", {
      method: "POST",
      data: {
        email: userProfile.email,
        currentOrganisation: currentOrg,
        interested_tab_checkbox_states: returnSelectedGuides(),
      },
    });
    // setSectionData((prevData) => prevData?.filter((guide) => !guideCheck?.includes(guide.id)));
    setLoading(true);
    await fetchInterestedBuyerGuides();
    setLoading(false);

    setGuideCheck([]); // Reset selection
    setAllGuidesCheck(false); // Uncheck "Select All"
  };

  const createNonePackageNode = async (params) => {
    const data = await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/createNonePurchasePackage",
      {
        method: "POST",
        data: {
          email: userProfile.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: params?.id,
        },
      },
      null,
      setCurrentPackage,
      (err) => {
        console.log(err);
      }
    );

    if (data) {
      console.log(data);
      localStorage.setItem("currentPackage", JSON.stringify(data));
      localStorage.setItem("packageType", JSON.stringify(data?.package_type));
    }
  };

  const handleRowClick = (params) => {
    if (params?.value === "Start Analysis" && !params.row.purchasedPackageClaimed.isClaimed) {
      createNonePackageNode(params);
    } else if (params?.row?.purchasedPackageClaimed?.isClaimed) {
      setCurrentPackage({
        package_type: params.row.purchasedPackageClaimed.options[0].PackageType,
        id: params.row.purchasedPackageClaimed.options[0].currentPackage,
      });
      localStorage.setItem(
        "currentPackage",
        JSON.stringify({
          id: params.row.purchasedPackageClaimed.options[0].currentPackage,
          package_type: params.row.purchasedPackageClaimed.options[0].PackageType,
        })
      );
      localStorage.setItem("packageType", JSON.stringify(params.row.purchasedPackageClaimed.options[0].PackageType));
    }

    setCurrentBuyerGuideId(params.row.id);
    setBuyerGuideName(params.row.name);
    localStorage.setItem("buyerGuideName", params.row.name); // NEED THIS
    localStorage.setItem("currentBuyerGuideId", params.row.id);
    navigate("/select-scenario", {
      state: {
        currentPackage,
      },
    });
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 90,
      sortable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 100,
      flex: 0.5,
      renderHeader: () => <Checkbox checked={allGuidesCheck} onChange={handleSelectAllChange} color="secondary" sx={{ color: "white" }} />,

      renderCell: (params) => {
        return (
          ((params.row?.purchasedPackageClaimed?.options?.length > 0 && params.row?.purchasedPackageClaimed.isClaimed === false) || params.row?.purchasedPackageClaimed?.options === null) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Checkbox checked={guideCheck?.includes(params.row.id)} onChange={() => handleCheckboxChange(params.row.id)} color="secondary" />
            </Box>
          )
        );
      },
    },
    {
      field: "name",
      headerName: "Buyer Guide Name",
      headerClassName: "super-app-theme--header",
      display: "flex",
      headerAlign: "center",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => <CustomName data-test-id="name" name={params.value} isError={params.row?.purchasedPackageClaimed?.error ? true : false} />,
    },

    {
      field: "purchasedPackageClaimed",
      headerName: "Purchased Package Claimed",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        // console.log(params);
        return (
          <DropdownSelect
            quantities={quantities}
            countChange={countChange}
            rowId={params.row.rowId}
            purchasedPackageClaimed={params.value}
            onSet={() => {
              setErrorToShow((prevErrorToShow) =>
                prevErrorToShow?.filter((packag) => {
                  return packag.purchasedPackageClaimed.id !== params?.value?.id;
                })
              );

              sectionData.forEach((el) => {
                if (el.purchasedPackageClaimed.id === params?.value?.id && params.value.selected !== "None") if (!guideCheck?.includes(el.id)) handleCheckboxChange(el.id);
              });

              setSectionData(
                sectionData?.map((el) => {
                  if (el.purchasedPackageClaimed.id === params?.value?.id) return { ...el, purchasedPackageClaimed: params.value };
                  return el;
                })
              );
            }}
          ></DropdownSelect>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Button data-test-id="start-analysis" sx={{ width: { md: "250px" } }} variant="contained" color="secondary" onClick={() => handleRowClick(params)}>
            {params.value}
          </Button>
        </Box>
      ),
    },
  ];

  // it decides whether to show dropdown and checkbox column

  let updatedColumns = columns;
  if (!purchasedPackageClaimedColumnToShow && !checkboxColumnToShow) updatedColumns = columns?.filter((el) => el.field !== "purchasedPackageClaimed" && el.field !== "checkbox");
  else if (!purchasedPackageClaimedColumnToShow) updatedColumns = columns?.filter((el) => el.field !== "purchasedPackageClaimed");
  else if (!checkboxColumnToShow) updatedColumns = columns?.filter((el) => el.field !== "checkbox");

  if (loading) {
    return (
      // <Box mt={4} textAlign="center">
      //   <CircularProgress />
      // </Box>
      <Loader data-testid="loading" open={loading} />
    );
  }

  return sectionData.length > 0 ? (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: 4,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderNaming data={data} />
        <Box
          className="thinner-scrollbar"
          sx={{
            outline: "0.1px solid gray",
            width: "100%",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <DataGridTable
            rows={sectionData}
            columns={updatedColumns}
            disableSelectionOnClick
            hideFooter
            autoHeight
            onRowClick={handleRowClick} // Added onRowClick event
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
                whiteSpace: "normal",
                wordWrap: "break-word",
                borderBottom: "none", // Remove the bottom border
              },
              "& .MuiDataGrid-cell": {
                wordWrap: "break-word",
                whiteSpace: "normal",
              },
              "& .MuiDataGrid-cell--editing": {
                backgroundColor: "transparent !important",
                border: "3px solid #000", // Thicker border when in edit mode
              },
              "& .MuiDataGrid-cell--editable:hover": {
                border: "2px solid #fff", // Thicker border when editable cell is hovered
              },
            }}
          />
        </Box>
        {errorToShow?.length > 0 && <span style={{ color: "red", marginTop: "16px", marginLeft: "8px" }}>Please Select Valid Package for the Claim</span>}
        {noLongerInterested && (
          <Button data-testid="No Longer Interested" variant="contained" color="secondary" sx={{ marginTop: "16px", width: "250px" }} onClick={handleSendBack}>
            No Longer Interested
          </Button>
        )}
        <Button data-test-id="Show Available Buyer Guides" variant="contained" color="primary" sx={{ marginTop: "16px", width: "250px" }} onClick={handleShowAvailable}>
          {claimPurchasedPackage ? "Claim Purchased Package" : "Show Available Buyer Guides"}
        </Button>
      </Box>
    </>
  ) : (
    <NoDataAvailable />
  );
};

export default InterestedBuyer;
