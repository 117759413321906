import React, { useContext } from "react";
import { Modal, Box, TextField, Button, Typography, CircularProgress } from "@mui/material";
import { UserProfileContext } from "../context/UserContext";

const NewScenarioModal = ({ open, handleClose, handleSubmit, title, label, value, setValue, isAdding }) => {
  const { setNotification } = useContext(UserProfileContext);
  const onSubmit = () => {
    handleSubmit();
  };

  return (
    <Modal
      closeAfterTransition
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        data-test-id="create-scenario-box"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <TextField data-test-id="scenario-name" fullWidth label={label} value={value} onChange={(e) => setValue(e.target.value)} margin="normal" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!value) {
                setNotification({
                  message: "Please Enter Scenario Name",
                  severity: "warning",
                  open: true,
                });
              } else {
                onSubmit();
              }
            }}
            data-test-id="submit-button"
          >
            {isAdding ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewScenarioModal;
