import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserProfileContext } from "../context/UserContext";
import {
  Box,
  Typography,
  Button,
  Container,
  // CircularProgress,
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../theme/theme";
import Loader from "../components/Loader";

const VendorOfferPageMapping = () => {
  const [selectedVendor, setSelectedVendor] = useState(null); // Vendor selection state
  const [vendors, setVendors] = useState([]); // List of vendors
  const [loading, setLoading] = useState(true); // Loading state for data
  const [openDialog, setOpenDialog] = useState(false); // Dialog state
  const [newVendorName, setNewVendorName] = useState(""); // State for new vendor name
  const location = useLocation();
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const userEmail = userProfile.email; // Replace with dynamic user email if available

  // Clear selected vendor ID from localStorage on initial load to prevent automatic selection
  // useEffect(() => {
  //   localStorage.removeItem('currentVendorId'); // Clear local storage on page load
  // }, []);

  // Fetch updated vendors list after creating a new vendor or on initial page load
  const fetchVendorData = useCallback(async () => {
    const url = "https://newtestfuncpython.azurewebsites.net/api/displayVendorForAnalyst";
    try {
      const response = await axios.post(url, { email: userEmail });
      const vendors = response.data;
      setVendors(vendors);
    } catch (error) {
      console.error("Failed to fetch vendor data:", error);
    }
  }, [userEmail]); // Dependency array includes userEmail

  // Load vendors from location.state or fetch from backend if not available
  useEffect(() => {
    if (location.state && location.state.vendors) {
      const processedVendors = location.state.vendors.map((vendor) => ({
        id: vendor.vendorId, // Assign vendorId to id for each row
        vendorName: vendor.vendorName, // Ensure vendorName is available
        ...vendor,
      }));
      setVendors(processedVendors);
    } else {
      fetchVendorData();
    }
    setLoading(false);
  }, [location.state, fetchVendorData]); // Dependency array includes fetchVendorData

  // Handle vendor selection (toggle behavior)
  const handleRadioClick = (id) => {
    const newSelectedVendor = id === selectedVendor ? null : id;
    setSelectedVendor(newSelectedVendor);

    // Store the selected vendor ID in localStorage for use on next pages, but only if a vendor is selected
    if (newSelectedVendor) {
      const selectedVendorData = vendors.find((vendor) => vendor?.vendorId === newSelectedVendor);

      const currentVendorId = selectedVendorData ? selectedVendorData?.vendorId : null;
      localStorage.setItem("currentVendorData", JSON.stringify(selectedVendorData));
      localStorage.setItem("currentVendorId", currentVendorId);
    } else {
      localStorage.removeItem("currentVendorId"); // Remove from localStorage if deselected
    }
  };

  // Fetch offer data based on selected vendor
  const fetchOffersData = async () => {
    const storedVendorId = localStorage.getItem("currentVendorId");
    console.log("Stored vendor id-->", storedVendorId);

    const currentVendorId = storedVendorId || selectedVendor;
    console.log("current vendor id-->", storedVendorId);

    if (currentVendorId) {
      try {
        const response = await axios.post(
          "https://newtestfuncpython.azurewebsites.net/api/displayOfferForAnalyst",
          {
            email: userEmail,
            currentVendorId: currentVendorId,
          }
        );

        navigate("/vendor-offer-menu-mapping", {
          state: { offers: response.data },
        });
      } catch (error) {
        console.error("Failed to fetch offers:", error);
      }
    } else {
      navigate("/vendor-offer-menu-mapping");
    }
  };

  // Handle dialog open and close
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  // Handle creating a new vendor
  const handleCreateVendor = async () => {
    try {
      const response = await axios.post(
        "https://newtestfuncpython.azurewebsites.net/api/addNewVendor",
        {
          email: userEmail,
          newVendorName: newVendorName,
        }
      );

      const { vendorId } = response?.data;
      setOpenDialog(false); // Close the dialog box

      // Update vendors with the newly created vendor
      const newVendor = { vendorId, vendorName: newVendorName };
      setVendors((prevVendors) => [...prevVendors, newVendor]);

      // Set the new vendor as selected and store it in localStorage
      setSelectedVendor(vendorId);
      localStorage.setItem("currentVendorId", vendorId);

      // Refetch vendors to get updated data (if needed)
      fetchVendorData();
    } catch (error) {
      console.error("Failed to create vendor:", error);
    }
  };

  // Define the columns for the DataGrid
  const columns = [
    {
      field: "select",
      headerName: "Select",
      width: 150,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Radio
              checked={selectedVendor === params.id}
              onChange={() => handleRadioClick(params.id)} // Update selection
              value={params.id}
              onClick={(e) => e.stopPropagation()} // Prevent row click event
            />
          }
          label=""
        />
      ),
      headerClassName: "super-app-theme--header", // Apply custom header class
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  if (loading) {
    return (
      // <Box
      //   display="flex"
      //   justifyContent="center"
      //   alignItems="center"
      //   height="100vh"
      // >
      //   <CircularProgress />
      // </Box>
      <Loader open={loading} />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: 0,
          }}
        >
          <Typography variant="h4" sx={{ mb: 2, alignSelf: "flex-start" }}>
            Select Vendor
          </Typography>
          {vendors.length > 0 ? (
            <Box sx={{ height: "100%", width: "100%", alignSelf: "flex-start" }}>
              <DataGrid
                rows={vendors}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                hideFooter
                getRowId={(row) => row.vendorId} // Ensure unique row IDs
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .super-app-theme--header": {
                    // Custom header styling
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "normal",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                }}
              />
            </Box>
          ) : (
            <Typography>No vendors available</Typography>
          )}

          {/* Show Add New Vendor button only if no vendor is selected */}
          {!selectedVendor && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "250px", alignSelf: "flex-start", mt: 2 }}
              onClick={handleOpenDialog}
            >
              Add New Vendor
            </Button>
          )}

          {selectedVendor ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "250px", alignSelf: "flex-start", mt: 2 }}
              onClick={fetchOffersData}
            >
              Edit Vendor/Offer
            </Button>
          ) : null}

          {/* Dialog for Adding a New Vendor */}
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{
              sx: {
                width: "550px",
              },
            }}
          >
            <DialogTitle>Add New Vendor</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Vendor Name"
                fullWidth
                variant="outlined"
                value={newVendorName}
                onChange={(e) => setNewVendorName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleCreateVendor}>
                Create Vendor
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default VendorOfferPageMapping;
