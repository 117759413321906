import { Box } from '@mui/material'
import React from 'react'
import ComparitiveCommentry from './ComparitiveCommentry'

function ComparitiveSummary({ commentary, setCommentary,generateReport}) {
  return (
    <Box>
      {!commentary?.ComparativeSummary?.detailMatrix
        ?.isLoading &&
       !commentary?.ComparativeSummary?.bestOfBest
        ?.isLoading &&
        !commentary?.ComparativeSummary?.unMetUseCase
        ?.isLoading&&
        !commentary?.ComparativeSummary?.coverageHeatmap
        ?.isLoading &&
        !commentary?.ComparativeSummary?.relevantVendors
        ?.isLoading  &&
        !commentary?.ComparativeSummary?.relevantOffers
        ?.isLoading ?
        (
        <ComparitiveCommentry commentary={commentary} setCommentary={setCommentary} generateReport={generateReport} />
      ):"loading"}
    </Box>
  )
}

export default ComparitiveSummary